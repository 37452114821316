/* -----------------------------------------------------------------------------------------------*/
export function isDefined(value) {
    return value !== undefined;
}
export function isNotNull(value) {
    return value !== null;
}
export const transformOrigin = (options) => ({
    name: "transformOrigin",
    options,
    fn(data) {
        const { placement, rects, middlewareData } = data;
        const cannotCenterArrow = middlewareData.arrow?.centerOffset !== 0;
        const isArrowHidden = cannotCenterArrow;
        const arrowWidth = isArrowHidden ? 0 : options.arrowWidth;
        const arrowHeight = isArrowHidden ? 0 : options.arrowHeight;
        const [placedSide, placedAlign] = getSideAndAlignFromPlacement(placement);
        const noArrowAlign = { start: "0%", center: "50%", end: "100%" }[placedAlign];
        const arrowXCenter = (middlewareData.arrow?.x ?? 0) + arrowWidth / 2;
        const arrowYCenter = (middlewareData.arrow?.y ?? 0) + arrowHeight / 2;
        let x = "";
        let y = "";
        if (placedSide === "bottom") {
            x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
            y = `${-arrowHeight}px`;
        }
        else if (placedSide === "top") {
            x = isArrowHidden ? noArrowAlign : `${arrowXCenter}px`;
            y = `${rects.floating.height + arrowHeight}px`;
        }
        else if (placedSide === "right") {
            x = `${-arrowHeight}px`;
            y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
        }
        else if (placedSide === "left") {
            x = `${rects.floating.width + arrowHeight}px`;
            y = isArrowHidden ? noArrowAlign : `${arrowYCenter}px`;
        }
        return { data: { x, y } };
    },
});
export function getSideAndAlignFromPlacement(placement) {
    const [side, align = "center"] = placement.split("-");
    return [side, align];
}
