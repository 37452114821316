<script context="module">
  const INDICATOR = { label: "INDICATOR", value: "INDICATOR" }
  const ITEM_HEIGHT = "calc(1.25 * var(--vertical-meter))"
</script>

<script>
  import { createEventDispatcher } from "svelte"

  export let columns
  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  let activeColumnIndex = null
  let activeItemIndex = null
  let targetColumnIndex = null
  let targetItemIndex = null

  $: isDragging = [
    activeColumnIndex,
    activeItemIndex,
    targetColumnIndex,
    targetItemIndex,
  ].every((i) => null !== i)

  let activeItem = null

  function getItem(columnIndex, itemIndex) {
    return columns[columnIndex]?.items[itemIndex] || null
  }

  function addItem(columnIndex, itemIndex, item) {
    columns[columnIndex].items.splice(itemIndex, 0, item)
  }

  function removeItem(columnIndex, itemIndex) {
    columns[columnIndex].items.splice(itemIndex, 1)
  }

  function moveItem(source, destination) {
    const item = getItem(source.columnIndex, source.itemIndex)
    removeItem(source.columnIndex, source.itemIndex)
    addItem(destination.columnIndex, destination.itemIndex, item)
  }

  function addIndicator() {
    addItem(targetColumnIndex, targetItemIndex, INDICATOR)
  }

  function removeIndicator() {
    const item = getItem(targetColumnIndex, targetItemIndex)
    if (INDICATOR !== item) return
    removeItem(targetColumnIndex, targetItemIndex)
  }

  function activateItem(columnIndex, itemIndex) {
    activeColumnIndex = targetColumnIndex = columnIndex
    activeItemIndex = targetItemIndex = itemIndex
    activeItem = getItem(columnIndex, itemIndex)
    addIndicator()
  }

  function deactivateItem() {
    removeIndicator()
    activeItem = null
    activeItemIndex = targetItemIndex = null
    activeColumnIndex = targetColumnIndex = null
  }

  function getIndexes(dataset) {
    const { columnindex, itemindex } = dataset
    return { columnIndex: Number(columnindex), itemIndex: Number(itemindex) }
  }

  function handleDragStart(event) {
    const { columnIndex, itemIndex } = getIndexes(event.target.dataset)
    activateItem(columnIndex, itemIndex)
    columns = columns // force svelte update.
  }

  function handleDragEnd(_event) {
    deactivateItem()
    columns = columns // force svelte update.
  }

  function handleDragEnter(event) {
    const elem = event.target.closest("[data-columnindex]")
    let { columnIndex, itemIndex } = getIndexes(elem.dataset)
    itemIndex = Number.isNaN(itemIndex) ? targetItemIndex : itemIndex

    moveItem(
      { columnIndex: targetColumnIndex, itemIndex: targetItemIndex },
      { columnIndex, itemIndex }
    )
    targetColumnIndex = columnIndex

    if (itemIndex > columns[targetColumnIndex].items.length - 1) {
      targetItemIndex = columns[targetColumnIndex].items.length - 1
    } else {
      targetItemIndex = itemIndex
    }

    columns = columns
  }

  function handleDrop(_event) {
    removeIndicator()
    const changed =
      activeColumnIndex !== targetColumnIndex ||
      activeItemIndex !== targetItemIndex
    if (!isDragging || !changed) return
    // [DK] tagetItemIndex is not updated when indicator is removed
    if (
      targetItemIndex > activeItemIndex &&
      activeColumnIndex === targetColumnIndex
    ) {
      targetItemIndex--
    }
    moveItem(
      { columnIndex: activeColumnIndex, itemIndex: activeItemIndex },
      { columnIndex: targetColumnIndex, itemIndex: targetItemIndex }
    )
    dispatch("change", columns)
  }

  $: total = columns.map((c) => c.items.length).reduce((acc, i) => acc + i, 0)
</script>

<div
  class="
    item-arranger
    flex
    flex-row
    justify-between
    gap-extra-large
    {cssClass}"
>
  <!-- on:dragover|preventDefault avoids rubber-band animation on drop. -->
  {#each columns as column, i}
    <div
      role="presentation"
      class="
        column
        flex
        flex-1
        flex-col
        border
        border-solid"
      class:column-active={targetColumnIndex === i}
      class:dragging={isDragging}
      style:border-color={targetColumnIndex === i
        ? column.activeBorderColor
        : "transparent"}
      data-columnindex={i}
      on:dragenter={handleDragEnter}
      on:dragover|preventDefault
      on:drop={handleDrop}
    >
      <div
        class="
          column-header
          my-double-extra-small
          flex
          w-full
          bg-table-column-header
          text-general-secondary-text
          font-medium-500"
        data-columnindex={i}
        data-itemindex={0}
      >
        {column.title}
        {#if column.showCount}
          ({column.items.length}/{total})
        {/if}

        {#if column.showReset}
          <!-- Column Reset Button  -->
          <button
            class="
              reset-column-button
              ml-auto
              font-small-600-caps
              hover:text-general-primary-text"
            on:click={() => dispatch("reset")}
          >
            <span
              class="
                tw-icon-Reset
                pr-triple-extra-small
                font-x-small-500"
            />
            Reset
          </button>
        {/if}
      </div>
      {#each column.items as item, j}
        <div
          role="presentation"
          class="
            item
            relative
            my-double-extra-small
            flex
            w-full
            items-center
            justify-center
            rounded
            bg-table-column-pill"
          class:item-active={activeItem === item}
          class:item-indicator={INDICATOR === item}
          class:bg-toggle-active={INDICATOR === item}
          class:bg-toggle-inactive={INDICATOR !== item}
          class:hidden={activeItem === item}
          style:max-height={ITEM_HEIGHT}
          style:min-height={ITEM_HEIGHT}
          data-columnindex={i}
          data-itemindex={j}
          draggable="true"
          on:dragstart={handleDragStart}
          on:dragend={handleDragEnd}
        >
          <span
            class="
              tw-icon-Grip1
              absolute
              flex
              h-full
              items-center
              justify-center
              text-general-secondary-text"
            style:left="var(--double-extra-small-spacing)"
            style:top="0"
          />

          <span
            class="
              text
              truncate
              text-general-default-text
              font-small-400"
          >
            {INDICATOR === item ? activeItem.label : item.label}
          </span>

          {#if column.showIndexes}
            <span
              class="
                absolute
                text-general-secondary-text
                font-xxx-small-500"
              style:right="var(--double-extra-small-spacing)"
              style:top="0"
            >
              C: {j + 1}
            </span>
          {/if}
        </div>
      {/each}
    </div>
  {/each}
</div>
