<script context="module">import {
  cn,
  tableVariants,
  uniqueContext,
  useActions
} from "../../..";
const { getContext, setContext } = uniqueContext();
export const getTableRootContext = getContext;
</script>

<script>import { useMutationObserver } from "../../../internal/actions";
import { writable } from "svelte/store";
export let cols = 12;
export let variant = "default";
export let responsive = false;
export let use = [];
let cssClass = "";
export { cssClass as class };
let headerLabels = [];
let rowCount = void 0;
const rootCtx = writable({
  cols,
  variant,
  responsive,
  headerLabels
});
setContext(rootCtx);
$: rootCtx.update((state) => ({
  ...state,
  cols,
  variant,
  responsive,
  headerLabels
}));
const mutationObserverOptions = {
  callback: (mutationList) => {
    const table = mutationList[0].target;
    rowCount = table.querySelectorAll("[role='row']").length;
  },
  childList: true
};
</script>

<div
  role="table"
  class={cn(tableVariants({ variant }), cssClass)}
  aria-rowcount={rowCount}
  use:useActions={[...use, [useMutationObserver, mutationObserverOptions]]}
  {...$$restProps}
>
  <slot />
</div>
