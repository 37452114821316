import {
  ACH_DEPOSIT_MAXIMUM,
  DateHelper,
  DEPOSIT_FREQUENCIES,
  DEPOSIT_FREQUENCY_VALUES,
  ExternalTransaction,
  getIntervalFromFrequency,
  getPeriodFromFrequency,
  IRA_CONTRIBUTION_REASONS,
  IraDetail,
  isNilOrEmpty,
  RECURRING_DEPOSIT_ELIGIBLE_REASONS,
  RecurringDeposit,
  ValidateYup,
} from "@tastyworks/tastyworks-api"
import { boolean, date, number, object, string } from "yup"

const { buildSchema, depositSchema, iraDetailSchema } = ValidateYup

const RECURRING_FREQUENCY_OPTIONS = [
  DEPOSIT_FREQUENCIES.WEEKLY,
  DEPOSIT_FREQUENCIES.BIWEEKLY,
  DEPOSIT_FREQUENCIES.MONTHLY,
]

const ONE_TIME_FREQUENCY_OPTIONS = [DEPOSIT_FREQUENCIES.ONE_TIME]

const ALL_FREQUENCY_OPTIONS = ONE_TIME_FREQUENCY_OPTIONS.concat(
  RECURRING_FREQUENCY_OPTIONS
)

const isRecurring = (frequency: DEPOSIT_FREQUENCIES) =>
  RECURRING_FREQUENCY_OPTIONS.includes(frequency)

export class AchDeposit {
  achRelationshipId?: number
  amount: number = null
  frequency = DEPOSIT_FREQUENCIES.ONE_TIME
  isRetirementAccount = false
  iraDetail = new IraDetail()
  startDate: Date

  constructor() {
    let dateHelper = new DateHelper()
    this.startDate = dateHelper.isWeekend()
      ? dateHelper.nextWeekday().toDate()
      : dateHelper.toDate()
  }

  get retirementFrequencyOptions() {
    if (this.iraDetail.isPreviousYearContribution) {
      return ONE_TIME_FREQUENCY_OPTIONS
    }
    if (
      !RECURRING_DEPOSIT_ELIGIBLE_REASONS.includes(
        this.iraDetail.contributionReason as IRA_CONTRIBUTION_REASONS
      )
    ) {
      return ONE_TIME_FREQUENCY_OPTIONS
    }

    return ALL_FREQUENCY_OPTIONS
  }

  get frequencyOptions() {
    if (this.isRetirementAccount) {
      return this.retirementFrequencyOptions
    } else {
      return ALL_FREQUENCY_OPTIONS
    }
  }

  get isRecurring() {
    return isRecurring(this.frequency)
  }

  toRecurringDeposit(accountNumber: string) {
    const recurringDeposit = new RecurringDeposit()

    recurringDeposit.accountNumber = accountNumber
    recurringDeposit.amount = this.amount
    recurringDeposit.startDate = this.startDate
    recurringDeposit.period = getPeriodFromFrequency(this.frequency)
    recurringDeposit.interval = getIntervalFromFrequency(this.frequency)
    recurringDeposit.achRelationshipId = this.achRelationshipId
    if (this.isRetirementAccount) {
      recurringDeposit.iraDetail = this.buildIraDetail()
    }

    return recurringDeposit
  }

  toOneTimeDeposit(accountNumber: string) {
    const oneTimeDeposit = new ExternalTransaction()
    oneTimeDeposit.accountNumber = accountNumber
    oneTimeDeposit.amount = this.amount
    oneTimeDeposit.achRelationshipId = this.achRelationshipId
    if (this.isRetirementAccount) {
      oneTimeDeposit.iraDetail = this.buildIraDetail()
    }

    return oneTimeDeposit
  }

  get hasContribution() {
    return !isNilOrEmpty(this.iraDetail?.contributionReason)
  }

  private buildIraDetail() {
    const iraDetail = new IraDetail()
    iraDetail.contributionReason = this.iraDetail.contributionReason
    iraDetail.contributionYear = this.iraDetail.contributionYear
    return iraDetail
  }
}

const contributionSchemaRequired = object({
  contributionReason: string().required(),
  contributionYear: string().required(),
})

const schema = depositSchema.pick(["achRelationshipId", "amount"]).concat(
  object({
    amount: number()
      .required()
      .positive()
      .max(
        ACH_DEPOSIT_MAXIMUM,
        "ACH transfer maximum of $250,000 PER DAY. To deposit more, please fund by wire or check"
      ),
    frequency: string().required().oneOf(DEPOSIT_FREQUENCY_VALUES),
    iraDetail: object({
      contributionReason: iraDetailSchema.fields.contributionReason,
      contributionYear: iraDetailSchema.fields.contributionYear,
    })
      .nullable()
      .test(
        "contribution",
        "yup.iraDetail.contribution.required",
        function (iraDetail) {
          if (this.parent.isRetirementAccount) {
            return contributionSchemaRequired.isValidSync(iraDetail)
          }
          return true
        }
      ),
    isApproved: boolean().required().oneOf([true]),
    isRetirementAccount: boolean().required(),
    startDate: date()
      .required()
      .when("frequency", {
        is: isRecurring,
        otherwise: (schema) => schema,
        then: (schema) =>
          schema
            .required()
            .min(new DateHelper(new Date()).clearTime().toDate())
            .default(() => new Date()),
      }),
  })
)

export const achDepositSchema = buildSchema("AchDeposit", schema)
