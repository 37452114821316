import * as Castle from "@castleio/castle-js"
import {
  AO2_AUTH_SESSION_COOKIE_KEY,
  EMBER_AUTH_COOKIE_KEY,
  TwLogin,
  isNilOrEmpty,
} from "@tastyworks/tastyworks-api"
import {
  CROSS_DOMAIN_COOKIE_DESER,
  CrossDomainCookie,
} from "@tastyworks/tastyworks-api/session"
import Cookie from "js-cookie"
import { derived, get, writable } from "svelte/store"
import { AlertSeverity, toastAlert } from "/@/control/alert"
import { MICRO_APP } from "/@/micro-app"

export const webPlatformSourceIdentifier = "WB2"

export const TW_SESSION_ID_KEY = "tw-session-id"
export const TW_USER_EXTERNAL_ID_KEY = "tw-user-external-id"
export const TW_USER_EMAIL_KEY = "tw-user-email"
export const TW_USER_NAME_KEY = "tw-user-name"
export const TW_ADMIN_ACCOUNT_KEY = "tw-admin-account"
export const TW_CURRENT_ACCOUNT_KEY = "tw-current-account"
export const TW_SEEN_ENTITY_MIGRATION_FLOW = "tw-seen-entity-migration-flow"
export const TW_SEEN_W_8BEN_RECERT_MODAL = "tw-seen-w-8ben-recert-modal"
export const TW_W_8BEN_RECERT_SUBMITTED = "tw-w-8ben-recert-submitted"
export const DX_LAYOUTS_KEY = "layouts"
export const DEFAULT_IG_ACCOUNT_NUMBER = "default-ig-account-number"

const REMEMBER_ME_FLAG = "tw-remember-me-flag"
const SESSION_COOKIE_DOMAIN = import.meta.env.VITE_SESSION_DOMAIN

// turn relative top-level URLs into absolute URLs
// so that redirects work when prefixed (e.g. branches deployed to staging)
function prefixedUrl(url) {
  return import.meta.env.BASE_URL + url
}

export const LOGIN_PAGE = prefixedUrl("login.html")

export const APP_PAGE = prefixedUrl("app.html")

export const ENTITY_MIGRATION_PAGE = prefixedUrl("entity-migration.html")

export const INVALID_SESSION = prefixedUrl("invalid-session.html")

export const LAST_LOCATION_KEY = "tw-last-location"

export const IG_APP = import.meta.env.VITE_IG_APP

const EMAIL_REDIRECT_URLS_WITHOUT_LOGIN = [
  prefixedUrl("castle-incident/confirm"),
  prefixedUrl("castle-incident/reject"),
  prefixedUrl("confirmation"),
]

const EMAIL_REDIRECT_URLS_WITH_TOKENS = [
  prefixedUrl("reconfirmation"),
  prefixedUrl("password/reset"),
].concat(EMAIL_REDIRECT_URLS_WITHOUT_LOGIN)

const _charHasBeenReset = writable({})
export const chartHasBeenReset = derived(_charHasBeenReset, ($value) => $value)

export function setHasSeenMigrationFlow(seen: boolean) {
  sessionStorage.setItem(TW_SEEN_ENTITY_MIGRATION_FLOW, seen.toString())
}

export function hasSeenMigrationFlow() {
  return sessionStorage.getItem(TW_SEEN_ENTITY_MIGRATION_FLOW) === "true"
}

export function setSeenW8BenRecertModal(seen: boolean) {
  sessionStorage.setItem(TW_SEEN_W_8BEN_RECERT_MODAL, seen.toString())
}

export function hasSeenW8BenRecertModal() {
  return sessionStorage.getItem(TW_SEEN_W_8BEN_RECERT_MODAL) === "true"
}

export function setW8BenRecertSubmitted(seen: boolean) {
  Cookie.set(TW_W_8BEN_RECERT_SUBMITTED, seen.toString(), {
    // This should expire once we can be sure that the apex record has been updated
    // (which doesn't happen on weekends or holidays). 1 week should be sufficient
    expires: 7,
  })
}

export function hasSubmittedW8BenRecert() {
  return Cookie.get(TW_W_8BEN_RECERT_SUBMITTED) === "true"
}

/**
 * Return the default IG account number if it's provided. Clear the value in session storage after
 * reading to avoid resetting the account number on platform reload
 */
export function getDefaultIgAccountNumber() {
  const defaultIgAccountNumber = sessionStorage.getItem(
    DEFAULT_IG_ACCOUNT_NUMBER
  )

  sessionStorage.removeItem(DEFAULT_IG_ACCOUNT_NUMBER)

  return defaultIgAccountNumber
}

const sessionValid = writable(null)

export function setIsSessionValid(valid: boolean) {
  sessionValid.set(valid)
}

export function isSessionValid() {
  let valid = get(sessionValid)

  if (valid === null) {
    let token = getStoredSessionToken()
    valid = !isNilOrEmpty(token)
    setIsSessionValid(valid)
  }

  return valid
}

export function resetChart(value = {}) {
  _charHasBeenReset.set(value)
}

// Special handling for redirect paths, bypassing login requirements to view page
// Redirects user back to login if user trys to navigate elsewhere
export function allowUniqueRedirectBypass() {
  const valid = isSessionValid()

  if (valid) {
    return
  }

  const isCastle = EMAIL_REDIRECT_URLS_WITHOUT_LOGIN.some((path) =>
    window.location.hash.includes(path)
  )

  if (!isCastle) {
    redirectWithPath(LOGIN_PAGE)
  }

  return isCastle
}

export function createCastleIoRequestToken() {
  // Castle is not configured in unit tests.
  if (["development", "test"].includes(import.meta.env.MODE)) {
    return ""
  }
  // This is not a real promise. it's executed syncronously, so it's okay to
  // grab the value without waiting.
  return Castle.createRequestToken().then(() => {})._value || ""
}

export function handleInvalidSession(saveCurrentLocation = false) {
  if (saveCurrentLocation) {
    clearSessionPartial(sessionStorage, [LAST_LOCATION_KEY])
  } else {
    clearSession()
  }

  if (
    EMAIL_REDIRECT_URLS_WITH_TOKENS.some((x) =>
      window.location.hash.includes(x)
    )
  ) {
    return
  }

  if (IG_APP) {
    redirectToMyIg()
  } else if (get(MICRO_APP)) {
    redirectWithPath(INVALID_SESSION)
  } else {
    redirectWithPath(LOGIN_PAGE)
    toastAlert(AlertSeverity.ERROR, "Session has been invalidated")
  }
}

export function handleHttpNetworkError(
  _url: string,
  _request: any,
  _error: any
) {
  toastAlert(AlertSeverity.ERROR, "Network issue")
}

export function getUserExternalId() {
  return sessionStorage.getItem(TW_USER_EXTERNAL_ID_KEY)
}

function clearSessionPartial(storage: Storage, savedKeys: string[]) {
  const map = new Map<string, string>()
  for (const key of savedKeys) {
    let value: string
    if ((value = storage.getItem(key))) {
      map.set(key, value)
    }
  }

  storage.clear()

  for (const [k, v] of map.entries()) {
    storage.setItem(k, v)
  }
}

export function clearSession() {
  sessionStorage.clear()
  localStorage.removeItem(TW_SESSION_ID_KEY)
}

export function getStoredUsername(): string | null {
  return sessionStorage.getItem(TW_USER_NAME_KEY)
}

export function getStoredEmail(): string | null {
  return sessionStorage.getItem(TW_USER_EMAIL_KEY)
}

export function getStoredSessionToken(): string | null {
  const sessionToken = sessionStorage.getItem(TW_SESSION_ID_KEY)
  if (sessionToken) return sessionToken

  const localToken = localStorage.getItem(TW_SESSION_ID_KEY)
  if (localToken) {
    sessionStorage.setItem(TW_SESSION_ID_KEY, localToken)
    return localToken
  }

  return null
}

export function isRememberMeFlagPresent(): boolean {
  return localStorage.getItem(REMEMBER_ME_FLAG) === "true"
}

export function onSessionSuccess(twLogin: TwLogin, rememberMe: boolean) {
  const sessionToken = twLogin.sessionToken
  sessionStorage.setItem(TW_SESSION_ID_KEY, sessionToken)

  const user = twLogin.user
  sessionStorage.setItem(TW_USER_EXTERNAL_ID_KEY, user.externalId)
  sessionStorage.setItem(TW_USER_EMAIL_KEY, user.email)
  sessionStorage.setItem(TW_USER_NAME_KEY, user.username)

  if (rememberMe) {
    localStorage.setItem(REMEMBER_ME_FLAG, "true")
    localStorage.setItem(TW_SESSION_ID_KEY, sessionToken)
  } else {
    localStorage.removeItem(REMEMBER_ME_FLAG)
    localStorage.removeItem(TW_SESSION_ID_KEY)
  }
}

export function clearLastLocation() {
  sessionStorage.removeItem(LAST_LOCATION_KEY)
}

export function saveCurrentLocation() {
  sessionStorage.setItem(LAST_LOCATION_KEY, window.location.hash)
}

export function getAndClearLoginSuccessLocation(): string {
  const lastLocation = sessionStorage.getItem(LAST_LOCATION_KEY)
  sessionStorage.removeItem(LAST_LOCATION_KEY)
  if (lastLocation) return `${APP_PAGE}${lastLocation}`

  return APP_PAGE
}

export function redirectWithPath(path: string) {
  window.location.replace(`${window.location.origin}${path}`)
}

export function redirectToMyIg() {
  window.open(`${import.meta.env.VITE_MY_IG_URL}/dashboard`, "_self")
}

export async function isCurrentSessionValid(): Promise<boolean> {
  const twToken = getStoredSessionToken()
  if (!twToken) return false

  try {
    // super lightweight session check to minimize JS payload
    const validateResponse = await fetch(
      `${import.meta.env.VITE_TW_API_BASE_URL}sessions/validate`,
      {
        method: "POST",
        headers: {
          authorization: twToken,
          "X-Castle-Request-Token": createCastleIoRequestToken(),
        },
      }
    )

    if (!validateResponse.ok) {
      clearSession()
      return false
    }

    const { data } = await validateResponse.json()
    sessionStorage.setItem(TW_USER_EXTERNAL_ID_KEY, data["external-id"])
    sessionStorage.setItem(TW_USER_EMAIL_KEY, data.email)
    sessionStorage.setItem(TW_USER_NAME_KEY, data.username)

    return true
  } catch (e) {
    console.log("Failed to validate", e)
    return false
  }
}

export async function requireValidSession(): Promise<boolean> {
  const valid = await isCurrentSessionValid()
  setIsSessionValid(valid)

  if (!valid && !allowUniqueRedirectBypass()) {
    saveCurrentLocation()
    handleInvalidSession(true)
    throw new Error("Invalid Session")
  }

  return true
}

let _accountNumbers: string[] = Object.freeze([]) as string[]
export function getAccountNumbers(): string[] {
  return _accountNumbers
}

export function setAccountNumbers(accountNumbers: string[]): void {
  _accountNumbers = Object.freeze(accountNumbers.slice()) as string[]
}

export function getCurrentAccountNumber(): string {
  return sessionStorage.getItem(TW_CURRENT_ACCOUNT_KEY)
}

export function setCurrentAccountNumber(accountNumber: string): void {
  if (accountNumber) {
    sessionStorage.setItem(TW_CURRENT_ACCOUNT_KEY, accountNumber)
  } else {
    sessionStorage.removeItem(TW_CURRENT_ACCOUNT_KEY)
  }
}

export function isAdmin() {
  return sessionStorage.getItem(TW_ADMIN_ACCOUNT_KEY) !== null
}

export function getAdminAccountNumbers(): string[] {
  return sessionStorage.getItem(TW_ADMIN_ACCOUNT_KEY).split(",")
}

export function initializeSession(sessionToken: string): void {
  clearSession() // Ensure previous setups don't exist
  sessionStorage.setItem(TW_SESSION_ID_KEY, sessionToken)
}

export function setupAdminMode(sessionToken: string, account: string): void {
  initializeSession(sessionToken)
  sessionStorage.setItem(TW_ADMIN_ACCOUNT_KEY, account)
}

export function setCrossDomainCookieForAccountOpening(
  sessionJson: TwLogin,
  cookieName = EMBER_AUTH_COOKIE_KEY,
  domain = SESSION_COOKIE_DOMAIN
) {
  const payload = CROSS_DOMAIN_COOKIE_DESER.stringify(
    new CrossDomainCookie(sessionJson)
  )
  return Cookie.set(cookieName, payload, {
    domain,
  })
}

export function removeFundingPromptStorage() {
  try {
    localStorage.removeItem("funding-prompt")
  } catch (e) {
    console.error(e)
  }
}

export function getHostname(url: string) {
  return new URL(url).hostname
}

export function parseUserFromSessionStorage() {
  const twLogin = new TwLogin()
  twLogin.sessionToken = sessionStorage.getItem(TW_SESSION_ID_KEY)

  const user = twLogin.user
  user.externalId = sessionStorage.getItem(TW_USER_EXTERNAL_ID_KEY)
  user.email = sessionStorage.getItem(TW_USER_EMAIL_KEY)
  user.username = sessionStorage.getItem(TW_USER_NAME_KEY)
  user.sessionToken = sessionStorage.getItem(TW_SESSION_ID_KEY)
  twLogin.email = sessionStorage.getItem(TW_USER_EMAIL_KEY)

  return twLogin
}

/**
 * Set the cross domain cookie for AO2
 * AO2 will remove the cookie on arrival, so we need to set it every time we want to open AO2
 */
export function setCrossDomainCookieForAO2() {
  const twLogin = parseUserFromSessionStorage()
  // just in case - reset the auth cookie if the user closes AO2, but still has WB2 open
  setCrossDomainCookieForAccountOpening(twLogin, AO2_AUTH_SESSION_COOKIE_KEY)
}

export function isIgApp() {
  return IG_APP
}
