<script context="module">
  import goog from "/@lib/boulangerie"

  const SecurityEntriesUtil = goog.module.get(
    "com.dough.trade.util.SecurityEntriesUtil"
  )
  const UiOrderStatusLeg = goog.module.get("tasty.ui.boule.UiOrderStatusLeg")
</script>

<script>
  import BoulePlainText from "/@/boule/control/BoulePlainText.svelte"

  import SecurityEntryActionPill from "./SecurityEntryActionPill.svelte"
  import { nullableBoulePropertyStore } from "./control/boule-property-store.js"

  export let grow = false
  export let orderStatusLeg

  let cssClass = ""
  export { cssClass as class }

  $: orderStatus = orderStatusLeg?.orderStatus

  $: isPartialFill = nullableBoulePropertyStore(
    orderStatus?.partialFillProperty()
  )

  // LiquidityAllocations always show up as partial-fills, but their non-
  // allocate-legs will never have a remaining-quantity.
  $: isLiquidityAllocation = !!orderStatus
    ? SecurityEntriesUtil.isLiquidityAllocation(orderStatus)
    : false
</script>

<SecurityEntryActionPill
  class="order-status-leg-pill {cssClass}"
  expirationClass="rounded-r-none"
  actionSecurityEntry={orderStatusLeg}
  {grow}
>
  <svelte:fragment slot="quantity">
    {#if $isPartialFill && !isLiquidityAllocation}
      <BoulePlainText
        class="field partial-fill-quantity text-general-secondary-text"
        source={orderStatusLeg}
        field={UiOrderStatusLeg.FILLED_QUANTITY}
      />
      <span class="text-general-secondary-text">/</span>
    {/if}
    <BoulePlainText
      class="field quantity"
      source={orderStatusLeg}
      field={UiOrderStatusLeg.QUANTITY}
    />
  </svelte:fragment>
  <BoulePlainText
    slot="expiration"
    class="field expiration"
    source={orderStatusLeg}
    field={UiOrderStatusLeg.EXPIRATION_DATE}
  />
  <BoulePlainText
    slot="days-to-expiration"
    class="field days-to-expiration"
    source={orderStatusLeg}
    field={UiOrderStatusLeg.DAYS_TO_EXPIRATION}
  />
  <BoulePlainText
    slot="strike"
    class="field strike"
    source={orderStatusLeg}
    field={UiOrderStatusLeg.STRIKE_PRICE}
  />
  <BoulePlainText
    slot="call-or-put"
    class="field call-or-put"
    source={orderStatusLeg}
    field={UiOrderStatusLeg.OPTION_TYPE_SHORT}
  />
  <BoulePlainText
    slot="action"
    let:clazz
    class="field action {clazz}"
    source={orderStatusLeg}
    field={UiOrderStatusLeg.ACTION}
  />
</SecurityEntryActionPill>
