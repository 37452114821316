/**
 * TODO:
 *   If Zero Hash ever decides to become a real OAuth client
 *   (i.e, one that uses the `code` response type instead of `token`),
 *   configure them in the new oauth app table (using client ID hardcoded below)
 *   and then delete this file and any usages of it
 */
import twApiClient from "/@/tasty-oauth/tw-api-client"

const CLIENT_ID = "57de7da8-e164-44cf-acf7-62660b58b2d3"
const RESPONSE_TYPE = "token"
const ALLOWED_REDIRECT_URIS = [
  "https://tasty.customers.zerohash.com/dashboard",
  "https://kyc-mock-platform-server.dev.0hash.com/wh",
  "https://tasty.customers.dev.0hash.com/auth/redirect",
  "https://tasty.customers.cert.zerohash.com/auth/redirect",
  "https://tasty.customers.zerohash.com/auth/redirect",
]

export function isZeroHash(clientId: string, responseType: string): boolean {
  return clientId === CLIENT_ID && responseType === RESPONSE_TYPE
}

export async function doZeroHashFlow(redirectUri: string): Promise<
  | {
      clientName: string
      customerTwoFactorRequired: boolean
    }
  | undefined
> {
  if (ALLOWED_REDIRECT_URIS.includes(redirectUri)) {
    return {
      clientName: "Zero Hash",
      customerTwoFactorRequired: true,
    }
  }
}

export async function grantZeroHash(redirectUri: string, state?: string) {
  await twApiClient.preferencesService.update({
    "oauth-confirmations": {
      [CLIENT_ID]: new Date().toISOString(),
    },
  })
  const finalRedirectUrl = new URL(redirectUri)
  finalRedirectUrl.searchParams.append(
    "token",
    twApiClient.twSession.sessionToken
  )
  if (state) {
    finalRedirectUrl.searchParams.append("state", state)
  }
  window.location.replace(finalRedirectUrl.toString())
}
