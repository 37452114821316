<script context="module">import { useActions } from "../../helpers";
import { getPopperRootContext } from "./PopperRoot.svelte";
</script>

<script>export let use = [];
export let as = "div";
export let type = as === "button" ? "button" : void 0;
export let ref = null;
let element;
$: if (element) ref = element;
const rootContext = getPopperRootContext();
$: $rootContext.anchor = element;
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<svelte:element
  this={as}
  {type}
  {...$$restProps}
  use:useActions={use ?? []}
  bind:this={element}
  on:mouseup
  on:pointerup
  on:pointerenter
  on:pointermove
  on:pointerleave
  on:pointerdown
  on:focus
  on:blur
  on:keydown
  on:keyup
>
  <slot />
</svelte:element>
