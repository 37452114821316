<script context="module"></script>

<script>import { Directions, Orientations } from "../../../internal/constants";
import { generateId, useActions } from "../../../internal/helpers";
import {
} from "../../../internal/types";
import { cn } from "../../../utils";
import { useCollection } from "radix-svelte/internal/helpers";
import { createEventDispatcher, tick } from "svelte";
import {
  getButtonGroupItemCollection,
  getButtonGroupRootContext
} from "./ButtonGroupRoot.svelte";
import { buttonGroupItemVariants } from "./variants";
let className = "";
export { className as class };
export let value = "";
export let id = generateId("button-group-item");
export let disabled = false;
export let use = [];
const dispatch = createEventDispatcher();
const ctx = getButtonGroupRootContext();
const itemCollection = getButtonGroupItemCollection();
$: ({ variant, deselect, name, type, orientation, dir } = $ctx);
$: pressed = Array.isArray($ctx.value) ? $ctx.value.includes(value) : $ctx.value === value;
let wasClicked = false;
function normalize(value2) {
  return typeof value2 === "string" ? value2 : JSON.stringify(value2);
}
function getTabIndex(items, selected) {
  if (!items.length) return -1;
  const firstValue = items[0].dataset.value;
  const selectedValue = Array.isArray(selected) ? selected[0] : selected;
  const target = selectedValue ?? firstValue;
  return normalize(target) === normalize(value) ? 0 : -1;
}
function handleFocus(event) {
  if (type === "multiple" || $ctx.value === null || $ctx.value === void 0) {
    return;
  }
  if (!wasClicked) {
    handleChange();
  }
  wasClicked = false;
}
async function toggleButtons(target) {
  if ($ctx.value === null || $ctx.value === void 0) {
    target.click();
  } else {
    const otherButton = $itemCollection.find((item) => item !== target);
    otherButton.focus();
    await tick();
    otherButton.click();
  }
}
function handleKeyDown(event) {
  if (type === "multiple") return;
  if (event.key === "Enter" || event.key === " ") {
    if ($itemCollection.length === 2) {
      event.preventDefault();
      event.stopPropagation();
      toggleButtons(event.target);
    }
  }
}
function handleMouseDown() {
  wasClicked = true;
}
function handleClick() {
  if (pressed && deselect && type === "single") {
    $ctx.value = null;
  } else {
    handleChange();
  }
}
function handleChange() {
  if (pressed && deselect && type === "single") {
    $ctx.value = null;
  } else if ($ctx.type === "single") {
    $ctx.value = value;
  } else if ($ctx.type === "multiple") {
    const values = Array.isArray($ctx.value) ? $ctx.value : [];
    $ctx.value = !$ctx.value.includes(value) ? [...values, value] : values.filter((v) => v !== value);
  }
  dispatch("change", $ctx.value);
}
</script>

<button
  class={cn(buttonGroupItemVariants({ className, variant }), {
    "first:rounded-l-md last:rounded-r-md":
      orientation === Orientations.HORIZONTAL && dir === Directions.LTR,
    "first:rounded-r-md last:rounded-l-md":
      orientation === Orientations.HORIZONTAL && dir === Directions.RTL,
    "first:rounded-t-md last:rounded-b-md":
      orientation === Orientations.VERTICAL,
  })}
  {value}
  {id}
  type="button"
  disabled={$ctx.disabled || disabled ? true : undefined}
  aria-pressed={pressed}
  data-state={pressed ? "on" : "off"}
  data-orientation={$ctx.orientation}
  tabindex={getTabIndex($itemCollection, $ctx.value)}
  on:mousedown={handleMouseDown}
  on:click={handleClick}
  on:focus={handleFocus}
  on:keydown={handleKeyDown}
  use:useActions={[
    ...(use ?? []),
    [useCollection, { collection: itemCollection }],
  ]}
  {...$$restProps}
>
  <slot />
</button>

{#if name}
  <input
    type={type === "single" ? "radio" : "checkbox"}
    hidden
    inert
    tabIndex={-1}
    {name}
    {value}
    {disabled}
  />
{/if}
