<script lang="ts">
  import { Alert, Button } from "@tastyworks/ui-library"

  export let oauthFlowStateContext

  const { clientName } = oauthFlowStateContext
</script>

<Alert.Root variant="reminder">
  <Alert.Title>2FA Required</Alert.Title>
  <Alert.Description>
    You must have
    <Button
      variant="link"
      href="https://support.tastytrade.com/support/s/solutions/articles/43000578659"
      target="_blank"
      >two-factor authentication
    </Button>
    enabled on your tastytrade account in order to allow {clientName} to authenticate
    your identity with tastytrade for the purpose of managing your {clientName}
    account.
  </Alert.Description>
</Alert.Root>
