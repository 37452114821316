// type Enum | `${Enum}` === Enum | "val1" | ... | "valN"
export var Directions;
(function (Directions) {
    Directions["LTR"] = "ltr";
    Directions["RTL"] = "rtl";
})(Directions || (Directions = {}));
export var Orientations;
(function (Orientations) {
    Orientations["HORIZONTAL"] = "horizontal";
    Orientations["VERTICAL"] = "vertical";
})(Orientations || (Orientations = {}));
export var States;
(function (States) {
    States["DISABLED"] = "disabled";
    States["ENABLED"] = "enabled";
    States["ACTIVE"] = "active";
    States["INACTIVE"] = "inactive";
})(States || (States = {}));
export var Activations;
(function (Activations) {
    Activations["FOCUS"] = "focus";
    Activations["CLICK"] = "click";
})(Activations || (Activations = {}));
