import type BigNumber from 'bignumber.js'
import _ from 'lodash'
import { formatJsonDate, formatJsonDateTime } from './format/date'
import type { ArrayMap } from './util/collection'
import type { Cost } from './util/cost'
import type { BasicJsonValue, JsonKeyExtractor, JsonMap } from './util/json'
import { JsonBuilder, JsonHelper } from './util/json'
import type { Creator } from './util/meta-prog'
import { getProperty, setProperty } from './util/meta-prog'
import { dasherize } from './util/string'

export type Parser<T> = (helper: JsonHelper) => T

export type Updater<T> = (target: T, helper: JsonHelper) => void

export type Serializer<T> = (target: T, json: JsonBuilder) => void

export const toParser =
  <T>(creator: Creator<T>, updater: Updater<T>): Parser<T> =>
  (helper: JsonHelper) => {
    const target = new creator()
    updater(target, helper)
    return target
  }

export interface DeSer<T> {
  update(target: T, helper: JsonHelper): void
  serialize(target: T, json: JsonBuilder): void
}

export abstract class SimpleField<T, K extends keyof T, V> implements DeSer<T> {
  protected jsonFieldName: string

  protected constructor(
    protected objectFieldName: K,
    $jsonFieldName = '',
    protected serializeEmpty = false
  ) {
    this.jsonFieldName = $jsonFieldName
      ? $jsonFieldName
      : dasherize(objectFieldName as string)
  }

  public update(target: T, helper: JsonHelper): void {
    if (!helper.hasField(this.jsonFieldName)) {
      return
    }

    const value = this.getJsonValue(helper)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setProperty(target, this.objectFieldName, value as any)
  }

  protected abstract getJsonValue(helper: JsonHelper): V

  public serialize(target: T, json: JsonBuilder): void {
    const value: V = getProperty(target, this.objectFieldName)
    const jsonValue = this.toJsonValue(value)
    json.add(this.jsonFieldName, jsonValue, this.serializeEmpty)
  }

  protected abstract toJsonValue(value: V): BasicJsonValue
}

export class PrimitiveArrayField<T, K extends keyof T> implements DeSer<T> {
  private readonly jsonFieldName: string

  constructor(
    private readonly objectFieldName: K,
    $jsonFieldName = ''
  ) {
    this.jsonFieldName = $jsonFieldName
      ? $jsonFieldName
      : dasherize(objectFieldName as string)
  }

  update(target: T, helper: JsonHelper): void {
    if (!helper.hasField(this.jsonFieldName)) {
      return
    }

    const array = helper.getPrimitiveArray(this.jsonFieldName)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setProperty(target, this.objectFieldName, array as any)
  }

  serialize(target: T, json: JsonBuilder): void {
    const array: BasicJsonValue[] | undefined = getProperty(
      target,
      this.objectFieldName
    )
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!array) {
      return
    }

    json.add(this.jsonFieldName, array)
  }
}

export class CustomEnumField<T, K extends keyof T, V> extends SimpleField<
  T,
  K,
  V
> {
  constructor(
    private readonly converter: (value: string) => V,
    _objectFieldName: K,
    _jsonFieldName = ''
  ) {
    super(_objectFieldName, _jsonFieldName)
  }

  protected getJsonValue(helper: JsonHelper): V {
    const value = helper.getString(this.jsonFieldName)
    return this.converter(value)
  }

  protected toJsonValue(value: V): BasicJsonValue {
    return String(value)
  }
}

export class NestedField<T, U, K extends keyof T> implements DeSer<T> {
  private readonly jsonFieldName: string

  constructor(
    private readonly objectFieldName: K,
    private readonly schemaDeSer: SchemaDeSer<U>,
    private readonly creator: Creator<U>,
    $jsonFieldName = ''
  ) {
    this.jsonFieldName = $jsonFieldName
      ? $jsonFieldName
      : dasherize(objectFieldName as string)
  }

  update(target: T, helper: JsonHelper): void {
    if (!helper.hasField(this.jsonFieldName)) {
      return
    }

    let nested: U = getProperty(target, this.objectFieldName)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!nested) {
      nested = new this.creator()
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setProperty(target, this.objectFieldName, nested as any)
    }

    helper.updateObject(this.jsonFieldName, nested, this.schemaDeSer.update)
  }

  serialize(target: T, json: JsonBuilder): void {
    const value: U = getProperty(target, this.objectFieldName)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!value) {
      return
    }

    const nestedJsonBuilder = new JsonBuilder()
    this.schemaDeSer.serialize(value, nestedJsonBuilder)

    json.add(this.jsonFieldName, nestedJsonBuilder.json)
  }
}

export class ArrayField<T, U, K extends keyof T> implements DeSer<T> {
  private readonly jsonFieldName: string
  private readonly parser: Parser<U>

  constructor(
    private readonly objectFieldName: K,
    private readonly schemaDeSer: SchemaDeSer<U>,
    creator: Creator<U>,
    $jsonFieldName = ''
  ) {
    this.jsonFieldName = $jsonFieldName
      ? $jsonFieldName
      : dasherize(objectFieldName as string)
    this.parser = schemaDeSer.toParser(creator)
  }

  update(target: T, helper: JsonHelper): void {
    if (!helper.hasField(this.jsonFieldName)) {
      return
    }

    const array = helper.parseArray(this.jsonFieldName, this.parser)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setProperty(target, this.objectFieldName, array as any)
  }

  serialize(target: T, json: JsonBuilder): void {
    const array: U[] | undefined = getProperty(target, this.objectFieldName)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!array) {
      return
    }

    const toAdd: JsonMap[] = []

    array.forEach(value => {
      const builder = new JsonBuilder()

      this.schemaDeSer.serialize(value, builder)

      toAdd.push(builder.json)
    })

    json.add(this.jsonFieldName, toAdd)
  }
}

export class ArrayMapField<T, U, K extends keyof T, V> implements DeSer<T> {
  private readonly jsonFieldName: string

  constructor(
    private readonly objectFieldName: K,
    private readonly schemaDeSer: SchemaDeSer<U>,
    private readonly arrayMapSupplier: () => ArrayMap<V, U>,
    private readonly jsonKeyExtractor: JsonKeyExtractor<V>,
    $jsonFieldName = ''
  ) {
    this.jsonFieldName = $jsonFieldName
      ? $jsonFieldName
      : dasherize(objectFieldName as string)
  }

  update(target: T, helper: JsonHelper): void {
    if (!helper.hasField(this.jsonFieldName)) {
      return
    }

    let arrayMap: ArrayMap<V, U> | undefined = getProperty(
      target,
      this.objectFieldName
    )
    arrayMap = arrayMap ?? this.arrayMapSupplier()

    helper.updateArrayMap(
      this.jsonFieldName,
      arrayMap,
      this.schemaDeSer.update,
      this.jsonKeyExtractor
    )
  }

  serialize(target: T, json: JsonBuilder): void {
    const arrayMap: ArrayMap<any, U> | undefined = getProperty(
      target,
      this.objectFieldName
    )
    if (!arrayMap) {
      return
    }

    const toAdd: JsonMap[] = []

    arrayMap.values.forEach(value => {
      const builder = new JsonBuilder()

      this.schemaDeSer.serialize(value, builder)

      toAdd.push(builder.json)
    })

    json.add(this.jsonFieldName, toAdd)
  }
}

export class StringField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  string
> {
  constructor(
    objectFieldName: K,
    jsonFieldName?: string,
    serializeEmpty = false
  ) {
    super(objectFieldName, jsonFieldName, serializeEmpty)
  }

  protected getJsonValue(helper: JsonHelper): string {
    return helper.getString(this.jsonFieldName)
  }

  protected toJsonValue(value: string): BasicJsonValue {
    return value
  }
}

export class IntField<T, K extends keyof T> extends SimpleField<T, K, number> {
  constructor(objectFieldName: K, serializeEmpty: boolean, jsonField = '') {
    super(objectFieldName, jsonField, serializeEmpty)
  }

  protected getJsonValue(helper: JsonHelper): number {
    return helper.getInt(this.jsonFieldName)
  }

  protected toJsonValue(value: number): BasicJsonValue {
    return value
  }
}

export class FloatField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  number
> {
  constructor(objectFieldName: K, jsonFieldName?: string) {
    super(objectFieldName, jsonFieldName, false)
  }

  protected getJsonValue(helper: JsonHelper): number {
    return helper.getFloat(this.jsonFieldName)
  }

  protected toJsonValue(value: number): BasicJsonValue {
    if (_.isNil(value)) {
      return value
    }

    // Floats should be stringified to preserve scale
    return `${value}`
  }
}

export class BigNumberField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  BigNumber
> {
  constructor(objectFieldName: K, serializeEmpty: boolean, jsonField = '') {
    super(objectFieldName, jsonField, serializeEmpty)
  }

  protected getJsonValue(helper: JsonHelper) {
    return helper.getBigNumber(this.jsonFieldName)
  }

  protected toJsonValue(value: BigNumber): BasicJsonValue {
    if (_.isNil(value)) {
      return value
    }

    return value.toFixed()
  }
}

class CostField<T, K extends keyof T> extends SimpleField<T, K, Cost | null> {
  constructor(objectFieldName: K) {
    super(objectFieldName, '', false)
  }

  protected getJsonValue(helper: JsonHelper): Cost | null {
    return helper.getCost(this.jsonFieldName)
  }

  protected toJsonValue(): BasicJsonValue {
    throw new Error('Cost serialization TODO')
  }
}

export class DateField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  Date | null
> {
  format = ''

  constructor(objectFieldName: K, format: string) {
    super(objectFieldName)
    this.format = format
  }

  protected getJsonValue(helper: JsonHelper): Date | null {
    return helper.getDate(this.jsonFieldName, { format: this.format })
  }

  protected toJsonValue(value: Date | null): BasicJsonValue {
    if (_.isNil(value)) {
      return value
    }

    return formatJsonDate(value)
  }
}

export class DateTimeField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  Date | null
> {
  format = ''

  constructor(objectFieldName: K, format: string, jsonFieldName?: string) {
    super(objectFieldName, jsonFieldName)
    this.format = format
  }

  protected getJsonValue(helper: JsonHelper): Date | null {
    return helper.getDateTime(this.jsonFieldName, { format: this.format })
  }

  protected toJsonValue(value: Date | null): BasicJsonValue {
    if (_.isNil(value)) {
      return value
    }

    return formatJsonDateTime(value)
  }
}

export class BooleanField<T, K extends keyof T> extends SimpleField<
  T,
  K,
  boolean
> {
  constructor(objectFieldName: K, jsonFieldName = '', serializeEmpty = false) {
    super(objectFieldName, jsonFieldName, serializeEmpty)
  }

  protected getJsonValue(helper: JsonHelper): boolean {
    return helper.getBoolean(this.jsonFieldName)
  }

  protected toJsonValue(value: boolean): BasicJsonValue {
    return value
  }
}

export class SchemaDeSer<T> implements DeSer<T> {
  private readonly parser: Parser<T>
  constructor(
    readonly fields: Array<DeSer<T>>,
    readonly creator: Creator<T>
  ) {
    this.parser = toParser(creator, this.update)
  }

  update = (target: T, helper: JsonHelper): void => {
    this.fields.forEach(field => {
      field.update(target, helper)
    })
  }

  serialize = (target: T, builder: JsonBuilder): void => {
    this.fields.forEach(field => {
      field.serialize(target, builder)
    })
  }

  toParser = (creator?: Creator<T>) =>
    toParser(creator ?? this.creator, this.update)

  stringify = (target: T): string => {
    const builder = new JsonBuilder()
    this.serialize(target, builder)
    return JSON.stringify(builder.json)
  }

  parse = (text: string): T => {
    const parsed = JSON.parse(text) as JsonMap
    const helper = new JsonHelper(parsed)
    return this.parser(helper)
  }
}

export class SchemaDeSerBuilder<T, K extends keyof T = keyof T> {
  readonly fields: Array<DeSer<T>> = []

  constructor(readonly creator: Creator<T>) {}

  addAll(schemaFields: Array<DeSer<T>>): this {
    schemaFields.forEach(field => this.fields.push(field))
    return this
  }

  add(schemaField: DeSer<T>): this {
    this.fields.push(schemaField)
    return this
  }

  ofStrings(...objectFields: K[]): this {
    for (const field of objectFields) {
      this.ofString(field)
    }
    return this
  }

  ofString(objectField: K, jsonField?: string): this {
    return this.add(new StringField(objectField, jsonField))
  }

  ofNullableString(objectField: K, jsonField?: string): this {
    return this.add(new StringField(objectField, jsonField, true))
  }

  ofInt(
    objectField: K,
    {
      serializeEmpty = false,
      jsonField
    }: { serializeEmpty?: boolean; jsonField?: string } = {}
  ): this {
    return this.add(new IntField(objectField, serializeEmpty, jsonField))
  }

  ofFloat(objectField: K, jsonField?: string): this {
    return this.add(new FloatField(objectField, jsonField))
  }

  ofDate(objectField: K, format = ''): this {
    return this.add(new DateField(objectField, format))
  }

  ofDateTime(objectField: K, format = '', jsonField?: string): this {
    return this.add(new DateTimeField(objectField, format, jsonField))
  }

  ofCost(objectField: K): this {
    return this.add(new CostField(objectField))
  }

  ofBoolean(objectField: K): this {
    return this.add(new BooleanField(objectField))
  }

  ofBooleans(...objectFields: K[]): this {
    for (const field of objectFields) {
      this.ofBoolean(field)
    }
    return this
  }

  ofBigNumber(
    objectField: K,
    {
      serializeEmpty = false,
      jsonField
    }: { serializeEmpty?: boolean; jsonField?: string } = {}
  ): this {
    return this.add(new BigNumberField(objectField, serializeEmpty, jsonField))
  }

  ofNested<U>(
    objectField: K,
    schemaDeSer: SchemaDeSer<U>,
    creator: Creator<U>,
    jsonField?: string
  ): this {
    return this.add(
      new NestedField(objectField, schemaDeSer, creator, jsonField)
    )
  }

  ofArray<U>(
    objectField: K,
    schemeDeSer: SchemaDeSer<U>,
    creator: Creator<U>,
    jsonField?: string
  ): this {
    return this.add(
      new ArrayField(objectField, schemeDeSer, creator, jsonField)
    )
  }

  ofPrimitiveArray(objectField: K, jsonField?: string): this {
    return this.add(new PrimitiveArrayField(objectField, jsonField))
  }

  ofArrayMap<U, V>(
    objectField: K,
    schemeDeSer: SchemaDeSer<U>,
    arrayMapSupplier: () => ArrayMap<V, U>,
    jsonKeyExtractor: JsonKeyExtractor<V>
  ): this {
    return this.add(
      new ArrayMapField(
        objectField,
        schemeDeSer,
        arrayMapSupplier,
        jsonKeyExtractor
      )
    )
  }

  ofCustomEnum<V>(
    objectField: K,
    jsonField: string,
    converter: (value: string) => V
  ): this {
    return this.add(new CustomEnumField(converter, objectField, jsonField))
  }

  toDeSer(): SchemaDeSer<T> {
    return new SchemaDeSer(this.fields, this.creator)
  }
}
