import { tick } from "svelte"

export { default as clickOutside } from "./click-outside.js"
export * from "./constants.ts"
export * from "./create-property-proxy.js"
export * from "./cryptocurrency.ts"
export * from "./drag-pan.js"
export * from "./lock.ts"
export * from "./login"
export * from "./logout"
export * from "./media-query.ts"
export * from "./messages.ts"
export * from "./promise-hash.ts"
export * from "./radio-button.js"
export * from "./style"
export * from "./style-dimensions.js"
export * from "./tab-context.js"
export * from "./tax-center.ts"
export * from "./timeout.ts"
export * from "./unique-context"
export * from "./video.js"

let scheduledWindowResize = false // Limit resize to once per run loop
export async function triggerWindowResize() {
  if (scheduledWindowResize) {
    return
  }
  scheduledWindowResize = true
  await tick()
  window.dispatchEvent(new Event("resize"))
  scheduledWindowResize = false
}
