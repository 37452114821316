<script context="module">import { reactiveContext } from "../../helpers";
const { getContext, setContext } = reactiveContext({
  anchor: null,
  arrow: null
});
export const getPopperRootContext = getContext;
</script>

<script>setContext();
</script>

<slot />
