import {
  JsonHelper,
  parseErrorContainer,
  SingleResponse
} from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import { InvalidSessionError, toJsonHelper } from '../../tastyworks/http'
import type Logger from '../../tastyworks/logger'
import { toFormData } from '../../tastyworks/request'
import {
  parseSingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import type { TwLogin } from '../../tastyworks/session'
import { ClientDomain, LOGIN_PARSER } from '../../tastyworks/session'
import TwUser, { USER_DESER } from '../../tastyworks/user'

export class SessionService {
  private readonly sessionsClient: HttpClient

  constructor(
    baseHttpClient: HttpClient,
    private readonly logger: Logger
  ) {
    this.sessionsClient = baseHttpClient.nested('sessions')
  }

  readonly login = async (
    login: string,
    password: string,
    clientDomain: ClientDomain = ClientDomain.Customer
  ): Promise<SingleResponse<TwLogin>> => {
    const url = this.sessionsClient.baseUrl.toUrl('')
    const body = toFormData({ login, password, clientDomain })

    // Do a raw fetch request to bypass SessionErrorHandler
    const headers: HeadersInit = {}

    const fetchResponse = await this.sessionsClient.fetcher(url, {
      body,
      headers,
      method: 'POST'
    })

    const helper = await toJsonHelper(fetchResponse)

    const response = parseSingleResponse(helper, LOGIN_PARSER)

    return response
  }

  readonly validate = async (
    sessionToken: string
  ): Promise<SingleResponse<TwUser>> => {
    if (!sessionToken) {
      throw new Error('sessionToken required')
    }

    let helper: JsonHelper | null = null
    try {
      helper = await this.sessionsClient.postJson('validate', {})
    } catch (err) {
      if (!(err instanceof InvalidSessionError)) {
        throw err
      }
      helper = await toJsonHelper(err.response)
    }

    const user = new TwUser()
    const response = updateSingleResponse(helper, user, USER_DESER.update)
    user.sessionToken = sessionToken

    return response
  }

  readonly validateOneTimePassword = async (
    oneTimePassword: string
  ): Promise<SingleResponse<null>> => {
    this.sessionsClient.session.oneTimePassword = oneTimePassword
    try {
      await this.sessionsClient.postJson('validate-one-time-password', {})
      return new SingleResponse<null>()
    } catch (err) {
      if (!(err instanceof InvalidSessionError)) {
        throw err
      }
      const response = new SingleResponse<null>()
      const helper = await toJsonHelper(err.response)
      parseErrorContainer(helper, response)
      return response
    }
  }

  readonly logout = async (sessionToken: string): Promise<boolean> => {
    if (!sessionToken) {
      return false
    }

    const url = this.sessionsClient.baseUrl.toUrl('')
    try {
      await this.sessionsClient.fetch(url, 'DELETE')
      return true
    } catch (error) {
      this.logger.warn('Error on logout', error)
      return false
    }
  }

  async requestOneTimePassword(
    resetPasswordToken: string | undefined = undefined
  ): Promise<any> {
    return this.sessionsClient.postJson(
      'one-time-password-request',
      {},
      resetPasswordToken ? { resetPasswordToken } : undefined
    )
  }
}
