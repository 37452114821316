<script lang="ts">
  import Modal from "./Modal.svelte"

  export let show = false
  export let lightbox = false
  export let height = "50vh"
  export let width = "50vw"
  export let inescapeableLightbox = false
  let cssClass = ""
  export { cssClass as class }
  let cssDialogClass = ""
  export { cssDialogClass as dialogClass }
  let cssStyle = ""
  export { cssStyle as style }
</script>

<Modal
  class="modal-dialog {cssClass}"
  {inescapeableLightbox}
  {show}
  {lightbox}
  {height}
  {width}
  on:click-outside
>
  <div
    class="
      flex
      h-full
      w-full
      flex-col
      items-stretch
      overflow-hidden
      rounded
      border
      border-solid
      border-general-default
      bg-general-primary-surface
      p-extra-large
      {cssDialogClass}"
    style={cssStyle}
  >
    <div
      class="
        title
        flex-0
        flex
        flex-row
        items-center
        p-extra-small"
    >
      <slot name="title" />
    </div>

    <div
      class="
        body
        flex
        flex-grow
        flex-col
        overflow-auto
        p-extra-small"
    >
      <slot name="body" />
    </div>

    <div
      class="
        actions
        flex-0
        flex
        flex-row
        items-center
        justify-center
        gap-small
        p-extra-small"
    >
      <slot name="actions" />
    </div>
  </div>
</Modal>
