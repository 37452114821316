import Anchor from "./PopperAnchor.svelte";
import Content from "./PopperContent.svelte";
import Root from "./PopperRoot.svelte";
export const SIDE_OPTIONS = ["top", "right", "bottom", "left"];
export const ALIGN_OPTIONS = ["start", "center", "end"];
export const Popper = {
    Root,
    Anchor,
    Content,
};
