<!-- @component
  cross-browser and localized date input mimicking the native HTML5 date input in behavior
-->
<script context="module">import { collectionContext, generateId } from "../../../internal/helpers";
const collection = collectionContext();
export const getDateInputCollectionContext = collection.getContext;
export const dateFormatter = new Intl.DateTimeFormat(void 0, {
  month: "2-digit",
  day: "2-digit",
  year: "numeric"
});
function findNearestInput(inputs, coord) {
  const distances = inputs.map((input) => {
    const { left, bottom, top, right } = input.getBoundingClientRect();
    const x = Math.max(left, Math.min(coord.x, right));
    const y = Math.max(top, Math.min(coord.y, bottom));
    return Math.sqrt((x - coord.x) ** 2 + (y - coord.y) ** 2);
  });
  const minDistance = Math.min(...distances);
  return inputs[distances.indexOf(minDistance)];
}
export function focusPrevInput(inputs, current) {
  const index = inputs.indexOf(current);
  if (index > 0) {
    inputs[index - 1].focus();
  }
}
export function focusNextInput(inputs, current) {
  const index = inputs.indexOf(current);
  if (index < inputs.length - 1) {
    inputs[index + 1].focus();
  }
}
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);
const ISO_DATE_PARTS = [
  {
    type: "year",
    value: "",
    placeholder: "yyyy",
    min: 0,
    max: 9999,
    order: 0
  },
  {
    type: "literal",
    value: "-",
    order: 1
  },
  {
    type: "month",
    value: "",
    placeholder: "mm",
    min: 1,
    max: 12,
    order: 2
  },
  {
    type: "literal",
    value: "-",
    order: 3
  },
  {
    type: "day",
    value: "",
    placeholder: "dd",
    min: 1,
    max: 31,
    order: 4
  }
];
const ISO_DATE_FORMAT = "YYYY-MM-DD";
const ISO_ORDER = {
  year: 0,
  month: 1,
  day: 2
};
function getValues(parts) {
  return parts.filter((part) => part.type !== "literal").sort((a, b) => ISO_ORDER[a.type] - ISO_ORDER[b.type]).map((part) => part.value);
}
function enhanceParts(parts, clear = false) {
  return ISO_DATE_PARTS.map((defaultPart, i) => {
    const partIndex = parts.findIndex(
      (part2, j) => (part2.type !== "literal" || j >= i) && part2.type === defaultPart.type
    );
    const part = parts[partIndex];
    return {
      ...defaultPart,
      ...part,
      type: part.type,
      value: clear && part.type !== "literal" ? "" : part.value,
      order: partIndex
    };
  });
}
function localize(value = void 0) {
  const date = dayjs(value);
  const parts = date.isValid() ? enhanceParts(dateFormatter.formatToParts(date.toDate())) : enhanceParts(dateFormatter.formatToParts(/* @__PURE__ */ new Date()), true);
  parts.sort((a, b) => a.order - b.order);
  return parts;
}
function arePartValuesEqual(a, b) {
  return a.every((part, i) => part.value === b[i].value);
}
</script>

<script>import { flip, offset, shift } from "@floating-ui/core";
import Icons from "@tastyworks/icons";
import Portal from "../../../internal/components/Portal.svelte";
import { createFloatingActions } from "../../../internal/helpers/floating";
import { cn } from "../../../utils";
import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { createEventDispatcher } from "svelte";
import Button from "../button/Button.svelte";
import DateInputText from "./DateInputText.svelte";
import DatePicker from "./DatePicker.svelte";
const dispatch = createEventDispatcher();
let className = "";
export { className as class };
export let iconClass = "";
export let disabled = false;
export let value = "";
export let min = "";
export let max = "";
export let ref = void 0;
export let open = false;
export let id = generateId("date-input");
export let error = false;
export let name = void 0;
export let useDatePicker = true;
let isValid = false;
let trigger = {};
function rerenderInputs() {
  trigger = {};
}
$: parts = localize(value);
$: handleParts(parts);
const inputs = collection.setContext();
function handleParts(parts2) {
  const values = getValues(parts2);
  const prevValue = value;
  if (!values.every(Boolean)) {
    isValid = false;
    value = "";
  } else {
    const candidate = dayjs(values.join("-"), ISO_DATE_FORMAT, true).utc(true);
    isValid = candidate.isValid();
    value = isValid ? candidate.format(ISO_DATE_FORMAT) : "";
  }
  if (value !== prevValue) {
    dispatch("change", { value });
  }
}
function _stepHelper(target, increment) {
  const index = parts.findIndex(
    (part) => part.type === target.dataset.partType
  );
  if (index < 0) throw new Error("Could not find part in collection");
  const { min: min2, max: max2, value: value2 } = parts[index];
  const currentValue = parseInt(value2);
  const boundary = increment ? max2 : min2;
  const oppositeBoundary = increment ? min2 : max2;
  if (currentValue === boundary) return;
  const modifier = increment ? 1 : -1;
  parts[index].value = (isNaN(currentValue) ? oppositeBoundary : currentValue + modifier).toString();
}
function incrementInput(target) {
  _stepHelper(target, true);
}
function decrementInput(target) {
  _stepHelper(target, false);
}
function handleSelect(event) {
  value = event.detail.value;
  dispatch("change", { value });
  open = false;
  rerenderInputs();
}
function _isAnyInputFocused() {
  return $inputs.some((input) => input === document.activeElement);
}
function handleMouseDown(event) {
  if (!value && !_isAnyInputFocused()) {
    $inputs[0].focus();
  } else {
    const nearest = findNearestInput($inputs, event);
    if (nearest && document.activeElement !== nearest) {
      nearest.focus();
    }
  }
}
function _isRelatedTargetWithinComponent(event) {
  return event.relatedTarget instanceof HTMLElement && (event.relatedTarget.closest(".date-time-picker") || ref?.contains(event.relatedTarget));
}
function closeDatePicker() {
  if (open) open = false;
}
function handleFocusOut(event) {
  if (_isRelatedTargetWithinComponent(event)) {
    return;
  }
  dispatch("blur", event);
  closeDatePicker();
  const newParts = localize(value);
  const partsDiffer = arePartValuesEqual(parts, newParts);
  if (partsDiffer) {
    parts = newParts;
    rerenderInputs();
  }
}
function handleEnter(target) {
  if (open) {
    open = false;
  } else {
    target.closest("form")?.submit();
  }
}
function handleKeyDown(event) {
  if (!(event.target instanceof HTMLElement)) {
    return;
  }
  switch (event.key) {
    case "ArrowLeft":
      focusPrevInput($inputs, event.target);
      break;
    case "ArrowRight":
      focusNextInput($inputs, event.target);
      break;
    case "ArrowUp":
      incrementInput(event.target);
      break;
    case "ArrowDown":
      decrementInput(event.target);
      break;
    case "Escape":
      closeDatePicker();
      break;
    case "Enter":
      handleEnter(event.target);
      break;
  }
}
function openDatePicker() {
  if (useDatePicker) {
    open = !open;
  }
}
const [floatingRef, floatingContent] = createFloatingActions({
  strategy: "absolute",
  placement: "bottom-start",
  middleware: [flip(), shift(), offset(1)]
});
function handleFocusIn(event) {
  if (!_isRelatedTargetWithinComponent(event)) {
    dispatch("focus", event);
  }
}
</script>

<!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
<div
  role="group"
  class={cn(
    "relative flex h-10 select-none items-center rounded bg-input-field-dropdown-primary px-3 py-2 text-general-secondary-label ring-inset font-small-500 focus-within:outline-none focus-within:ring-2 disabled:pointer-events-none disabled:cursor-not-allowed",
    {
      "text-general-primary-text": !disabled && isValid,
      "outline-inset outline outline-2 outline-offset-[-2px] outline-alert-error":
        error,
    },
    className
  )}
  {id}
  bind:this={ref}
  on:mousedown|preventDefault={handleMouseDown}
  on:keydown={handleKeyDown}
  on:focusout={handleFocusOut}
  on:focusout
  on:focusin={handleFocusIn}
  data-value={value}
  data-label-delegate={$inputs[0]?.id}
  use:floatingRef
  {...$$restProps}
>
  {#key trigger}
    {#each parts as part, index}
      {#if part.type === "literal"}
        <span>{part.value}</span>
      {:else}
        <div class="inline-block">
          <DateInputText
            id="{id}-{part.type}"
            label={part.type}
            length={part.placeholder.length}
            placeholder={part.placeholder}
            min={part.min}
            max={part.max}
            partType={part.type}
            {index}
            bind:value={part.value}
          />
        </div>
      {/if}
    {/each}
  {/key}
  <div class="flex-1 grow" />
  <Button
    variant="ghost"
    on:click={openDatePicker}
    tabindex={-1}
    class={[
      "-mx-2 -my-1.5 px-2 py-1.5 hover:bg-input-field-dropdown-secondary focus-visible:bg-input-field-dropdown-secondary focus-visible:ring-0 focus-visible:ring-offset-0",
      { hidden: !useDatePicker },
    ]}
  >
    <Icons.calendar
      class={cn(
        "date-icon text-general-secondary-label icon-double-extra-large",
        iconClass
      )}
    />
  </Button>
  {#if name}
    <input type="hidden" {name} {value} />
  {/if}
</div>
<Portal>
  <div
    id="{id}-date-picker"
    class="transition-opacity"
    use:floatingContent
    aria-hidden="true"
    class:opacity-0={!open}
    class:pointer-events-none={!open}
  >
    <DatePicker {min} {max} {value} on:select={handleSelect} />
  </div>
</Portal>

<style>
  [id$="-date-picker"] :global(.date-time-picker) {
    --date-picker-foreground: var(--color-text-general-primary-text);
    --date-picker-background: var(--color-background-accordion-header);
  }
</style>
