<script>import { getAlertRootContext } from "./AlertRoot.svelte";
let cssClass = void 0;
export { cssClass as class };
export let level = 4;
const rootCtx = getAlertRootContext();
</script>

<div
  class="heading mb-double-extra-small flex items-center font-x-small-600-caps {cssClass}"
  role="heading"
  aria-level={level}
>
  <slot>
    {$rootCtx.variant}
  </slot>
</div>
