<script context="module"></script>

<script>import { Popper } from "../../../internal/components";
import { cn } from "../../../utils";
import { createEventDispatcher, onMount } from "svelte";
import { selectTriggerVariants } from ".";
import Content from "./SelectContent.svelte";
import Indicator from "./SelectIndicator.svelte";
import { getSelectRootContext } from "./SelectRoot.svelte";
const rootCtx = getSelectRootContext();
const { triggerRef, inputRef } = $rootCtx;
export let behavior = "toggle";
export let ref = void 0;
onMount(() => {
  if (ref) {
    triggerRef.set(ref);
  }
});
const dispatch = createEventDispatcher();
function handlePointerUp(event) {
  const shouldProceed = dispatch(
    "pointerup",
    { originalEvent: event },
    { cancelable: true }
  );
  if (shouldProceed) {
    if (behavior === "toggle") {
      $rootCtx.toggleDialog();
    } else {
      $rootCtx.openSelectPanel();
    }
  }
}
</script>

<!-- HACK: pressing space generated an on:click event, which is insane, use on:mouseup -->
<Popper.Anchor
  on:pointerup={handlePointerUp}
  as="button"
  aria-haspopup="listbox"
  aria-expanded={$rootCtx.open}
  id="{$rootCtx.idPrefix}-trigger"
  {...$$restProps}
  bind:ref
  disabled={$rootCtx.disabled}
  tabindex={$inputRef ? -1 : 0}
  class={cn(selectTriggerVariants(), $$restProps.class)}
  on:focus={() => $inputRef?.focus()}
  on:keydown={$rootCtx.handleKeyDown}
>
  <slot selected={$rootCtx.selected}>
    <Content>
      {#if $rootCtx.selected}
        {$rootCtx.selected.label}
      {:else}
        <span class="text-general-secondary-label">
          <slot name="placeholder">
            {$rootCtx.placeholder}
          </slot>
        </span>
      {/if}
    </Content>
  </slot>

  <slot name="trigger-indicator">
    <Indicator />
  </slot>
</Popper.Anchor>
