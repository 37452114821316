<script context="module">const defaults = {
  open: false
};
const { setContext, getContext } = reactiveContext(defaults);
export const getTooltipRootCtx = getContext;
</script>

<script>import { reactiveContext } from "../../..";
import { Tooltip } from "radix-svelte";
const ctx = setContext({
  open: (v) => open = v
});
export let open = defaults.open;
export let delayDuration = 300;
export let disableHoverableContent = false;
$: ctx.update((state) => ({ ...state, open }));
</script>

<Tooltip.Root
  bind:open
  {delayDuration}
  {disableHoverableContent}
  {...$$restProps}
>
  <slot />
</Tooltip.Root>
