import type { Params } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'
import {
  OAUTH_APPLICATION_DESER,
  OAUTH_GRANT_DESER,
  OauthApplication,
  OauthGrant
} from '../../tastyworks/oauth'
import {
  BaseResponse,
  ItemsResponse,
  SingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import { RecaptchaClientType, RecaptchaVersion } from '../../tastyworks/user'

export class OauthService {
  private readonly oauthClient: HttpClient

  constructor(
    baseHttpClient: HttpClient,
    private readonly userMeClient: HttpClient
  ) {
    this.oauthClient = baseHttpClient.nested('oauth')
  }

  readonly index = async (
    clientId?: string
  ): Promise<ItemsResponse<OauthGrant>> =>
    this.userMeClient.indexSimple(
      OAUTH_GRANT_DESER.toParser(OauthGrant),
      '/oauth/grants',
      {
        'client-id': clientId
      }
    )

  readonly update = async (
    grantExternalId: string,
    active?: boolean,
    accountNumbers?: string[],
    allowAllAccounts?: boolean
  ): Promise<SingleResponse<OauthGrant>> => {
    const params: Params = {
      active,
      accountNumbers,
      allowAllAccounts
    }
    const helper = await this.userMeClient.patch('/oauth/grants/:id', params, {
      id: grantExternalId
    })

    return updateSingleResponse(
      helper,
      new OauthGrant(),
      OAUTH_GRANT_DESER.update
    )
  }

  readonly delete = async (grantExternalId: string): Promise<BaseResponse> =>
    this.userMeClient.destroy('/oauth/grants/:id', { id: grantExternalId })

  readonly show = async (
    clientId: string,
    redirectUri: string
  ): Promise<SingleResponse<OauthApplication>> =>
    this.oauthClient.show(
      new OauthApplication(),
      OAUTH_APPLICATION_DESER.update,
      '/client',
      { clientId, redirectUri }
    )

  readonly grant = async (
    clientId: string,
    codeChallenge: string,
    codeChallengeMethod: string,
    redirectUri: string,
    responseType: string,
    recaptchaToken: string,
    state?: string,
    scope?: string
  ): Promise<SingleResponse<OauthGrant>> =>
    this.oauthClient
      .postJson('/authorize', {
        'client-id': clientId,
        'code-challenge': codeChallenge,
        'code-challenge-method': codeChallengeMethod,
        'redirect-uri': redirectUri,
        'response-type': responseType,
        'recaptcha-client': RecaptchaClientType.WEB,
        'recaptcha-token': recaptchaToken,
        'recaptcha-version': RecaptchaVersion.V3,
        state,
        scope
      })
      .then(helper =>
        updateSingleResponse(helper, new OauthGrant(), OAUTH_GRANT_DESER.update)
      )
}
