<script context="module">
  import {
    createEventDispatcher,
    getContext,
    onDestroy,
    onMount,
    setContext,
    tick,
  } from "svelte"
  import DefaultCellRenderer from "./DefaultCellRenderer.svelte"
  import Vscrollbar from "./Vscrollbar.svelte"
  import { dragPan, px, timeout } from "/@/util"
  import goog from "/@lib/boulangerie"

  let identifier = 0

  const Observer = goog.module.get("com.dough.boule.Observer")
  export const LAST_STATE = Symbol.for("RECYCLER_VIEW_LAST_STATE")
  export const DRAG_STATE = Symbol.for("RECYCLER_VIEW_DRAG_STATE")
  export const NO_OP = () => {}
</script>

<script>
  import { writable } from "svelte/store"
  import { runLoopHelper } from "/@lib/tastyworks-rest/run-loop"

  const eventDispatcher = createEventDispatcher()

  let oldBouleArray = undefined
  let cssClass = ""
  export let bouleArray
  export let cellSize // if vertical, then cellSize == cellHeight, else cellSize == cellWidth
  export let CellRenderer = DefaultCellRenderer
  export let horizontal = false // else vertical
  export let hideScrollBars = false
  // Mostly for testing or to override dynamic view length behavior
  export let fixedViewLength = NaN
  export let preventScroll = false
  export { cssClass as class }
  export let placeholderText = "No content to show"
  export let id = `recycler-view-${identifier++}`
  export let dragAndDrop = false // else dragPan
  // bind only
  export let viewportElement = null
  // if using DragAndDrop, you must provide a CellRenderer with draggable="true" that accepts the handler props
  // see SidebarWatchlistEditRowRenderer

  // disable dragPan if dragAndDrop is enabled, else we get wonky behavior
  const dragPanAction = dragAndDrop ? NO_OP : dragPan
  const handleDragLeave = dragAndDrop
    ? (_e) => {
        toIndex = null
      }
    : NO_OP
  const handleDrop = (_e) => {
    eventDispatcher("move", { fromIndex, toIndex })
    toIndex = null
    fromIndex = null
  }
  const dragstartHandler = (e) => {
    dragging = true
    fromIndex = parseInt(e.target.dataset.itemindex)
  }
  const dragendHandler = (e) => {
    dragging = false
    toIndex = parseInt(e.target.dataset.itemindex)
  }
  const dragoverHandler = ({ detail }) => {
    toIndex = detail
  }

  // TODO: offsetTop may change dynamically, may need to make this more complicated later
  $: scrollBarTopOffset = viewportElement?.offsetTop

  const lastRecyclerViewState = getContext(LAST_STATE)
  onMount(async () => {
    if (
      lastRecyclerViewState &&
      lastRecyclerViewState.bouleArraySize === bouleArray.size()
    ) {
      await tick()
      setScrollLengthOffset(lastRecyclerViewState.scrollOffset)
    }
  })

  function updateCellItems(startItemIndex) {
    const endItemIndex = startItemIndex + cellCount
    const bouleArrayLength = bouleArray?.size() ?? 0

    for (
      let itemIndex = startItemIndex;
      itemIndex < endItemIndex;
      ++itemIndex
    ) {
      const cellIndex = itemIndex % cellCount

      const cell = cells[cellIndex]
      const { component, element } = cell
      if (itemIndex < bouleArrayLength) {
        const item = bouleArray.getAtIndex(itemIndex)
        component.$set({
          empty: false,
          index: itemIndex,
          item,
          ...(dragAndDrop
            ? {
                dragoverHandler,
                dragstartHandler,
                dragendHandler,
              }
            : {}),
        })
        element.__boule_item = item
      } else {
        component.$set({
          empty: true,
          index: itemIndex,
          item: null,
          ...(dragAndDrop
            ? {
                dragoverHandler,
                dragstartHandler,
                dragendHandler,
              }
            : {}),
        })
        element.__boule_item = null
      }

      const lengthOffset = px(itemIndex * cellSize)
      if (horizontal) {
        element.style.top = "auto"
        element.style.left = lengthOffset
      } else {
        element.style.top = lengthOffset
        element.style.left = "auto"
      }
    }

    if (bouleArray.size()) {
      eventDispatcher("firstItem", bouleArray.getAtIndex(startItemIndex))
    }
  }

  async function handleBouleArrayUpdates(arrayEvent) {
    const startIndex = arrayEvent.start
    const maxItemOffsetIndex = itemOffsetIndex + cellCount

    if (arrayEvent.setAt) {
      // In place update
      if (startIndex >= itemOffsetIndex && startIndex < maxItemOffsetIndex) {
        const cellIndex = startIndex % cellCount
        const cell = cells[cellIndex]
        const component = cell.component
        const item = bouleArray.getAtIndex(startIndex)
        component.$set({
          item,
        })
      }
    } else {
      // Update cells
      const bouleArrayLength = bouleArray.size()
      hasContent = bouleArrayLength > 0

      updateContentDimensions()
      await tick() // Wait for maxScrollLength recalculation.

      if (maxScrollLength < scrollLengthOffset) {
        // Scrolled beyond the data-set; set causes refreshCellItems.
        setScrollLengthOffset(maxScrollLength, "auto")
      } else {
        refreshCellItems(scrollLengthOffset)
      }
    }
  }
  const bouleArrayObserver = Observer.$adapt(handleBouleArrayUpdates)

  function updateBouleArrayObserver(newBouleArray) {
    if (oldBouleArray) {
      oldBouleArray.removeArrayObserver(bouleArrayObserver)
    }

    if (newBouleArray) {
      newBouleArray.addArrayObserver(bouleArrayObserver)
    }

    oldBouleArray = newBouleArray
    hasContent = newBouleArray?.size() > 0
    updateContentDimensions()
    setScrollLengthOffset(0)
    refreshCellItems(0)
  }
  onDestroy(() => {
    if (oldBouleArray) {
      oldBouleArray.removeArrayObserver(bouleArrayObserver)
    }
  })
  $: updateBouleArrayObserver(bouleArray)

  /*
  Since this Recycler view can have vertial or horizontal orientation, height and width are not stable terms

  So breadth and length are used instead.

  The length is the expandable scroll direction and the breadth is fixed

  If orientation === vertical then length = height, breadth = width
  If orientation === horizontal then length = width, breadth = height
  */
  let viewLength = 0
  let viewBreadth = 0

  let hasContent = false

  let itemOffsetIndex = 0
  let viewportHeight, viewportWidth
  let contentElement
  let contentCount
  let cellCount = 0
  let cells = []
  let scrollLengthOffset = 0
  let maxScrollLength = 0

  $: updateViewportDimensions(viewportWidth, viewportHeight, fixedViewLength)

  function updateViewportDimensions(width, height, fixedLength) {
    const oldViewBreadth = viewBreadth
    const oldViewLength = viewLength
    if (isNaN(fixedLength) || fixedLength <= 0) {
      if (horizontal) {
        viewLength = width
        viewBreadth = height
      } else {
        viewLength = height
        viewBreadth = width
      }
    } else {
      viewLength = fixedLength
    }
    updateContentDimensions()

    if (oldViewBreadth !== viewBreadth) {
      eventDispatcher("viewBreadthChanged", viewBreadth)
    }
    if (oldViewLength !== viewLength) {
      eventDispatcher("viewLengthChanged", viewLength)
    }
  }

  $: {
    const contentLengthNumeric = cellSize * contentCount
    maxScrollLength =
      contentLengthNumeric > viewLength ? contentLengthNumeric - viewLength : 0
  }

  let contentLength = 0

  function updateContentDimensions() {
    contentCount = bouleArray?.size() ?? 0

    if (!contentElement) return

    contentLength = cellSize * contentCount
    const contentLengthStyle = px(contentLength)
    const contentBreadthStyle = px(viewBreadth)
    if (horizontal) {
      contentElement.style.width = contentLengthStyle
      contentElement.style.height = "auto"
    } else {
      contentElement.style.width = contentBreadthStyle
      contentElement.style.height = contentLengthStyle
    }
  }

  $: {
    const rawCellCount = Math.ceil(viewLength / cellSize) + 1
    cellCount = contentElement ? rawCellCount : 0
    cells = updateCells(cellCount)
  }
  function updateCells(newCellCount) {
    if (cells.length > newCellCount) {
      // remove extra cells
      const extras = cells.slice(newCellCount)

      for (const extra of extras) {
        extra.component.$destroy()
      }

      return cells.slice(0, newCellCount)
    }

    const addCount = newCellCount - cells.length
    if (addCount === 0) {
      return cells
    }

    const cellSizePx = px(cellSize)

    for (let i = 0; i < addCount; i++) {
      const component = new CellRenderer({
        props: {
          empty: true,
          index: -1,
          item: null,
          ...(dragAndDrop
            ? {
                dragoverHandler,
                dragstartHandler,
                dragendHandler,
              }
            : {}),
        },
        target: contentElement,
      })

      const element = contentElement.lastElementChild
      if (horizontal) {
        element.style.width = cellSizePx
      } else {
        element.style.height = cellSizePx
      }

      cells.push({
        component,
        element,
      })
    }

    return cells
  }

  /*
   behavior === "none" means don't have the viewportElement scroll as well.
   This is used when handling scroll/wheel events where the browser will already update the scroll position for us
   */
  function setScrollLengthOffset(rawPos, behavior = "auto") {
    if (preventScroll) return
    if (rawPos === undefined) {
      return
    }
    let pos = rawPos
    if (rawPos < 0) {
      pos = 0
    } else if (rawPos > maxScrollLength) {
      pos = maxScrollLength
    }

    if (scrollLengthOffset === pos) return

    scrollLengthOffset = pos
    if (lastRecyclerViewState) {
      lastRecyclerViewState.scrollOffset = pos
      lastRecyclerViewState.bouleArraySize = bouleArray.size()
    }
    if (!viewportElement) return

    if (behavior !== "none") {
      if (horizontal) {
        viewportElement.scrollTo({ behavior, left: pos })
      } else {
        viewportElement.scrollTo({ behavior, top: pos })
      }
    }

    if (scrollLengthOffset === 0) {
      eventDispatcher("approachingListStart", scrollLengthOffset)
    } else if (scrollLengthOffset === maxScrollLength) {
      eventDispatcher("approachingListEnd", scrollLengthOffset)
    } else {
      eventDispatcher("scroll", scrollLengthOffset)
    }
  }

  function getScrollLengthOffset() {
    return Math.min(
      horizontal ? viewportElement.scrollLeft : viewportElement.scrollTop,
      maxScrollLength
    )
  }

  function refreshCellItems(nextScrollLengthOffset) {
    if (!hasContent || viewLength < cellSize) return

    const calcItemOffsetIndex = Math.floor(nextScrollLengthOffset / cellSize)
    itemOffsetIndex =
      calcItemOffsetIndex > bouleArray.size()
        ? Math.max(0, bouleArray.size() - cellCount - 1)
        : calcItemOffsetIndex

    updateCellItems(itemOffsetIndex)
  }
  // viewLength not used but passed in to trigger reactive update on HMR
  $: refreshCellItems(scrollLengthOffset, viewLength, cellCount)

  // Scrolls to a given index. By default, the item is centred. If center is
  // false, the item is positioned at the start (vertical-top/horizontal-left).
  export async function scrollToIndex(
    index,
    behavior,
    center = true,
    delay = 0
  ) {
    await timeout(delay)
    let viewOffset = index * cellSize
    if (center) {
      const halfViewLength = viewLength / 2
      const targetOffset = (index + 0.5) * cellSize
      viewOffset = Math.max(0, targetOffset - halfViewLength)
    }
    setScrollLengthOffset(viewOffset, behavior)
  }

  export async function scrollToRelativeIndex(
    relativeIndex,
    behavior,
    delay = 0
  ) {
    if (!relativeIndex) return

    await timeout(delay)

    const initialOffset = getScrollLengthOffset()
    const firstIndex = Math.floor(initialOffset / cellSize)

    // Align to the respective cell-edge.
    const fraction = relativeIndex > 0 ? viewLength % cellSize : 0

    const targetOffset = (firstIndex + relativeIndex) * cellSize + fraction

    setScrollLengthOffset(targetOffset, behavior)
  }

  let dragPanStart
  function handleDragPanStart(_event) {
    dragPanStart = getScrollLengthOffset()
  }

  function handleDragPan(event) {
    const offset = horizontal ? event.detail.offsetX : event.detail.offsetY
    const next = offset + dragPanStart
    setScrollLengthOffset(next)
  }

  const scrollThrottle = runLoopHelper.throttle(16 /* 60 FPS */, () => {
    setScrollLengthOffset(getScrollLengthOffset(), "none")
  })
  onDestroy(() => scrollThrottle.cancel())

  function handleScroll(_event) {
    scrollThrottle.schedule()
  }

  function handleScrollBar(event) {
    viewportElement.scrollTop = event.detail
  }

  const dragDestination = writable(null)
  const dragOrigin = writable(null)
  const numberOfCells = writable(-1)
  let fromIndex = null,
    toIndex = null,
    dragging = false
  $: $dragDestination = dragging ? toIndex : null
  $: $dragOrigin = fromIndex
  $: $numberOfCells = cellCount
  setContext(DRAG_STATE, { dragDestination, dragOrigin, numberOfCells })
</script>

<div
  use:dragPanAction
  role="presentation"
  on:dragpan={handleDragPan}
  on:dragpan
  on:dragpandown={handleDragPanStart}
  on:dragpanup
  class={`recycler-viewport hide-native-scrollbar ${cssClass}`}
  class:horizontal
  {id}
  bind:this={viewportElement}
  bind:clientHeight={viewportHeight}
  bind:clientWidth={viewportWidth}
  on:scroll={handleScroll}
  on:dragover|preventDefault
  on:drop={handleDrop}
  on:dragleave={handleDragLeave}
  on:pointermove
>
  {#if !hasContent}
    <div class="placeholder">
      <slot name="placeholder">{placeholderText}</slot>
    </div>
  {/if}
  <slot name="before-content" />
  <div
    role="status"
    class="recycler-content"
    bind:this={contentElement}
    class:empty={!hasContent}
  />
  <slot />
</div>
{#if !horizontal && !hideScrollBars}
  <Vscrollbar
    viewportId={id}
    topOffset={scrollBarTopOffset}
    scrollTop={scrollLengthOffset}
    wholeHeight={contentLength}
    trackHeight={viewportHeight}
    on:scrollTop={handleScrollBar}
  />
{/if}

<style lang="postcss">
  .recycler-viewport {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 0;

    &.horizontal {
      overflow-x: auto;
      overflow-y: hidden;
    }
  }

  .placeholder {
    text-align: center;
    padding: var(--medium-spacing);
  }

  .recycler-content {
    > :global(*) {
      position: absolute;
    }
  }
</style>
