import createClient from "/@lib/tastyworks-rest/create-client"
import session from "/@lib/tastyworks-rest/session"
import { SessionErrorHandler } from "@tastyworks/tastyworks-api"
import { handleHttpNetworkError } from "/@lib/shared"
import { replace } from "svelte-spa-router"

const customSessionErrorHandler: SessionErrorHandler = {
  onInvalidSession: async () => {
    await replace("/")
  },
  onOTPRequired: (_response: Response) => {},
  onHttpNetworkError: handleHttpNetworkError,
}

export default createClient(
  import.meta.env.VITE_TW_API_BASE_URL,
  import.meta.env.VITE_TW_VAST_API_BASE_URL,
  customSessionErrorHandler,
  session
)
