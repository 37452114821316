import goog from "@tastyworks/boulangerie-bundle"
import { push } from "svelte-spa-router"
import { futuresRollEntryManager } from "./tw-context.js"

const JsNavigationCallback = goog.module.get("tasty.js.tw.JsNavigationCallback")

function navigateToOrderEntry(tradeMode) {
  push(`/trading/order/${tradeMode.mode}`)
}

let _preferences = null
async function _lazyLoadPrefernces() {
  // [DG] circular dependency circumvented by lazy loading
  if (_preferences === null) {
    const twContext = await import("./tw-context")
    _preferences = twContext.appTwSession.remoteWebPreferences
  }
  return _preferences
}

export class WebNavigationCallback extends JsNavigationCallback {
  async openOptionTrading() {
    // TODO: should go to last options trade mode
    const { lastOptionsTradeModeVisited } = await _lazyLoadPrefernces()
    navigateToOrderEntry(lastOptionsTradeModeVisited.getValue())
  }

  async openUnderlyingOnlyTrading() {
    const { lastSingleTradeModeVisited } = await _lazyLoadPrefernces()
    navigateToOrderEntry(lastSingleTradeModeVisited.getValue())
  }

  confirmCancelComplexOrder(_complexOrderStatus, _description) {
    // XXX: Confirm happens at context-menu handling due to sync requirement.
    //      This just avoids altering the way the menu-item works (potential
    //      impact to other client-platforms.
    return true
  }

  canViewComplexOrder() {
    // TODO implement view complex order
    return false
  }

  viewComplexOrder(_complexOrderStatus) {
    // TODO implement view complex order
  }

  showReplaceFuturesRoll(status) {
    futuresRollEntryManager.edit(status)
    // May be triggered from right-sidebar; navigate to positions.
    push("/trading/positions")
  }

  startRollFuturesForPosition(positionEntry) {
    futuresRollEntryManager.startRoll(positionEntry)
    // May be triggered from right-sidebar; navigate to positions.
    push("/trading/positions")
  }

  openBracketOrderTrading() {
    // There's a good chance we don't actually go anywhere.
    push("/trading/positions")
  }

  openFixedIncomeTrading(_unusedSecurityEntry) {
    // FIXME: Need to sort out what to check in order to push to the right kind
    //        of bond.
    //        This'll stop working correctly when we support more than treasury
    //        bills/notes/bonds.
    push("/trading/fixed-income")
  }
}
