import _ from 'lodash'
import type { Parser, Updater } from './deser'
import type { PaginationParams, Params } from './request'
import type { ArrayMap } from './util/collection'
import type { JsonHelper, JsonKeyExtractor } from './util/json'
import { isNilOrEmpty } from './util/string'

export const NOT_FOUND_MESSAGE = 'not_found'
export const RECORD_NOT_FOUND_CODE = 'record_not_found'

export class Pagination {
  public totalItems = 0
  public currentItemCount = 0
  public perPage = 0
  public itemOffset = 0
  public pageOffset = 0
  public totalPages = 0

  get hasNextPage(): boolean {
    return this.pageOffset < this.totalPages - 1
  }

  get nextPaginationParams(): PaginationParams {
    if (!this.hasNextPage) {
      return {}
    }
    return { perPage: this.perPage, pageOffset: this.pageOffset + 1 }
  }
}

export class ErrorDetail {
  public domain = ''
  public reason = ''
  public message = ''
  public code = ''

  toString() {
    const acc = []
    if (this.domain.length > 0) {
      acc.push(this.domain)
    }
    if (this.reason.length > 0) {
      acc.push(this.reason)
    }
    if (this.message.length > 0) {
      acc.push(this.message)
    }

    return `[${acc.join(',')}]`
  }
}

export class ErrorContainer {
  public code = ''
  public message = ''
  public errors: ErrorDetail[] = []

  toString() {
    return `Code: ${this.code}, Message: ${this.message}, errors: ${this.errors
      .map(error => error.toString())
      .join('; ')}`
  }

  isEmpty() {
    return (
      isNilOrEmpty(this.code) &&
      isNilOrEmpty(this.message) &&
      !this.errors.length
    )
  }
}

const NO_ERROR = Object.freeze(new ErrorContainer())

export class BaseResponse {
  errorContainer: ErrorContainer = NO_ERROR

  get isValid() {
    return this.errorContainer === NO_ERROR
  }

  get isError() {
    return this.errorContainer !== NO_ERROR
  }

  get isNotFound() {
    if (!this.isError) {
      return false
    }

    return this.isNotFoundMessage || this.isRecordNotFound
  }

  get isNotFoundMessage() {
    return this.errorContainer.message === NOT_FOUND_MESSAGE
  }

  get isRecordNotFound() {
    return this.errorContainer.code === RECORD_NOT_FOUND_CODE
  }
}

export class SingleResponse<T> extends BaseResponse {
  public data: T | null = null

  public hasData(): this is { data: T } {
    return this.data !== null
  }
}

export function toArrayMap<K, V>(
  itemsResponse: ItemsResponse<V>,
  arrayMap: ArrayMap<K, V>
): SingleResponse<ArrayMap<K, V>> {
  const singleResponse = new SingleResponse<ArrayMap<K, V>>()
  singleResponse.errorContainer = itemsResponse.errorContainer
  singleResponse.data = arrayMap

  return singleResponse
}

export class ItemsResponse<T> extends BaseResponse {
  public pagination = new Pagination()
  public items: T[] = []
  public context: any = null
  public requestParams: Params | null = null

  public hasItems() {
    return this.items.length > 0
  }

  public nextRequestParams(): Params | null {
    if (!this.pagination.hasNextPage) {
      return null
    }

    return { ...this.requestParams, ...this.pagination.nextPaginationParams }
  }

  get isLastPage(): boolean {
    return !this.pagination.hasNextPage
  }
}

export function updatePagination(
  pagination: Pagination,
  helper: JsonHelper
): void {
  pagination.totalItems = helper.getInt('total-items')
  pagination.currentItemCount = helper.getInt('current-item-count')
  pagination.perPage = helper.getInt('per-page')
  pagination.itemOffset = helper.getInt('item-offset')
  pagination.pageOffset = helper.getInt('page-offset')
  pagination.totalPages = helper.getInt('total-pages')
}

export function parseErrorDetail(helper: JsonHelper): ErrorDetail {
  const errorDetail = new ErrorDetail()
  errorDetail.domain = helper.getString('domain')
  errorDetail.reason = helper.getString('reason')
  errorDetail.message = helper.getString('message')
  errorDetail.code = helper.getString('code')
  return errorDetail
}

export function updateErrorContainer(
  errorContainer: ErrorContainer,
  helper: JsonHelper
): void {
  errorContainer.code = helper.getString('code')
  errorContainer.message = helper.getString('message')

  errorContainer.errors = helper.parseArray('errors', parseErrorDetail)
}

export function parseErrorContainer(
  helper: JsonHelper,
  response: BaseResponse
): void {
  const errorContainer = new ErrorContainer()

  // DK/AM: oauth endpoints use different json keys in error case to follow spec
  if (helper.hasField('error-code') || helper.hasField('error-description')) {
    errorContainer.code = helper.getString('error-code')
    errorContainer.message = helper.getString('error-description')
  }

  if (helper.hasField('error')) {
    helper.updateObject('error', errorContainer, updateErrorContainer)
  }

  if (!errorContainer.isEmpty()) {
    response.errorContainer = errorContainer
  }
}

export function updateSingleResponse<T>(
  helper: JsonHelper,
  target: T,
  updater: Updater<T>
): SingleResponse<T> {
  const response = new SingleResponse<T>()
  parseErrorContainer(helper, response)
  if (helper.hasField('data')) {
    helper.updateObject('data', target, updater)
    response.data = target
  }
  return response
}

export function parseSingleResponse<T>(
  helper: JsonHelper,
  parser: Parser<T>
): SingleResponse<T> {
  const response = new SingleResponse<T>()
  parseErrorContainer(helper, response)
  if (helper.hasField('data')) {
    const dataHelper = helper.getChild('data')
    const target = parser(dataHelper)
    response.data = target
  }
  return response
}

export function parseItemsResponse<T>(
  helper: JsonHelper,
  parser: Parser<T>
): ItemsResponse<T> {
  const response = new ItemsResponse<T>()
  parseErrorContainer(helper, response)
  if (helper.hasField('data')) {
    const data = helper.getChild('data')
    const items = data.parseArray('items', parser)
    helper.updateObject('pagination', response.pagination, updatePagination)
    response.items = items
  }
  return response
}

export function updateItemsResponse<K, V>(
  helper: JsonHelper,
  target: ArrayMap<K, V>,
  updater: Updater<V>,
  jsonKeyExtractor: JsonKeyExtractor<K>
): ItemsResponse<V> {
  const response = new ItemsResponse<V>()
  parseErrorContainer(helper, response)
  if (helper.hasField('data')) {
    const dataHelper = helper.getChild('data')
    dataHelper.updateArrayMap('items', target, updater, jsonKeyExtractor)

    helper.updateObject('pagination', response.pagination, updatePagination)
    response.items = target.values
  }
  return response
}

export function extractErrorDetailMessage(
  errorDetail: ErrorDetail
): string | null {
  const message = errorDetail.message
  if (!_.isEmpty(message)) {
    return message
  }

  return `${_.capitalize(errorDetail.domain)} ${errorDetail.reason}`
}

export function extractErrorMessage(response: BaseResponse): string | null {
  if (!response.isError) {
    return null
  }

  const errorContainer = response.errorContainer

  if (errorContainer.errors.length === 0) {
    return errorContainer.message ? errorContainer.message : errorContainer.code
  }

  return extractErrorDetailMessage(errorContainer.errors[0])
}
