import type { ComponentProps } from "svelte"
import { toast, type SvelteToast } from "@zerodevx/svelte-toast"
import AlertMessage from "./AlertMessage.svelte"
import { AlertSeverity } from "./alert-severity"
import TourAlertMessage from "./TourAlertMessage.svelte"
import { writable } from "svelte/store"

type SvelteToastOptions = ComponentProps<SvelteToast>["options"]

const defaultToastOptions = {
  // defaults direct from svelte-toast docs
  // progress bars are globally hidden in global.css
  classes: [], // user-defined classes
  dismissable: true, // allow dismiss with close button
  duration: 4000, // duration of progress bar tween to the `next` value
  initial: 1, // initial progress bar value
  intro: { x: 256 }, // toast intro fly animation settings
  next: 0, // next progress value
  pausable: false, // pause progress bar tween on mouse hover
  reversed: false, // insert new toast to bottom of stack
  theme: {
    float: "right",
    clear: "right",
  }, // css var overrides
}

export interface ExploreToursOption {
  type: "exploreTours"
}

export interface VideoTourOptions {
  type: "videoTour"
  videoId: string
  isIGApp: boolean
  tourType: "stock" | "options"
}

export type TourToastOptions = ExploreToursOption | VideoTourOptions

/**
 * Push an alert to the toast stack
 *
 * IMPORTANT: This requires the {@link SvelteToast} component to be mounted in the app
 * or else the toast will not display
 *
 * @param {AlertSeverity} severity the severity of the alert
 * @param {string} message  the message to display
 * @param {SvelteToastOptions} [options] any additional options to pass to the toast
 * @returns {number} the id of the toast
 */
export function toastAlert(
  severity: AlertSeverity,
  message: string,
  options: SvelteToastOptions = {}
) {
  return toast.push({
    ...defaultToastOptions,
    ...options,
    classes: [severity],
    component: {
      props: {
        ...options?.component?.props,
        message: message,
        severity: severity,
      },
      src: AlertMessage,
    },
  })
}

export function tourToastAlert(
  buttonText: string,
  message: string,
  onButtonClick: () => void,
  onClose: () => void = () => {},
  options: any = {},
  severity: AlertSeverity,
  tourToastOptions: TourToastOptions
) {
  const id = toast.push({
    ...defaultToastOptions,
    ...options,
    classes: [severity, "tour-toast"],
    component: {
      props: {
        ...options.component?.props,
        buttonText,
        message: message,
        onButtonClick: () => {
          onButtonClick()
          onClose()
          toast.pop(id)
        },
        onDismiss: () => {
          window.dataLayer?.push({
            event: "shepherd_explore_other_tours_cancel",
          })
          onClose()
          toast.pop(id)
        },
        severity: severity,
        tourToastOptions,
      },
      src: TourAlertMessage,
    },
    dismissable: false,
    initial: 1,
    next: 1,
    theme: {
      ...defaultToastOptions,
      ...options.theme,
      "--toastBackground": "var(--color-background-general-tertiary-surface)",
      "--toastBorder": "1px solid var(--color-border-general-default)",
    },
  })
  if (tourToastOptions.type === "videoTour") {
    window.dispatchEvent(
      new CustomEvent("watchVideo", { detail: tourToastOptions })
    )
  }
  return () => {
    onClose()
    toast.pop(id)
  }
}

export const isTourActive = writable(false)
