import * as Castle from '@castleio/castle-js'
import type HttpClient from '../../tastyworks/http'
import { recursiveDasherizeKeys } from '../../tastyworks/request'
import { BaseResponse, parseErrorContainer } from '../../tastyworks/response'

interface CastleIncidentParams {
  castleIncidentId: string
  incidentResponseToken: string
  isForcedPasswordRecovery: boolean
  password: string
  passwordConfirmation: string
  status: string
}

export const CASTLE_STATUSES = {
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected'
}

export class CastleIncidentService {
  constructor(private readonly httpClient: HttpClient) {}

  readonly update = async ({
    castleIncidentId,
    incidentResponseToken,
    password,
    passwordConfirmation,
    status,
    isForcedPasswordRecovery
  }: CastleIncidentParams): Promise<BaseResponse> => {
    let params = {
      incidentResponseToken,
      status
    }

    if (status === CASTLE_STATUSES.REJECTED || isForcedPasswordRecovery) {
      Object.assign(params, {
        password,
        passwordConfirmation
      })
    }

    // Attach Castle token
    let header = await Castle.createRequestToken()

    const helper = await this.httpClient.patch(
      `/castle-incidents/${castleIncidentId}`,
      recursiveDasherizeKeys(params),
      {},
      { 'X-Castle-Request-Token': header }
    )

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
