import _ from 'lodash'

export type CompleteListener = (requestState: RequestState) => void

export default class RequestState {
  static onInitialize = (_obj: RequestState) => {
    /* no-op */
  }

  constructor() {
    RequestState.onInitialize(this)
  }

  private _completeListeners: CompleteListener[] = []

  public static error(message = '') {
    const state = new RequestState()
    state.errorMessage = message
    state.isError = true
    return state
  }

  public static all(requestStates: RequestState[]): RequestState {
    const state = new RequestState()
    const completeListener = (_requestState: RequestState) => {
      const allComplete = _.every(requestStates, rs => rs.isComplete)
      if (!allComplete) {
        return
      }

      const errors = _.filter(requestStates, rs => rs.isError)
      if (!_.isEmpty(errors)) {
        state.syncWithRequest(_.first(errors)!)
      } else {
        state.isLoaded = true
      }
    }

    requestStates.forEach(rs => {
      rs.addListener(completeListener)
    })

    return state
  }

  private _isLoaded = false
  get isLoaded(): boolean {
    return this._isLoaded
  }

  set isLoaded(loaded: boolean) {
    if (this.isComplete) {
      return
    }

    this._isLoaded = loaded

    if (this.isLoaded) {
      this.isComplete = true
    }
  }

  private _isError = false
  get isError(): boolean {
    return this._isError
  }

  set isError(error: boolean) {
    if (this.isComplete) {
      return
    }

    this._isError = error

    if (this.isError) {
      this.isComplete = true
    }
  }

  private _errorMessage = ''
  get errorMessage(): string {
    return this._errorMessage
  }

  set errorMessage(message: string) {
    this._errorMessage = message
    if (!_.isEmpty(message)) {
      this.isError = true
    }
  }

  private _isComplete = false
  get isComplete(): boolean {
    return this._isComplete
  }

  set isComplete(complete: boolean) {
    this._isComplete = complete

    if (this.isComplete) {
      this.onComplete()
    }
  }

  protected readonly onComplete = () => {
    this._completeListeners.forEach(listener => {
      listener(this)
    })
    this._completeListeners = []
  }

  readonly addListener = (listener: CompleteListener) => {
    if (this.isComplete) {
      listener(this)
    } else {
      this._completeListeners.push(listener)
    }

    return this
  }

  readonly then = (listener: CompleteListener) => this.addListener(listener)

  readonly syncWithPromise = (promise: Promise<any>) => {
    promise
      .then(() => (this.isLoaded = true))
      .catch(() => (this.isError = true))
  }

  readonly syncWithRequest = (requestState: RequestState) => {
    if (requestState.isError) {
      this.errorMessage = requestState.errorMessage
      this.isError = requestState.isError
    } else if (requestState.isLoaded) {
      this.isLoaded = requestState.isLoaded
    } else {
      this.isComplete = requestState.isComplete
    }
  }

  readonly reset = () => {
    this.isComplete = false
    this.isLoaded = false
    this.isError = false
  }
}
