<svelte:options immutable />

<script>
  import BouleTextWrapper from "./BouleTextWrapper.svelte"
  import userAnalytics from "/@/util/user-analytics"

  export let source
  export let field
  export let userAnalyticsTag = null
  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }

  // all bouleTableCells receive columnType
  // svelte-ignore unused-export-let
  export let columnType = undefined
</script>

<BouleTextWrapper {source} {field} let:text>
  <span
    use:userAnalytics={userAnalyticsTag}
    class="boule-plain-text {cssClass}"
    class:empty={!text}
    style={cssStyle}
    title={text}>{text}</span
  >
</BouleTextWrapper>

<style>
  .boule-plain-text {
    display: inline-block;
  }
</style>
