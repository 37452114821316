import { readable, writable } from "svelte/store"

const NOOP_TRANSFORM = (value) => value

export function _boulePropertyStore(
  bouleProperty,
  transform = NOOP_TRANSFORM,
  nullable = false
) {
  if (bouleProperty === null || bouleProperty === undefined) {
    if (!nullable) {
      throw new Error(`non-nullable bouleProperty was supplied`)
    }
    return null
  }
  // Java primitive: int, boolean, double.
  if ("value" in bouleProperty) {
    return readable(
      bouleProperty.value,
      createSubscribePrimitive(bouleProperty, transform)
    )
  }

  // Java Object.
  return readable(
    bouleProperty.get(),
    createSubscribeObject(bouleProperty, transform)
  )
}

export function _bouleWritablePropertyStore(
  bouleProperty,
  transforms = { read: NOOP_TRANSFORM, write: NOOP_TRANSFORM },
  nullable = false
) {
  if (bouleProperty === null || bouleProperty === undefined) {
    if (!nullable) {
      throw new Error(`non-nullable bouleWritablePropertyStore was supplied`)
    }
    return null
  }
  // Java primitive: int, boolean, double.
  if ("value" in bouleProperty) {
    return {
      set: createSetPrimitive(bouleProperty, transforms.write),
      subscribe: createSubscribePrimitive(bouleProperty, transforms.read),
    }
  }

  // Java Object.
  return {
    set: createSetObject(bouleProperty, transforms.write),
    subscribe: createSubscribeObject(bouleProperty, transforms.read),
  }
}

function createSetPrimitive(prop, transform = NOOP_TRANSFORM) {
  const { set } = writable(prop.value)
  return (value) => {
    prop.value = transform(value)
    set(value)
  }
}

function createSetObject(prop, transform = NOOP_TRANSFORM) {
  const { set } = writable(prop.get())
  return (value) => {
    prop.set(transform(value))
    set(value)
  }
}

function createSubscribePrimitive(prop, transform = NOOP_TRANSFORM) {
  return (set) => {
    set(transform(prop.value))
    const tearDown = prop.addSimpleListener((_event) =>
      set(transform(prop.value))
    )
    return () => tearDown.tearDown()
  }
}

function createSubscribeObject(prop, transform = NOOP_TRANSFORM) {
  return (set) => {
    set(transform(prop.get()))
    const tearDown = prop.addSimpleListener((_event) =>
      set(transform(prop.get()))
    )
    return () => tearDown.tearDown()
  }
}

/**
 *
 * @param {? extends ReadOnlyProperty} bouleProperty
 * @returns read only svelte store
 */
export default function boulePropertyStore(
  bouleProperty,
  transform = NOOP_TRANSFORM
) {
  return _boulePropertyStore(bouleProperty, transform, false)
}

export function nullableBoulePropertyStore(
  bouleProperty,
  transform = NOOP_TRANSFORM
) {
  return _boulePropertyStore(bouleProperty, transform, true)
}

export function bouleWritablePropertyStore(
  bouleProperty,
  transforms = { read: NOOP_TRANSFORM, write: NOOP_TRANSFORM }
) {
  return _bouleWritablePropertyStore(bouleProperty, transforms, false)
}

export function nullableBouleWritablePropertyStore(
  bouleProperty,
  transform = NOOP_TRANSFORM
) {
  return _bouleWritablePropertyStore(bouleProperty, transform, true)
}

export function defaultBoulePropertyStore(bouleProperty, defaultValue = null) {
  return nullableBoulePropertyStore(bouleProperty) ?? readable(defaultValue)
}
