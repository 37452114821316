import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import {
  Withdrawal,
  type CHECK_DELIVERY_METHODS,
  type WIRE_BANK_ACCOUNT_TYPES
} from './withdrawal'
import { WITHDRAWAL_FEE_DESER, WithdrawalFee } from './withdrawal-fee'

export interface WithdrawalDryRunnable {
  amount: number
  accountNumber: string
  bankAccountType?: WIRE_BANK_ACCOUNT_TYPES
  deliveryMethod?: CHECK_DELIVERY_METHODS
  transferMethod: string
  achRelationshipId?: number | undefined
}

export const WITHDRAWAL_DRY_RUNNABLE_DESER: SchemaDeSer<WithdrawalDryRunnable> =
  new SchemaDeSerBuilder<WithdrawalDryRunnable, keyof WithdrawalDryRunnable>(
    Withdrawal
  )
    .ofFloat('amount')
    .ofString('bankAccountType')
    .ofString('deliveryMethod')
    .ofString('transferMethod')
    .ofInt('achRelationshipId')
    .toDeSer()

export class WithdrawalDryRun {
  amount = 0
  fees = [] as WithdrawalFee[]
  maxAmount = 0
  permittedWithdrawalAmount = 0
  netDebitAmount = 0

  get isFullWithdrawal() {
    return this.netDebitAmount >= this.permittedWithdrawalAmount
  }
}

export const WITHDRAWAL_DRY_RUN_DESER: SchemaDeSer<WithdrawalDryRun> =
  new SchemaDeSerBuilder(WithdrawalDryRun)
    .ofFloat('amount')
    .ofArray('fees', WITHDRAWAL_FEE_DESER, WithdrawalFee)
    .ofFloat('maxAmount')
    .ofFloat('netDebitAmount')
    .ofFloat('permittedWithdrawalAmount')
    .toDeSer()
