<svelte:options immutable />

<script context="module">
  import goog from "/@lib/boulangerie"

  const JavaConsumer = goog.module.get("java.util.function.Consumer")
</script>

<script>
  import { onDestroy } from "svelte"
  import { uiBouleRendererFactory } from "/@lib/boulangerie"

  export let source
  export let field
  let cssClass = ""
  export { cssClass as class }

  let text

  const consumer = JavaConsumer.$adapt((content) => (text = content))
  const renderer = uiBouleRendererFactory.createPrivacyAware(consumer)

  $: renderer.source = source
  $: renderer.field = field

  onDestroy(() => renderer.tearDown())
</script>

<span class="boule-privacy-aware-text {cssClass}" class:empty={!text}>
  <slot {text}>
    {text}
  </slot>
</span>
