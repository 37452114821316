<script lang="ts">
  import { key as formKey } from "@tastyworks/svelte-forms-lib"
  import { ValidateYup } from "@tastyworks/tastyworks-api"
  import { ButtonGroup, cn } from "@tastyworks/ui-library"
  import { createEventDispatcher, getContext } from "svelte"
  import { _, json } from "svelte-i18n"
  import InlineLayout from "/@/account-management/control/input-field-label/InlineLayout.svelte"
  import InputFieldLabel from "/@/account-management/control/input-field-label/InputFieldLabel.svelte"
  import LabelText from "/@/account-management/control/LabelText.svelte"
  import {
    ErrorMessage,
    FormContext,
    OptionEntry,
  } from "/@/account-management/form"
  import {
    findFieldTranslationKey,
    getByPath,
    getOptionText,
  } from "/@/account-management/form/util"

  const dispatch = createEventDispatcher()

  const context = getContext<FormContext>(formKey)
  const {
    form,
    isReadOnly,
    validationSchema,
    validateField,
    touched,
    updateField,
  } = context

  let cssClass = ""
  export { cssClass as class }
  export let name: string
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  export let label = $_(fieldTranslationKey)
  export let optionEntries: OptionEntry[] = []
  export let variant = InlineLayout
  export let buttonGroupClass = ""

  const { optionTranslationKey } = ValidateYup
  const optionsPath = optionTranslationKey(fieldTranslationKey)
  const buttons = buildButtons(optionEntries)

  let value = getByPath($form, name)
  $: handleChange(value)

  function buildButtons(optionEntries: OptionEntry[]) {
    return optionEntries.map((optionEntry) => {
      return {
        label: getOptionText(optionEntry, $json(optionsPath)),
        value: optionEntry.value,
      }
    })
  }

  function handleChange(value) {
    updateField(name, value)
    if (getByPath($touched, name)) {
      validateField(name)
    }
    dispatch("change", context)
  }
</script>

<div class="button-group {cssClass}">
  <InputFieldLabel {variant}>
    <LabelText slot="label" class="grow [flex-basis:_min-content]" {label} />

    <ButtonGroup.Root
      disabled={$isReadOnly}
      class={cn(buttonGroupClass, "button-group-root mt-small small:mt-0")}
      bind:value
      slot="input"
    >
      {#each buttons as { label, value }}
        <ButtonGroup.Item class="button-group-button w-1/2" {value}
          >{label}</ButtonGroup.Item
        >
      {/each}
    </ButtonGroup.Root>
    <ErrorMessage slot="error" {name} />
  </InputFieldLabel>
</div>
