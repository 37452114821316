import { location } from "svelte-spa-router"
import { derived } from "svelte/store"
import { defineTree } from "/@/util/tree.ts"

import About from "./modules/About.svelte"
import Accounts from "./modules/Accounts.svelte"
import AccountsHeader from "./modules/AccountsHeader.svelte"
import CapitalRequirementsColumns from "./modules/CapitalRequirementsColumns.svelte"
import Chart from "./modules/Chart.svelte"
import General from "./modules/General.svelte"
import PositionsColumns from "./modules/PositionsColumns.svelte"
import PositionsPositionDetail from "./modules/PositionsPositionDetail.svelte"
import Sound from "./modules/Sound.svelte"
import SymbolDetailHeader from "./modules/SymbolDetailHeader.svelte"
import TradeDisplay from "./modules/TradeDisplay.svelte"
import TradeFutures from "./modules/TradeFutures.svelte"
import TradeOptions from "./modules/TradeOptions.svelte"
import TradeOrder from "./modules/TradeOrder.svelte"
import TradeStocks from "./modules/TradeStocks.svelte"
import WatchlistsCenterMain from "./modules/WatchlistsCenterMain.svelte"
import WatchlistsLeftSidebar from "./modules/WatchlistsLeftSidebar.svelte"
import WatchlistsRightSidebar from "./modules/WatchlistsRightSidebar.svelte"
import goog from "/@lib/boulangerie"

const JsTradeMode = goog.module.get("tasty.js.ui.model.JsTradeMode")

export const SettingsTree = defineTree({
  label: "Settings",
  nodes: {
    ABOUT: {
      component: About,
      label: "About",
    },
    ACCOUNTS: {
      label: "Accounts",
      nodes: {
        ACCOUNTS: {
          component: Accounts,
          label: "Accounts",
        },
        HEADER: {
          component: AccountsHeader,
          label: "Header Detail",
        },
        SYMBOL: {
          component: SymbolDetailHeader,
          label: "Symbol Detail",
        },
      },
    },
    CHART: {
      component: Chart,
      label: "Chart",
    },
    GENERAL: {
      component: General,
      label: "General",
    },
    POSITIONS: {
      label: "Positions",
      nodes: {
        COLUMNS: {
          component: PositionsColumns,
          label: "Columns",
        },
        POSITION_DETAIL: {
          component: PositionsPositionDetail,
          label: "Position Detail",
        },
      },
    },
    POSITIONSREPORT: {
      label: "Position Reports",
      nodes: {
        CAPREQREPORT: {
          component: CapitalRequirementsColumns,
          label: "Cap Req Table",
        },
      },
    },
    SOUND: {
      component: Sound,
      label: "Sound",
    },
    TRADE: {
      label: "Trade",
      nodes: {
        DISPLAY: {
          component: TradeDisplay,
          label: "Display",
        },
        FUTURES: {
          component: TradeFutures,
          label: "Futures",
        },
        OPTIONS: {
          component: TradeOptions,
          label: "Options",
        },
        ORDER: {
          component: TradeOrder,
          label: "Order",
        },
        STOCK: {
          component: TradeStocks,
          label: "Stocks",
        },
      },
    },
    WATCHLISTS: {
      label: "Watchlists",
      nodes: {
        CENTER_MAIN: {
          component: WatchlistsCenterMain,
          label: "Center Main",
        },
        LEFT_SIDEBAR: {
          component: WatchlistsLeftSidebar,
          label: "Left Sidebar",
        },
        RIGHT_SIDEBAR: {
          component: WatchlistsRightSidebar,
          label: "Right Sidebar",
        },
      },
    },
  },
})

// XXX: Order matters due to prefix matching.
const LOCATION_SETTINGS = [
  { module: SettingsTree.ACCOUNTS, prefix: "/trading/account-history" },
  {
    module: SettingsTree.POSITIONSREPORT,
    prefix: "/trading/positions/capital-requirements",
  },
  { module: SettingsTree.CHART, prefix: "/trading/chart" },
  { module: SettingsTree.TRADE, prefix: "/trading/order" },
  { module: SettingsTree.POSITIONS, prefix: "/trading/positions" },
  { module: SettingsTree.WATCHLISTS, prefix: "/trading/watchlists" },
]

export const active = derived(location, ($location) => {
  if (
    $location.includes(JsTradeMode.TABLE.mode) ||
    $location.includes(JsTradeMode.CURVE.mode)
  ) {
    return SettingsTree.TRADE.OPTIONS
  }
  const mapping = LOCATION_SETTINGS.find((s) => $location.startsWith(s.prefix))
  return mapping?.module ?? SettingsTree.GENERAL
})
