import { Directions, Orientations, } from "../constants";
import { nextKey, prevKey } from "./keyboard";
/**
 * Automatically add and remove appropriate keyboard event listeners
 * to elements within a collection that are navigated together
 * e.g. Tabs, ButtonGroup, Select
 */
export function enableGroupKeyboardNavigation(collection, listenersMap, orientation = Orientations.HORIZONTAL, dir = Directions.LTR, loop = true) {
    const eventType = "keydown";
    collection.subscribe((elements) => {
        const enabledElements = elements.filter((el) => !el.dataset.disabled);
        // index might not be reliable
        elements.forEach((el, i) => {
            if (!el.id) {
                throw new TypeError("enableGroupKeyboardNavigation requires all elements to have a unique id, consider using UICL generateId()");
            }
            const previousListener = listenersMap.get(el.id);
            if (previousListener) {
                el.removeEventListener(eventType, previousListener);
            }
            const enabledIndex = enabledElements.indexOf(el);
            const listener = (e) => {
                if (e.key === nextKey(orientation, dir)) {
                    e.preventDefault();
                    nextItem(enabledElements, enabledIndex, loop)?.focus();
                }
                else if (e.key === prevKey(orientation, dir)) {
                    e.preventDefault();
                    prevItem(enabledElements, enabledIndex, loop)?.focus();
                }
            };
            listenersMap.set(el.id, listener);
            el.addEventListener(eventType, listener);
        });
    });
}
export function nextItem(items, index, loop = true) {
    return index === items.length - 1
        ? loop
            ? items[0]
            : items[index]
        : items[index + 1];
}
export function prevItem(items, index, loop = true) {
    return index === 0
        ? loop
            ? items[items.length - 1]
            : items[index]
        : items[index - 1];
}
