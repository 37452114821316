import {
  IRA_DISTRIBUTION_REASONS,
  IraDetail,
  TRANSFER_METHODS,
  WIRE_BANK_ACCOUNT_TYPES,
  WITHHOLDING_TYPES,
  Withdrawal,
  toStringValues,
} from "@tastyworks/tastyworks-api"
import { WithdrawalTransaction } from "./withdrawal-transaction"

export enum WIRE_WITHDRAWAL_FREQUENCIES {
  ONE_TIME = "One Time",
}

export const WIRE_WITHDRAWAL_FREQUENCY_VALUES = toStringValues(
  WIRE_WITHDRAWAL_FREQUENCIES
)

export class WireWithdrawal implements WithdrawalTransaction {
  amount: number = null
  bankAccountNumber = ""
  bankCity = ""
  bankCountry = ""
  bankName = ""
  bankRoutingNumber = ""
  federalWithholding: number | null = null
  federalWithholdingEnabled = false
  federalWithholdingType: WITHHOLDING_TYPES | null = null
  frequency = WIRE_WITHDRAWAL_FREQUENCIES.ONE_TIME
  furtherCredit = false
  furtherCreditAccountNumber = ""
  iban = ""
  isAnyIra = false
  hasConfirmedReviewed = false
  hasViewedIraDistributionRequest = false
  isInternational = false
  reason: IRA_DISTRIBUTION_REASONS | null = null
  stateWithholding: number | null = null
  stateWithholdingEnabled = false
  stateWithholdingType: WITHHOLDING_TYPES | null = null
  swiftCode = ""

  toWithdrawal(accountNumber: string) {
    const withdrawal = new Withdrawal()

    withdrawal.accountNumber = accountNumber
    withdrawal.amount = this.amount
    withdrawal.transferMethod = TRANSFER_METHODS.WIRE
    withdrawal.achRelationshipId = undefined
    withdrawal.bankAccountType = this.isInternational
      ? WIRE_BANK_ACCOUNT_TYPES.INTERNATIONAL
      : WIRE_BANK_ACCOUNT_TYPES.DOMESTIC

    if (!this.isInternational) {
      withdrawal.bankAccountNumber = this.bankAccountNumber
      withdrawal.bankRoutingNumber = this.bankRoutingNumber
    } else {
      withdrawal.bankAccountNumber = this.iban.replace(/\s/g, "")
      withdrawal.bankCity = this.bankCity
      withdrawal.bankCountry = this.bankCountry
      withdrawal.bankName = this.bankName
      withdrawal.bankRoutingNumber = this.swiftCode
    }

    if (this.furtherCredit) {
      withdrawal.furtherCreditAccountNumber = this.furtherCreditAccountNumber
    }

    if (this.isAnyIra) {
      const iraDetail = new IraDetail()
      iraDetail.distributionReason = this.reason

      if (this.federalWithholdingEnabled) {
        iraDetail.federalWithholding = this.federalWithholding
        iraDetail.federalWithholdingType = this.federalWithholdingType
      }

      if (this.stateWithholdingEnabled) {
        iraDetail.stateWithholding = this.stateWithholding
        iraDetail.stateWithholdingType = this.stateWithholdingType
      }

      withdrawal.iraDetail = iraDetail
    }

    return withdrawal
  }

  get isDryRunnable() {
    return this.amount !== null
  }
}
