<script>
  import { Alert } from "@tastyworks/ui-library"
  import { querystring } from "svelte-spa-router"

  const query = new URLSearchParams($querystring)
</script>

<Alert.Root variant="error">
  <Alert.Title>{query.get("code")}</Alert.Title>
  <Alert.Description>
    {query.get("message")}
  </Alert.Description>
</Alert.Root>
