import { Account, JsonHelper, TradingStatus } from '../../tastyworks'
import { Customer } from '../../tastyworks/customer'
import type { Parser } from '../../tastyworks/deser'
import TwUser, { USER_DESER } from '../../tastyworks/user'
import type { Creator } from '../../tastyworks/util/meta-prog'
import type { Params } from '../request'

export interface AdminCustomerAccountParams extends Params {
  phoneNumber?: string
  accountNumber?: string
  firstName?: string
  lastName?: string
  taxNumber?: string
  login?: string

  partialNameSearch: boolean
}

export class Authority {
  id = 0
  accountId = 0
  customerId = 0
  authorityLevel = ''
  entityPrimaryOwner = false
  createdAt: Date | null = null
  updatedAt: Date | null = null
  externalId = ''
}

const AUTHORITIES_DESER = {
  update: (target: Authority, helper: JsonHelper): void => {
    target.id = helper.getInt('id')
    target.accountId = helper.getInt('account_id')
    target.customerId = helper.getInt('customer_id')
    target.authorityLevel = helper.getString('authority_level')
    target.entityPrimaryOwner = helper.getBoolean('entity_primary_owner')
    target.createdAt = helper.getDateTime('created_at')
    target.updatedAt = helper.getDateTime('updated_at')
    target.externalId = helper.getString('external_id')
  },
  toParser: (creator: Creator<Authority>): Parser<Authority> => {
    return (helper: JsonHelper): Authority => {
      const result = new creator()
      AUTHORITIES_DESER.update(result, helper)
      return result
    }
  }
}

export const AUTHORITIES_DESER_PARSER = AUTHORITIES_DESER.toParser(Authority)

export const USER_DESER_PARSER = USER_DESER.toParser()

export class CustomerAccountRowResult {
  customer: Customer | undefined = undefined
  account: Account | undefined = undefined
  tradingStatus: TradingStatus | undefined = undefined
  authorities: Authority[] | undefined = undefined
  user: TwUser | undefined = undefined
}

export class RedFlagRecord {
  constructor(
    readonly notes: string,
    readonly nickname: string,
    readonly countryOfResidence: string
  ) {}
}

export const RED_FLAG_RECORD_PARSER = (helper: JsonHelper) =>
  new RedFlagRecord(
    helper.getString('notes'),
    helper.getString('nickname'),
    helper.getString('country-of-residence')
  )
