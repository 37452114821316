<svelte:options immutable />

<script>
  import IconCheckbox from "/@/control/IconCheckbox.svelte"

  export let preferenceField
  export let label

  let checked = preferenceField.getValue()
  $: preferenceField.setValue(checked)
</script>

<IconCheckbox
  class="checkbox-field text-general-secondary-text icon-extra-large"
  bind:checked
  let:click
>
  <button
    type="button"
    class="label cursor-pointer font-medium-500"
    on:click|stopPropagation={click}
  >
    {label}
  </button>
</IconCheckbox>
