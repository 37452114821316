<script>import { cn } from "../../../utils";
let className = void 0;
export { className as class };
</script>

<div
  class={cn("p-6 pt-0 text-general-primary-text", className)}
  {...$$restProps}
>
  <slot />
</div>
