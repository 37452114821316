const VITE_PRODUCTION_MODE = "production"
const VITE_IG_PRODUCTION_MODE = "ig-production"
const VITE_UAT_MODE = "uat"

export function isProduction() {
  // NOTE: [KT] avoiding import.meta.env.PROD as custom environments,
  // environments not defined by vite, default to true, e.g. staging
  return import.meta.env.MODE === VITE_PRODUCTION_MODE
}

export function isNotProduction() {
  return !isProduction()
}

export function isIgProduction() {
  return import.meta.env.MODE === VITE_IG_PRODUCTION_MODE
}

export function isUAT() {
  return import.meta.env.MODE === VITE_UAT_MODE
}

export function isAnyProduction() {
  return isProduction() || isIgProduction() || isUAT()
}
