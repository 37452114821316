<script context="module">const triggerContext = collectionContext();
export const getTriggerCollection = triggerContext.getContext;
</script>

<script>import { useActions } from "../../../internal/helpers";
import { collectionContext } from "../../../internal/helpers/collectionContext";
import { enableGroupKeyboardNavigation } from "../../../internal/helpers/group";
import { cn } from "../../../utils";
import { getTabsRootContext } from "./TabsRoot.svelte";
const rootCtx = getTabsRootContext();
export const triggerCollection = triggerContext.setContext();
let className = void 0;
export { className as class };
$: ({ dir, orientation } = $rootCtx);
const listeners = /* @__PURE__ */ new Map();
enableGroupKeyboardNavigation(triggerCollection, listeners, orientation, dir);
</script>

<div
  class={cn(
    "inline-flex items-center justify-center rounded bg-muted p-1 text-muted-foreground data-[orientation=horizontal]:h-10 data-[orientation=vertical]:h-fit",
    className
  )}
  role="tablist"
  data-orientation={$rootCtx.orientation}
  {...$$restProps}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
