import type { Parser } from './deser'
import type { JsonHelper } from './util/json'
import type { Creator } from './util/meta-prog'

// prettier-ignore

export class OrderFill {
  static onInitialize = (_obj: OrderFill) => {
    /* no-op */
  }

  constructor(readonly id: string) {
    OrderFill.onInitialize(this)
  }

  quantity = 0
  price = 0
  filledAt = new Date()
  externalGroupFillId = ''

  get totalPrice() {
    return this.quantity * this.price
  }
}
export const FILL_DESER = {
  update: (target: OrderFill, helper: JsonHelper): void => {
    target.externalGroupFillId = helper.getString('ext-group-fill-id')
    target.filledAt = helper.getDate('filled-at') ?? new Date('')
    target.quantity = helper.getInt('quantity')
    target.price = helper.getInt('price')
  },
  toParser: (creator: Creator<OrderFill>): Parser<OrderFill> => {
    return (helper: JsonHelper): OrderFill => {
      const result = new creator()
      FILL_DESER.update(result, helper)
      return result
    }
  }
}

export class OrderStatusLeg {
  static onInitialize = (_obj: OrderStatusLeg) => {
    /* no-op */
  }

  constructor() {
    OrderStatusLeg.onInitialize(this)
  }
  quantity = ''
  action = ''
  symbol = ''
  instrumentType = ''
  remainingQuantity = 0
  fills: OrderFill[] = []
}

export const LEG_DESER = {
  update: (target: OrderStatusLeg, helper: JsonHelper): void => {
    target.action = helper.getString('action')
    target.fills = helper.parseArray(
      'fills',
      FILL_DESER.toParser(OrderFill.bind('', ''))
    )
    target.instrumentType = helper.getString('instrument-type')
    target.quantity = helper.getString('quantity')
    target.remainingQuantity = helper.getInt('remaining-quantity')
    target.symbol = helper.getString('symbol')
  },
  toParser: (creator: Creator<OrderStatusLeg>): Parser<OrderStatusLeg> => {
    return (helper: JsonHelper): OrderStatusLeg => {
      const result = new creator()
      LEG_DESER.update(result, helper)
      return result
    }
  }
}
export class OrderStatus {
  static onInitialize = (_obj: OrderStatus) => {
    /* no-op */
  }

  constructor(
    readonly id: string,
    readonly accountNumber: string
  ) {
    OrderStatus.onInitialize(this)
  }
  size = ''
  underlyingSymbol = ''
  username = ''
  price = ''

  orderType = ''
  limitPrice = ''
  limitPriceEffect = ''
  timeInForce = ''
  legs: OrderStatusLeg[] = []

  editable = true
  confirmationStatus = ''
  status = ''
  stopTriggerPrice = 0

  timestampAt = new Date()
  updatedAt = 0

  cancellable = false
  cancelledAt: Date | null = null

  receivedAt = ''

  filledAt: Date | null = null

  terminalAt: Date | null = null

  gtcDate: Date = new Date()

  totalFilledQuantity = 0
}

export const ORDER_STATUS_DESER = {
  update: (target: OrderStatus, helper: JsonHelper): void => {
    target.cancellable = helper.getBoolean('cancellable')
    target.size = helper.getString('size')
    target.underlyingSymbol = helper.getString('underlying-symbol')
    target.username = helper.getString('username')
    target.status = helper.getString('status')
    target.receivedAt = helper.getString('received-at')
    target.timeInForce = helper.getString('time-in-force')
    target.legs = helper.parseArray('legs', LEG_DESER.toParser(OrderStatusLeg))
    target.price = helper.getString('price')
  },
  toParser: (creator: Creator<OrderStatus>): Parser<OrderStatus> => {
    return (helper: JsonHelper): OrderStatus => {
      const result = new creator()
      ORDER_STATUS_DESER.update(result, helper)
      return result
    }
  }
}
