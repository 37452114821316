import { readable } from "svelte/store"
import { isAnyProduction } from "/@/environment"
import { flagStore } from "/@lib/launch-darkly"

export const WATCHLIST_FEATURE_FLAG = isAnyProduction()
  ? flagStore("web-new-watchlist-layouts", {
      defaultValue: false,
      realtime: true,
    })
  : readable(true)
