<script context="module">
  const BOOLEAN_OPTION_ENTRIES = [{ value: true }, { value: false }]
  const REVERSED_BOOLEAN_OPTION_ENTRIES = [...BOOLEAN_OPTION_ENTRIES].reverse()
</script>

<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { getContext } from "svelte"
  import { _ } from "svelte-i18n"
  import ButtonGroup from "./ButtonGroup.svelte"
  import InlineLayout from "/@/account-management/control/input-field-label/InlineLayout.svelte"
  import { FormContext, OptionEntry } from "/@/account-management/form"
  import { findFieldTranslationKey } from "/@/account-management/form/util"

  const { validationSchema } = getContext<FormContext>(key)

  export let name: string
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  export let label = $_(fieldTranslationKey)
  export let variant = InlineLayout
  export let optionEntries: OptionEntry[] = []
  export let reversed = false
  let cssClass = ""
  export { cssClass as class }

  $: optionEntries = reversed
    ? REVERSED_BOOLEAN_OPTION_ENTRIES
    : BOOLEAN_OPTION_ENTRIES
</script>

<ButtonGroup
  class="
    form-boolean-button-group
    leading-extra-large
    my-medium
    w-full
    {name}
    {cssClass}"
  {name}
  {label}
  {fieldTranslationKey}
  {optionEntries}
  {variant}
  buttonGroupClass="small:w-40"
  on:change
/>
