<script context="module">const defaults = {
  type: "single",
  name: void 0,
  disabled: false,
  deselect: false,
  loop: true,
  rovingFocus: true,
  variant: "default",
  dir: Directions.LTR,
  orientation: Orientations.HORIZONTAL,
  value: null
};
const { setContext, getContext } = reactiveContext(defaults);
export const getButtonGroupRootContext = getContext;
const itemCollection = collectionContext();
export const getButtonGroupItemCollection = itemCollection.getContext;
</script>

<script>import {
  Directions,
  Orientations
} from "../../../internal/constants";
import {
  collectionContext,
  getUiclContext,
  reactiveContext,
  useActions
} from "../../../internal/helpers";
import { enableGroupKeyboardNavigation } from "../../../internal/helpers/group";
import { buttonGroupRootVariants } from "./variants";
let className = "";
export { className as class };
export let type = defaults.type;
export let name = defaults.name;
export let disabled = defaults.disabled;
export let deselect = type === "multiple";
export let loop = defaults.loop;
export let rovingFocus = defaults.rovingFocus;
export let variant = defaults.variant;
export let dir = defaults.dir;
export let orientation = defaults.orientation;
export let role = "group";
export let value = defaults.value;
const ctx = setContext({ value: (v) => value = v });
$: $ctx = {
  variant,
  value,
  name,
  type,
  orientation,
  dir,
  disabled,
  deselect,
  loop,
  rovingFocus
};
const itemStore = itemCollection.setContext();
const listeners = /* @__PURE__ */ new Map();
$: enableGroupKeyboardNavigation(itemStore, listeners, orientation, dir);
const v2 = !!getUiclContext()?.v2;
</script>

<div
  {role}
  {dir}
  {...$$restProps}
  data-disabled={disabled}
  data-orientation={orientation}
  class={buttonGroupRootVariants({ variant, className, v2 })}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
