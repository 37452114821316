<script context="module" lang="ts">
</script>

<script lang="ts">
  import { createForm } from "@tastyworks/svelte-forms-lib"
  import { Alert, Button, Input } from "@tastyworks/ui-library"
  import { object, string } from "yup"
  import { push } from "svelte-spa-router"
  import CustomCard from "./CustomCard.svelte"
  import twApiClient from "/@/tasty-oauth/tw-api-client"
  import { getStoredSessionToken } from "/@lib/shared"
  import { LoadingOverlay } from "/@/control"

  $: error = ""

  export let oauthFlowStateContext

  const { customerTwoFactorRequired, clientName, scope, prompt } =
    oauthFlowStateContext

  let promise = checkAlreadyLoggedIn()

  async function checkAlreadyLoggedIn() {
    if (prompt === "none") {
      let isValid = false
      const { twSession, sessionService } = twApiClient
      try {
        let { sessionToken } = twSession
        sessionToken = getStoredSessionToken()
        isValid = (await sessionService.validate(sessionToken)).hasData()
      } catch (_) {
        twApiClient.twSession.clear()
      }
      if (isValid) {
        await continueFlow()
      }
    }
  }

  const { form, isValid, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: object({
      username: string().required("Username is required"),
      password: string().required("Password is required"),
    }),
    onSubmit: async ({ username, password }) => {
      const response = await twApiClient.sessionService.login(
        username,
        password
      )

      if (response.isError || !response.hasData()) {
        error = response.errorContainer.message
        return
      }

      twApiClient.twSession.sessionToken = response.data?.sessionToken ?? ""

      await continueFlow()
    },
  })

  const continueFlow = async () => {
    if (scope?.includes("openid")) {
      const customer = await twApiClient.customerService.show()
      if (!customer.hasData()) {
        return push("/customer-profile-required")
      }
    }
    return push(customerTwoFactorRequired ? "/mfa" : "/confirm")
  }
</script>

{#await promise}
  <LoadingOverlay />
{:then _}
  <form
    class:valid={$isValid}
    on:submit={handleSubmit}
    on:change={() => (error = "")}
  >
    <CustomCard>
      <div>
        Sign in to grant access to <strong>{clientName}</strong>
      </div>
      <div>
        <Input
          id="username"
          placeholder="Username"
          bind:value={$form.username}
          on:change={handleChange}
        />
        {#if $errors.username}
          <p class="text-alerts-error">{$errors.username}</p>
        {/if}
      </div>

      <div>
        <Input
          id="password"
          type="password"
          placeholder="Password"
          bind:value={$form.password}
          on:change={handleChange}
        />
        {#if $errors.password}
          <p class="text-alerts-error">{$errors.password}</p>
        {/if}
      </div>
      <slot name="submitButton">
        <Button class="w-full" type="submit">Log In</Button>
      </slot>
      {#if error}
        <Alert.Root variant="error">
          <Alert.Description>
            {error}
          </Alert.Description>
        </Alert.Root>
      {/if}
    </CustomCard>
  </form>
{/await}
