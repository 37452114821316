<script>
  import { cn } from "@tastyworks/ui-library"
  import { isPrivacyMode } from "/@/logged-in/util"

  // Due to WB2 use on tastylive, and layout differences between WB2 and Desktop,
  // there are instances where we need to hide account fields in WB2 without
  // altering their visibility on other platforms

  let cssClass = ""
  export { cssClass as class }
  export let disabled = false // let us show specific fields (disable the privacy mode)
</script>

{#if $isPrivacyMode && !disabled}
  <span class={cn("text-general-primary-text font-x-small-600", cssClass)}
    >●●●●</span
  >
{:else}
  <slot class={cssClass} />
{/if}
