<script>import { Card } from "./";
import { cn } from "../../../utils";
let className = void 0;
export { className as class };
export let containerClass = "";
export let src = "";
export let alt = "";
</script>

<Card.Content class={cn("pl-0 pr-0", containerClass)}>
  <img {src} {alt} title={alt} class={className} />
</Card.Content>
