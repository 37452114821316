<script context="module">import { uniqueContext } from "../../..";
export var FileInputStatus = /* @__PURE__ */ ((FileInputStatus2) => {
  FileInputStatus2["EMPTY"] = "empty";
  FileInputStatus2["FILLED"] = "filled";
  return FileInputStatus2;
})(FileInputStatus || {});
const { setContext, getContext } = uniqueContext();
export const getFileInputRootContext = getContext;
</script>

<script>import { writable } from "svelte/store";
import { cn, fileInputVariants } from "..";
import Button from "../button/Button.svelte";
import Input from "../input/Input.svelte";
export let error = false;
export let disabled = false;
export let files = null;
let status = FileInputStatus.EMPTY;
let ref = void 0;
$: status = !files || files.length === 0 ? FileInputStatus.EMPTY : FileInputStatus.FILLED;
$: if (ref && status === FileInputStatus.EMPTY) {
  ref.value = "";
}
const rootCtx = writable({
  status,
  files
});
setContext(rootCtx);
function handleClick() {
  ref?.click();
}
async function handleChange(event) {
  files = event.target.files;
  if (files) {
    status = FileInputStatus.FILLED;
  }
}
async function handleCancel() {
  if (ref) {
    ref.value = "";
  }
  files = null;
}
$: rootCtx.update((state) => {
  return {
    ...state,
    status,
    files
  };
});
$: if (error && ref) {
  files = null;
  ref.value = "";
}
</script>

<Button
  variant="default"
  class={cn(fileInputVariants({ status, error }), $$restProps.className)}
  on:click={handleClick}
  type="button"
  aria-disabled={disabled}
  {...$$restProps}
>
  <slot {status} {files} />
</Button>

<Input
  type="file"
  class="hidden"
  bind:ref
  on:change={handleChange}
  on:change
  on:cancel={handleCancel}
  on:cancel
  accept={$$restProps.accept}
/>
