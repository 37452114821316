import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export const PER_PAGE = 10

class OrderFill {
  destinationVenue: string | null = null
  extExecId: string | null = null
  extGroupFillId: string | null = null
  fillId: string | null = null
  fillPrice: string | null = null
  filledAt: string | null = null
  quantity = 0
}

class OrderLeg {
  action: string | null = null
  instrumentType: string | null = null
  quantity = 0
  remainingQuantity = 0
  symbol: string | null = null
  fills: Array<OrderFill> = []
}

export class Order extends RestResource {
  accountNumber: string | null = null
  cancellable = false
  editable = false
  edited = false
  extClientOrderId: string | null = null
  extExchangeOrderNumber: string | null = null
  extGlobalOrderNumber = 0
  globalRequestId: string | null = null
  orderType: string | null = null
  price: string | null = null
  priceEffect: string | null = null
  receivedAt: string | null = null
  size = 0
  source: string | null = null
  status: string | null = null
  timeInForce: string | null = null
  underlyingInstrumentType: string | null = null
  underlyingSymbol: string | null = null
  updatedAt = 0
  legs: Array<OrderLeg> = []
}

export const ORDER_FILL_DESER: SchemaDeSer<OrderFill> = new SchemaDeSerBuilder(
  OrderFill
)
  .ofString('destinationVenue')
  .ofString('extExecId')
  .ofString('extGroupFillId')
  .ofString('fillId')
  .ofString('fillPrice')
  .ofString('filledAt')
  .ofInt('quantity')
  .toDeSer()

export const ORDER_LEG_DESER: SchemaDeSer<OrderLeg> = new SchemaDeSerBuilder(
  OrderLeg
)
  .ofString('action')
  .ofString('instrumentType')
  .ofInt('quantity')
  .ofInt('remainingQuantity')
  .ofString('symbol')
  .ofArray('fills', ORDER_FILL_DESER, OrderFill)
  .toDeSer()

export const ORDER_DESER: SchemaDeSer<Order> = new SchemaDeSerBuilder(Order)
  .ofInt('id')
  .ofString('accountNumber')
  .ofBoolean('cancellable')
  .ofBoolean('editable')
  .ofBoolean('edited')
  .ofString('extClientOrderId')
  .ofString('extExchangeOrderNumber')
  .ofInt('extGlobalOrderNumber')
  .ofString('globalRequestId')
  .ofString('orderType')
  .ofString('price')
  .ofString('priceEffect')
  .ofString('receivedAt')
  .ofInt('size')
  .ofString('source')
  .ofString('status')
  .ofString('timeInForce')
  .ofString('underlyingInstrumentType')
  .ofString('underlyingSymbol')
  .ofInt('updatedAt')
  .ofArray('legs', ORDER_LEG_DESER, OrderLeg)
  .toDeSer()
