<svelte:options immutable />

<script context="module">
  import { getCssPropertyValueNumeric } from "/@/util/style"
  import goog from "/@lib/boulangerie"
  const TitleType = goog.module.get("tasty.ui.util.TitleType")

  export function toTitleType(viewWidth) {
    const meter = getCssPropertyValueNumeric("--horizontal-meter")
    const compactThreshold = 2 * meter
    const descriptiveThreshold = 4 * meter
    if (viewWidth < compactThreshold) {
      return TitleType.COMPACT
    } else if (viewWidth > descriptiveThreshold) {
      return TitleType.DESCRIPTIVE
    }
    return TitleType.NORMAL
  }
</script>

<script>
  import { getContext } from "svelte"
  import { TABLE_HEADER_CELL_FACTORY } from "./BouleTable.svelte"
  import { defaultTableHeaderCellFactory } from "./common.js"
  import { boulePropertyStore } from "/@/boule"

  const headerCellFactory =
    getContext(TABLE_HEADER_CELL_FACTORY) ?? defaultTableHeaderCellFactory

  export let columnMeta
  export let rowHeight = undefined

  let cssClass
  export { cssClass as class }

  $: textAlignment = columnMeta.textAlignment
  $: viewWidth = boulePropertyStore(columnMeta.viewWidthProperty())
  $: leftOffset = boulePropertyStore(columnMeta.viewLayoutXProperty())
  $: styleString = `left: ${$leftOffset}px; width: ${$viewWidth}px; ${
    rowHeight && `height: ${rowHeight}px;`
  }`
</script>

<svelte:component
  this={headerCellFactory(columnMeta)}
  class="
    boule-table-header-cell
    boule-table-cell-align-{textAlignment.name()}
    outline-inset
    rounded
    font-x-small-600-caps {cssClass}"
  style={styleString}
  {viewWidth}
  {columnMeta}
/>

<style lang="postcss">
  .boule-table-header-cell {
    color: var(--color-text-general-primary-label);

    &.sortable {
      cursor: pointer;

      &.tw-icon-Arrow-Down,
      &.tw-icon-Arrow-Up {
        color: var(--color-text-general-default-text);
        &::before {
          /*
          * break arrows out of the positioning context
          * so they do not get clipped by `text-overflow: ellipsis`
          */
          position: absolute;
          left: 0;
        }
      }
    }
  }
</style>
