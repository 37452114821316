<script lang="ts">
  import { ColorScheme } from "/@/util/color-scheme"

  export let isOpen
  export let above
  export let colorScheme: ColorScheme
</script>

<span
  class="
      arrow-icon
      mr-double-extra-small
      flex
      items-center
      justify-center"
  class:open={isOpen}
  class:closed={!isOpen}
  class:tw-icon-Arrow-Down={isOpen && !above}
  class:tw-icon-Arrow-Right={!isOpen}
  class:tw-icon-Arrow-Up={isOpen && above}
  style={`
      --active-arrow-color: ${colorScheme.ACCOUNTS_SELECTOR_ARROW_ACTIVE};
      --inactive-arrow-color: var(--color-icon-expand-arrow-inactive);
    `}
/>

<style lang="postcss">
  .arrow-icon {
    &.open {
      color: var(--active-arrow-color);
    }
    &.closed {
      color: var(--inactive-arrow-color);
    }
  }
</style>
