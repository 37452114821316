import _ from 'lodash'
import {
  WITHDRAWAL_LIMIT_DESER,
  WithdrawalLimit
} from '../../tastyworks/cash-management'
import type HttpClient from '../../tastyworks/http'
import type { Params } from '../../tastyworks/request'
import type { SingleResponse } from '../../tastyworks/response'

export class WithdrawalLimitService {
  constructor(private readonly accountNumberClient: HttpClient) {}

  readonly show = async (
    accountNumber: string,
    achRelationshipId?: string
  ): Promise<SingleResponse<WithdrawalLimit>> =>
    this.accountNumberClient.show(
      new WithdrawalLimit(),
      WITHDRAWAL_LIMIT_DESER.update,
      'withdrawal-limits',
      _.merge({ accountNumber, achRelationshipId }) as Params
    )
}
