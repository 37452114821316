<script lang="ts" context="module">
  import { boulePropertyStore } from "/@/boule"
  import { ExternalLink } from "/@/control"
  import goog, { symbolSearchManager } from "/@lib/boulangerie"

  const SymbolSearchFilter = goog.module.get(
    "com.dough.service.SymbolSearchFilter"
  )
  const SymbolSearchManager = goog.module.get("tasty.ui.SymbolSearchManager")
</script>

<script lang="ts">
  import { isNilOrEmpty } from "@tastyworks/tastyworks-api"
  import { IG_APP } from "/@lib/shared"

  export let searchValue
  export let filter

  const header = boulePropertyStore(
    symbolSearchManager.filterHeaderTextProperty()
  )

  function showLink(filter) {
    return filter === SymbolSearchFilter.FUTURES.toString() && !IG_APP
  }
</script>

{#if isNilOrEmpty($searchValue)}
  <div
    class="
      flex
      h-extra-large
      items-center
      justify-between
      bg-general-secondary-surface
      px-small
      py-extra-small
      text-general-secondary-text
      font-xxx-small-500"
  >
    <span>{$header}</span>
    {#if showLink(filter)}
      <ExternalLink href={SymbolSearchManager.CME_LINK}>
        <span>{SymbolSearchManager.CME_TITLE}</span>
        <span class="tw-icon-Expand" />
      </ExternalLink>
    {/if}
  </div>
{/if}
