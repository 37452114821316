<script lang="ts">
  import { _ } from "svelte-i18n"
  import {
    generateRecaptchaToken,
    Recaptcha,
  } from "@tastyworks/svelte-forms-lib"
  import { Button } from "@tastyworks/ui-library"
  import CustomCard from "./CustomCard.svelte"
  import twApiClient from "/@/tasty-oauth/tw-api-client"
  import { grantZeroHash, isZeroHash } from "/@/tasty-oauth/zero-hash-hack"
  import { isNilOrEmpty } from "@tastyworks/tastyworks-api"
  import { LoadingOverlay } from "/@/control"

  export let oauthFlowStateContext
  const {
    clientId,
    clientName,
    codeChallenge,
    codeChallengeMethod,
    redirectUri,
    state,
    scope,
    responseType,
    prompt,
  } = oauthFlowStateContext

  const agreeText = $_("content.apiAccess.oauth.customerGrant.agree")

  let promise = checkGrantAlreadyExists()

  async function checkGrantAlreadyExists() {
    if (prompt === "none") {
      let response = await twApiClient.oauthService.index(clientId)
      if (!isNilOrEmpty(response.items)) {
        await handleAgree()
      }
    } else {
      return Promise.resolve()
    }
  }

  const handleAgree = async () => {
    if (isZeroHash(clientId, responseType)) {
      return grantZeroHash(redirectUri, state)
    }

    const finalRedirectUrl = new URL(redirectUri)
    if (state) {
      finalRedirectUrl.searchParams.append("state", state)
    }
    if (scope) {
      finalRedirectUrl.searchParams.append("scope", scope)
    }

    const grantResponse = await twApiClient.oauthService.grant(
      clientId,
      codeChallenge,
      codeChallengeMethod,
      redirectUri,
      responseType,
      await generateRecaptchaToken("oauth_grant"),
      state,
      scope
    )

    if (grantResponse.isError) {
      finalRedirectUrl.searchParams.append(
        "error",
        grantResponse.errorContainer.message
      )
    } else {
      finalRedirectUrl.searchParams.append("code", grantResponse.data!.authCode)
    }
    window.location.replace(finalRedirectUrl.toString())
  }
</script>

{#await promise}
  <LoadingOverlay />
{:then _}
  <CustomCard>
    <div class="pb-5">
      <!--
        HACK for Thinkific to make compliance happy
        TODO: remove this hack after properly implementing custom confirm banners
        -->
      {#if clientId === "71741567-6043-4498-8c1c-4f13ac9d20b5"}
        By continuing, certain personal information will be sent to Thinkific,
        who manages these courses. Your continued use of the site will be deemed
        as your continued consent to share your personal information.
        <br /><br />
        Please see
        <Button
          variant="link"
          href="https://www.thinkific.com/privacy-policy/"
          target="_blank"
          >Thinkific’s Privacy Policy
        </Button>
        for further details concerning the collection and processing of your personal
        information.
      {:else}
        By continuing, tastytrade will share your name, email, username with and
        allow {clientName} to authenticate your identity with tastytrade for the
        purpose of managing your {clientName} account.
      {/if}
    </div>
    <svelte:fragment slot="submitButton">
      <Button class="w-full" variant="confirmation" on:click={handleAgree}>
        {agreeText}
      </Button>
    </svelte:fragment>
  </CustomCard>
{/await}

<Recaptcha sitekey={import.meta.env.VITE_RECAPTCHA_SITEKEY} />
