import { cva } from "class-variance-authority";
import Content from "./SelectContent.svelte";
import Indicator from "./SelectIndicator.svelte";
import Input from "./SelectInput.svelte";
import Option from "./SelectOption.svelte";
import Panel from "./SelectPanel.svelte";
import Portal from "./SelectPortal.svelte";
import Root from "./SelectRoot.svelte";
import Trigger from "./SelectTrigger.svelte";
export const Select = {
    Root,
    Option,
    Panel,
    Portal,
    Trigger,
    Input,
    Indicator,
    Content,
};
export { getSelectRootContext } from "./SelectRoot.svelte";
export const selectFieldVariants = cva(`group relative inline-block w-[8.25rem] rounded text-general-primary-text font-small-500`, {
    variants: {
        error: {
            true: "outline outline-2 outline-offset-[-2px] outline-alert-error focus-within:outline-none",
        },
    },
});
export const selectTriggerVariants = cva(`
  inline-flex
  w-full
  items-center
  justify-between
  rounded
  bg-input-field-dropdown-primary
  p-2
  pl-3
  outline-none
  ring-inset
  focus-within:ring-2
  focus-visible:ring-2
  disabled:cursor-not-allowed
  group-data-[disabled=true]:bg-button-inactive`);
