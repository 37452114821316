<script context="module">
  import goog from "/@lib/boulangerie"

  const UiAccountPrivacyAware = goog.module.get(
    "tasty.ui.boule.UiAccountPrivacyAware"
  )
</script>

<script>
  import BoulePrivacyAwareText from "/@/boule/control/BoulePrivacyAwareText.svelte"
  import IconCheckbox from "/@/control/IconCheckbox.svelte"

  import TradingAccountIcon from "./TradingAccountIcon.svelte"
  import TradingAccountIndicator from "./TradingAccountIndicator.svelte"
  import { AutotradeBadge } from "/@/trading/control"
  import * as store from "./store.js"

  export let account

  const { currentAccount, focusedAccounts, includedAccounts } = store

  export let numberOfAccounts = $includedAccounts.length

  $: active = $currentAccount === account
</script>

<button
  class="
    trading-account-selector-item-header
    flex
    flex-row
    items-stretch
    gap-extra-small
    rounded-sm
    px-double-extra-small"
  class:bg-general-primary-surface={!active}
  class:bg-general-tertiary-surface={active}
  style:max-height="calc(1.35 * var(--vertical-meter))"
  on:click
>
  <div
    class="
      header-icon
      flex
      grow-0
      flex-col
      justify-center"
  >
    <TradingAccountIcon {account}>
      <svelte:fragment slot="badge">
        <AutotradeBadge
          isAutotradeEnabled={account.autoTradeEnabled}
          type="badge"
        />
      </svelte:fragment>
    </TradingAccountIcon>
  </div>

  <div
    class="
      header-text
      flex
      flex-grow
      flex-col
      items-start
      justify-center
      overflow-hidden
      text-general-default-text"
  >
    <BoulePrivacyAwareText
      class="display-name w-full truncate text-left font-small-600"
      source={account}
      field={UiAccountPrivacyAware.DISPLAY_NAME_ONLY}
    />

    <BoulePrivacyAwareText
      class="last-digits w-full truncate text-left font-x-small-400"
      source={account}
      field={UiAccountPrivacyAware.LAST_DIGITS_ONLY}
    />
  </div>

  <div
    class="
      header-indicator
      flex
      grow-0
      flex-col
      justify-center
      font-x-large-600"
  >
    <TradingAccountIndicator
      {active}
      on:click={() => ($currentAccount = account)}
    />
  </div>

  {#if numberOfAccounts > 1}
    <div
      class="
      header-focused
      flex
      grow-0
      flex-col
      justify-center
      font-xx-large-600"
    >
      <IconCheckbox
        class="focused"
        checked={$focusedAccounts.includes(account)}
        on:click={() => focusedAccounts.toggle(account)}
      />
    </div>
  {/if}
</button>
