<script>import { cn } from "../../../utils";
let className = void 0;
export { className as class };
</script>

<p
  class={cn("tracking-1 text-general-secondary-text font-small-500", className)}
  {...$$restProps}
>
  <slot />
</p>
