<script>import { cn } from "../../../utils";
import { alertIconVariants, renderIconByVariant } from ".";
import { getAlertRootContext } from "./AlertRoot.svelte";
export let icon = void 0;
let cssClass = void 0;
export { cssClass as class };
const rootCtx = getAlertRootContext();
</script>

<svelte:component
  this={icon ?? renderIconByVariant($rootCtx.variant)}
  class={cn(alertIconVariants({ variant: $rootCtx.variant }), cssClass)}
/>
