<script lang="ts">
  import { createForm } from "@tastyworks/svelte-forms-lib"
  import { TwoFactorMethod, TwoFactorType } from "@tastyworks/tastyworks-api"
  import { Alert, Button, Input } from "@tastyworks/ui-library"
  import { object, string } from "yup"
  import { push } from "svelte-spa-router"
  import { onMount } from "svelte"
  import CustomCard from "./CustomCard.svelte"
  import twApiClient from "/@/tasty-oauth/tw-api-client"

  $: error = ""
  $: info = ""

  $: isSms = false

  onMount(async () => {
    const showLatest = await twApiClient.twoFactorMethodService.showLatest()
    const { isActive, type } = showLatest.data as TwoFactorMethod

    if (!isActive) {
      await push("/mfa-required")
    }

    isSms = type === TwoFactorType.SMS

    if (isSms) {
      await twApiClient.sessionService.requestOneTimePassword()
    }
  })

  const { form, isValid, errors, handleChange, handleSubmit } = createForm({
    initialValues: {
      oneTimePassword: "",
    },
    validationSchema: object({
      oneTimePassword: string().required("One-time password is required"),
    }),
    onSubmit: async ({ oneTimePassword }) => {
      const validateConfirmationCodeResponse =
        await twApiClient.sessionService.validateOneTimePassword(
          oneTimePassword
        )

      if (validateConfirmationCodeResponse.isError) {
        error = validateConfirmationCodeResponse.errorContainer.message
        return
      }
      await push("/confirm")
    },
  })

  function resendOneTimePassword() {
    twApiClient.sessionService.requestOneTimePassword()
    info = "A new security code has been sent"
  }

  function resetAlerts() {
    error = ""
    info = ""
  }
</script>

<form class:valid={$isValid} on:submit={handleSubmit} on:change={resetAlerts}>
  <CustomCard>
    {#if isSms}
      We sent a security code to your mobile device to verify your identity.
      Please enter the security code below
    {:else}
      Please open the Authenticator App on your mobile device and enter the six
      digit code provided for tastytrade
    {/if}

    <Input
      id="oneTimePassword"
      placeholder="Security Code"
      bind:value={$form.oneTimePassword}
      on:change={handleChange}
    />
    {#if $errors.oneTimePassword}
      <p class="text-alerts-error">{$errors.oneTimePassword}</p>
    {/if}
    <slot name="submitButton">
      {#if isSms}
        <Button class="w-full" on:click={resendOneTimePassword}
          >Re-send Code
        </Button>
      {/if}
      <Button class="w-full" type="submit" variant="confirmation"
        >Verify
      </Button>
    </slot>
    {#if info}
      <Alert.Root variant="information">
        <Alert.Description>
          {info}
        </Alert.Description>
      </Alert.Root>
    {/if}
    {#if error}
      <Alert.Root variant="error">
        <Alert.Description>
          {error}
        </Alert.Description>
      </Alert.Root>
    {/if}
  </CustomCard>
</form>
