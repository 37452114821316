<script context="module">
  import goog from "/@lib/boulangerie"

  // QuantityField is an IntegerField.
  const IntegerField = goog.module.get(
    "com.dough.preference.model.IntegerField"
  )
  const DoubleField = goog.module.get("com.dough.preference.model.DoubleField")
  const MaxQuantityField = goog.module.get(
    "com.dough.preference.model.MaxQuantityField"
  )

  function getFieldType(field) {
    if (field instanceof IntegerField) {
      return IntegerField
    } else if (field instanceof DoubleField) {
      return DoubleField
    } else if (field instanceof MaxQuantityField) {
      return MaxQuantityField
    } else {
      throw new Error("Unsupported type for ComboboxField.")
    }
  }
</script>

<script>
  import PopoverDropdown from "/@/layout/popover/PopoverDropdown.svelte"

  export let label
  export let preferenceField

  let values
  export { values as options }

  let cssClass = ""
  export { cssClass as class }

  $: fieldType = getFieldType(preferenceField)
  $: options = values.map((v) => ({ label: fieldType.toString(v), value: v }))
</script>

<div
  class="combobox-field grid grid-cols-1 gap-y-double-extra-small {cssClass}"
>
  <span class="text-general-primary-label font-x-small-600-caps">
    {label}
  </span>
  <PopoverDropdown
    class="combobox"
    {options}
    on:change={({ detail }) => {
      preferenceField.setValue(detail.value)
      // Force input value to recompute for selected option.
      preferenceField = preferenceField
    }}
  >
    <svelte:fragment let:close>
      <input
        class="
          mx-double-extra-small
          border-none
          bg-transparent
          outline-none"
        type="text"
        value={fieldType.toString(preferenceField.getValue())}
        on:change={({ target }) => {
          try {
            preferenceField.setValue(fieldType.fromString(target.value))
          } catch (_) {
            // Noop; invalid user-input reverts.
          } finally {
            // Force input value to recompute for revert or special value.
            target.value = fieldType.toString(preferenceField.getValue())
            close()
          }
        }}
      />
    </svelte:fragment>
  </PopoverDropdown>
</div>
