<script>import { Popper } from "../../../internal/components";
import { getUiclContext } from "../../../internal/helpers/uiclContext";
import { cn } from "../../../utils";
import { getSelectRootContext } from "./SelectRoot.svelte";
export let width = "var(--popper-anchor-width)";
let ref;
const useV2Styling = !!getUiclContext()?.v2;
const rootCtx = getSelectRootContext();
const { panelRef } = $rootCtx;
$: $panelRef = ref;
</script>

<Popper.Content
  bind:ref
  data-state={$rootCtx.open ? "open" : "closed"}
  {...$$restProps}
  class={cn(
    "max-h-64 overflow-auto bg-transparent shadow-md",
    {
      "z-10": $rootCtx.open,
      hidden: !$rootCtx.open,
    },
    useV2Styling
      ? "mt-0.5 rounded border border-general-default"
      : "data-[side=bottom]:rounded-b data-[side=top]:rounded-t",
    $$restProps.class
  )}
  style="width: {width}"
>
  <!-- TODO: extract list box -->
  <slot name="listbox">
    <ul
      class={cn("max-w-full overflow-x-hidden outline-none", {
        "divide-y divide-general-default bg-interaction-dropdown-menu":
          useV2Styling,
      })}
      role="listbox"
      id="{$rootCtx.idPrefix}-panel"
    >
      <slot />
    </ul>
  </slot>
</Popper.Content>
