import { SchemaDeSerBuilder } from './deser'
import { Cost } from './util/cost'

export enum TimeOfDay {
  EndOfDay = 'EOD',
  BeginningOfDay = 'BOD'
}

export class Balance {
  static onInitialize = (_obj: Balance) => {
    /* no-op */
  }

  constructor() {
    Balance.onInitialize(this)
  }

  cashValue = 0
  dayTradeExcessValue = 0
  dayTradingBuyingPower = 0
  optionBuyingPower = 0
  stockBuyingPower = 0
  futuresOvernightMarginRequirement = 0
  effectiveCryptocurrencyBuyingPower = 0
  pendingCash = Cost.ZERO
  longBondValue = 0
  longCryptocurrencyValue = 0
  longStockValue = 0
  longOptionValue = 0
  longFixedIncomeValue = 0
  longFuturesValue = 0
  longFuturesOptionsValue = 0
  shortCryptocurrencyValue = 0
  shortStockValue = 0
  shortOptionValue = 0
  shortFuturesValue = 0
  shortFuturesOptionsValue = 0
  cashAvailableToWithdraw = 0
  netLiquidatingValue = 0
  marginEquity = 0
  maintenanceExcess = 0
  maintenanceRequirement = 0
  regTMarginRequirement = 0
  // Snapshot values:
  snapshotDate: Date | null = null
  timeOfDay: TimeOfDay | null = null

  get totalStockValue() {
    return this.longStockValue + this.shortStockValue
  }

  get totalOptionValue() {
    return this.longOptionValue + this.shortOptionValue
  }

  get isSnapshot(): boolean {
    return this.snapshotDate !== null && this.timeOfDay !== null
  }

  get pendingCashValueSigned(): number {
    return this.pendingCash.isDebit
      ? -this.pendingCash.value
      : this.pendingCash.value
  }

  get totalLongPositionValue() {
    return (
      this.longStockValue +
      this.longOptionValue +
      this.longBondValue +
      this.longCryptocurrencyValue +
      this.longFixedIncomeValue +
      this.longFuturesOptionsValue +
      this.longFuturesValue
    )
  }

  get totalShortPositionValue() {
    return (
      this.shortStockValue +
      this.shortOptionValue +
      this.shortCryptocurrencyValue +
      this.shortFuturesOptionsValue +
      this.shortFuturesValue
    )
  }

  get totalPositionValue() {
    return this.totalLongPositionValue - this.totalShortPositionValue
  }
}

export const BALANCE_DESER = new SchemaDeSerBuilder(Balance)
  .ofFloat('cashValue', 'cash-balance')
  .ofFloat('dayTradeExcessValue', 'day-trade-excess')
  .ofFloat('dayTradingBuyingPower')
  .ofFloat('optionBuyingPower', 'derivative-buying-power')
  .ofFloat('stockBuyingPower', 'equity-buying-power')
  .ofFloat(
    'effectiveCryptocurrencyBuyingPower',
    'effective-cryptocurrency-buying-power'
  )
  .ofCost('pendingCash')
  .ofFloat('futuresOvernightMarginRequirement')
  .ofFloat('longBondValue')
  .ofFloat('longCryptocurrencyValue')
  .ofFloat('longStockValue', 'long-equity-value')
  .ofFloat('longFixedIncomeValue', 'long-fixed-income-security-value')
  .ofFloat('longFuturesValue')
  .ofFloat('longFuturesOptionsValue', 'long-futures-derivative-value')
  .ofFloat('longOptionValue', 'long-derivative-value')
  .ofFloat('maintenanceExcess')
  .ofFloat('shortCryptocurrencyValue')
  .ofFloat('shortStockValue', 'short-equity-value')
  .ofFloat('shortOptionValue', 'short-derivative-value')
  .ofFloat('shortFuturesValue')
  .ofFloat('shortFuturesOptionsValue', 'short-futures-derivative-value')
  .ofFloat('marginEquity', 'margin-equity')
  .ofFloat('maintenanceRequirement', 'maintenance-requirement')
  .ofFloat('regTMarginRequirement', 'reg-t-margin-requirement')
  .ofFloat('cashAvailableToWithdraw')
  .ofFloat('netLiquidatingValue')
  .ofDate('snapshotDate')
  .ofString('timeOfDay')
  .toDeSer()

export const BALANCE_PARSER = BALANCE_DESER.toParser(Balance)
