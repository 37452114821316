<script context="module">
  export const leftSidebarOffset = tweened(0, {
    easing: cubicOut,
  })

  export const rightSidebarOffset = tweened(0, {
    easing: cubicOut,
  })
</script>

<script>
  import { cn } from "@tastyworks/ui-library"
  import { cubicOut } from "svelte/easing"
  import { tweened } from "svelte/motion"
  import {
    drawerOffset,
    isCam,
    isDashboard,
    isTrading,
    leftSidebarCollapsed,
    rightSidebarCollapsed,
    rightSidebarVisible,
  } from "."
  import media from "./media"
  import { getCssPropertyValueNumeric, px, triggerWindowResize } from "/@/util"
  import { IG_APP } from "/@lib/shared"

  const leftSidebarCollapsedWidth = IG_APP
    ? getCssPropertyValueNumeric("--ig-left-sidebar-collapsed-width")
    : getCssPropertyValueNumeric("--left-sidebar-collapsed-width")

  const leftSidebarExpandedWidth = getCssPropertyValueNumeric(
    "--left-sidebar-expanded-width"
  )

  const rightSidebarCollapsedWidth = getCssPropertyValueNumeric(
    "--right-sidebar-collapsed-width"
  )

  const rightSidebarExpandedWidth = getCssPropertyValueNumeric(
    "--right-sidebar-expanded-width"
  )

  const rightSidebarHiddenWidth = getCssPropertyValueNumeric(
    "--right-sidebar-hidden-width"
  )

  let prevMedia = null

  function handleMediaTransition(newMedia) {
    // on startup, do nothing
    if (prevMedia === null) {
      prevMedia = newMedia
      return
    }

    if (!newMedia.mobile && prevMedia.mobile && $isCam) {
      // grow -> left sidebar auto expand only in CAM
      $leftSidebarCollapsed = false
    }

    prevMedia = newMedia
  }

  function getLeftSidebarOffset(media, collapsed, isCam) {
    if (media.mobile || (collapsed && isCam)) {
      return 0
    } else if (collapsed) {
      return leftSidebarCollapsedWidth
    } else {
      return leftSidebarExpandedWidth
    }
  }

  function getRightSidebarOffset(
    collapsed,
    media,
    visible,
    isCam,
    isDashboard
  ) {
    if (media.large && (isDashboard || isCam)) {
      return rightSidebarExpandedWidth
    }
    if (visible) {
      if (!media.large) {
        return rightSidebarCollapsedWidth
      }
      return collapsed ? rightSidebarCollapsedWidth : rightSidebarExpandedWidth
    } else {
      return rightSidebarHiddenWidth
    }
  }

  $: handleMediaTransition($media)

  // pass in values to ensure updates on section navigation
  $: $leftSidebarOffset = getLeftSidebarOffset(
    $media,
    $leftSidebarCollapsed,
    $isCam
  )
  $: $rightSidebarOffset = getRightSidebarOffset(
    $rightSidebarCollapsed,
    $media,
    $rightSidebarVisible,
    $isCam,
    $isDashboard
  )

  // Notify boule table layouts of clientWidth change
  $: triggerWindowResize($leftSidebarOffset, $drawerOffset, $rightSidebarOffset)

  $: gridTemplateColumns = `
    ${px($leftSidebarOffset)}
    ${px($drawerOffset)}
    minmax(0, 1fr)
    ${px($rightSidebarOffset)}`

  $: gridTemplateAreas = `"${
    $media.mobile ? "left-sidebar" : "header"
  } header header header"
  "left-sidebar drawer main-content right-sidebar"`

  function handlePointerUp() {
    if (!$media.normal && !$leftSidebarCollapsed && !$isCam) {
      $leftSidebarCollapsed = true
    }
  }
</script>

<svelte:window on:pointerup={handlePointerUp} />

<div
  class={cn(
    "layout-wrapper relative grid h-full grid-rows-[auto_minmax(0,_1fr)] overflow-x-clip mobile:gap-y-0",
    { "gap-y-extra-small ": !$isTrading }
  )}
  style:grid-template-columns={gridTemplateColumns}
  style:grid-template-areas={gridTemplateAreas}
>
  <slot />
</div>
