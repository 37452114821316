<script>import { useActions } from "../../../internal/helpers";
import { cn } from "../../../utils";
export let innerText = "";
let content;
$: innerText = content?.textContent?.trim() ?? "";
</script>

<span
  bind:this={content}
  use:useActions={$$restProps.use}
  {...$$restProps}
  class={cn(
    `place-self-center
    overflow-hidden
    text-ellipsis
    whitespace-nowrap
    group-data-[disabled='true']:text-general-inactive-text`,
    $$restProps.class
  )}
>
  <slot />
</span>
