// Really just to enforce that we don't miss any required keys.
// Sticking with semantic naming here.
export interface ColorScheme {
  ACCOUNTS_SELECTOR_ARROW_ACTIVE: string
  DROPDOWN_EVEN_BACKGROUND: string
  DROPDOWN_ODD_BACKGROUND: string
  ALERT_CONFIRM_BACKGROUND: string
  ALERT_CONFIRM_COLOR: string
  ALERT_INFO_BACKGROUND: string
  ALERT_INFO_COLOR: string
  ALERT_REMINDER_BACKGROUND: string
  ALERT_REMINDER_COLOR: string
  BADGE_BACKGROUND: string
  BADGE_COLOR: string
  BUTTON_BUY_ACTIVE_BACKGROUND: string
  BUTTON_BUY_ACTIVE_BACKGROUND_HOVER: string
  BUTTON_BUY_ACTIVE_TEXT: string
  BUTTON_BUY_INACTIVE_BACKGROUND: string
  BUTTON_BUY_INACTIVE_BACKGROUND_HOVER: string
  BUTTON_BUY_INACTIVE_TEXT: string
  BUTTON_CONFIRM_BACKGROUND: string
  BUTTON_CONFIRM_BACKGROUND_HOVER: string
  BUTTON_CONFIRM_TEXT: string
  BUTTON_PRIMARY: string
  BUTTON_REVIEW_BACKGROUND: string
  BUTTON_REVIEW_BACKGROUND_HOVER: string
  BUTTON_SECONDARY: string
  BUTTON_SELL_ACTIVE_BACKGROUND: string
  BUTTON_SELL_ACTIVE_BACKGROUND_HOVER: string
  BUTTON_SELL_ACTIVE_TEXT: string
  BUTTON_SELL_INACTIVE_BACKGROUND: string
  BUTTON_SELL_INACTIVE_BACKGROUND_HOVER: string
  BUTTON_SELL_INACTIVE_TEXT: string
  BUTTON_SUBMIT: string
  CAM_BACKGROUND: string
  CAM_CARD_BORDER: string
  CHART_NEGATIVE: string
  CHART_POSITIVE: string
  COST_CREDIT: string
  COST_DEBIT: string
  CRYPTOCURRENCY_HISTORY_PENDING_TEXT: string
  CRYPTOCURRENCY_HISTORY_STATUS_OTHER_TEXT: string
  CRYPTOCURRENCY_HISTORY_REJECTED_TEXT: string
  DROPDOWN_BACKGROUND: string
  EXPAND_ACTIVE_COLOR: string
  HYPERLINK: string
  INPUTS_BACKGROUND: string
  MODAL_BACKGROUND: string
  MODAL_BACKGROUND_HEADER: string
  MODAL_BORDER: string
  NAVIGATION_ACTIVE_TAB: string
  REMOVE_WALLET_BUTTON_BACKGROUND: string
  TABLE_HEADER_BACKGROUND: string
  TRADE_BACKGROUND: string
  TABLE_EXPAND_BUTTON_TEXT: string
  TRADE_BORDER: string
  TRADE_HYPERLINK: string
}

export const CRYPTO_COLOR_SCHEME: ColorScheme = Object.freeze({
  ACCOUNTS_SELECTOR_ARROW_ACTIVE: "var(--color-zero-hash-brand-icon-green)",
  ALERT_CONFIRM_BACKGROUND: "var(--color-background-alert-success)",
  ALERT_CONFIRM_COLOR: "var(--color-zero-hash-brand-border-green)",
  ALERT_INFO_BACKGROUND: "var(--color-zero-hash-brand-cam-alert-purple)",
  ALERT_INFO_COLOR: "var(--color-zero-hash-brand-border-purple)",
  ALERT_REMINDER_BACKGROUND: "var(--color-zero-hash-brand-cam-alert-pink)",
  ALERT_REMINDER_COLOR: "var(--color-zero-hash-brand-border-pink)",
  BADGE_BACKGROUND: "var(--color-zero-hash-brand-button-purple)",
  BADGE_COLOR: "var(--color-text-general-static-white-text)",
  BUTTON_BUY_ACTIVE_BACKGROUND: "var(--color-zero-hash-brand-button-green)",
  BUTTON_BUY_ACTIVE_BACKGROUND_HOVER:
    "var(--color-zero-hash-brand-button-green)",
  BUTTON_BUY_ACTIVE_TEXT: "var(--color-text-general-inverted-text)",
  BUTTON_BUY_INACTIVE_BACKGROUND: "var(--color-background-button-default)",
  BUTTON_BUY_INACTIVE_BACKGROUND_HOVER:
    "var(--gradient-background-button-default-hover)",
  BUTTON_BUY_INACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_CONFIRM_BACKGROUND: "var(--color-zero-hash-brand-button-green)",
  BUTTON_CONFIRM_BACKGROUND_HOVER: "var(--color-zero-hash-brand-button-green)",
  BUTTON_CONFIRM_TEXT: "var(--color-text-general-inverted-text)",
  BUTTON_PRIMARY: "var(--color-zero-hash-brand-button-green)",
  BUTTON_REVIEW_BACKGROUND: "var(--color-zero-hash-brand-button-purple)",
  BUTTON_REVIEW_BACKGROUND_HOVER: "var(--color-zero-hash-brand-button-purple)",
  BUTTON_SECONDARY: "var(--color-zero-hash-brand-button-purple)",
  BUTTON_SELL_ACTIVE_BACKGROUND: "var(--color-zero-hash-brand-button-pink)",
  BUTTON_SELL_ACTIVE_BACKGROUND_HOVER:
    "var(--color-zero-hash-brand-button-pink)",
  BUTTON_SELL_ACTIVE_TEXT: "var(--color-text-general-inverted-text)",
  BUTTON_SELL_INACTIVE_BACKGROUND: "var(--color-background-button-default)",
  BUTTON_SELL_INACTIVE_BACKGROUND_HOVER:
    "var(--gradient-background-button-default-hover)",
  BUTTON_SELL_INACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_SUBMIT: "var(--color-zero-hash-brand-button-green)",
  CAM_BACKGROUND: `url("/zh/eclipse.svg"), var(--gradient-zero-hash-brand-cam-bg)`,
  CAM_CARD_BORDER: "2px solid var(--color-zero-hash-brand-border-purple)",
  CHART_NEGATIVE: "var(--color-zero-hash-brand-button-pink)",
  CHART_POSITIVE: "var(--color-zero-hash-brand-button-green)",
  COST_CREDIT: "var(--color-zero-hash-brand-button-green)",
  COST_DEBIT: "var(--color-zero-hash-brand-button-pink)",
  CRYPTOCURRENCY_HISTORY_PENDING_TEXT:
    "var(--color-zero-hash-brand-text-green)",
  CRYPTOCURRENCY_HISTORY_REJECTED_TEXT:
    "var(--color-text-general-inactive-text)",
  CRYPTOCURRENCY_HISTORY_STATUS_OTHER_TEXT:
    "var(--color-text-general-default-text)",
  DROPDOWN_BACKGROUND: "var(--color-zero-hash-brand-modal-bg-secondary)",
  DROPDOWN_EVEN_BACKGROUND: "var(--color-zero-hash-brand-bg-purple-1)",
  DROPDOWN_ODD_BACKGROUND: "var(--color-zero-hash-brand-bg-purple-2)",
  EXPAND_ACTIVE_COLOR: "var(--color-zero-hash-brand-button-green)",
  HYPERLINK: "var(--color-zero-hash-brand-text-green)",
  INPUTS_BACKGROUND: "var(--color-zero-hash-brand-modal-bg-secondary)",
  MODAL_BACKGROUND: `
    url("/zh/eclipse.svg") top right no-repeat,
    var(--gradient-zero-hash-brand-modal-bg)`,
  MODAL_BACKGROUND_HEADER:
    "var(--gradient-zero-hash-brand-modal-header-gradient)",
  MODAL_BORDER: "1px solid var(--color-zero-hash-brand-border-purple-50)",
  NAVIGATION_ACTIVE_TAB: "2px solid var(--color-zero-hash-brand-border-purple)",
  REMOVE_WALLET_BUTTON_BACKGROUND: "var(--color-zero-hash-brand-button-pink)",
  TABLE_EXPAND_BUTTON_TEXT: "var(--color-zero-hash-brand-text-pink)",
  TABLE_HEADER_BACKGROUND: "var(--color-zero-hash-brand-bg-purple-1)",
  TRADE_BACKGROUND: "var(--gradient-zero-hash-brand-web-bg)",
  TRADE_BORDER: "1px solid var(--color-zero-hash-brand-border-purple)",
  TRADE_HYPERLINK: "var(--color-zero-hash-brand-text-purple)",
})

export const DEFAULT_COLOR_SCHEME: ColorScheme = Object.freeze({
  ACCOUNTS_SELECTOR_ARROW_ACTIVE: "var(--color-icon-expand-arrow-active)",
  ALERT_CONFIRM_BACKGROUND: "var(--color-background-alert-success)",
  ALERT_CONFIRM_COLOR: "var(--color-border-alert-success)",
  ALERT_INFO_BACKGROUND: "var(--color-background-alert-information)",
  ALERT_INFO_COLOR: "var(--color-border-alert-information)",
  ALERT_REMINDER_BACKGROUND: "var(--color-background-alert-reminder)",
  ALERT_REMINDER_COLOR: "var(--color-border-alert-reminder)",
  BADGE_BACKGROUND: "var(--color-background-indicator-non-standard)",
  BADGE_COLOR: "var(--color-text-general-primary-text)",
  BUTTON_BUY_ACTIVE_BACKGROUND: "var(--color-background-button-buy)",
  BUTTON_BUY_ACTIVE_BACKGROUND_HOVER: "var(--color-background-button-buy)",
  BUTTON_BUY_ACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_BUY_INACTIVE_BACKGROUND: "var(--color-background-button-default)",
  BUTTON_BUY_INACTIVE_BACKGROUND_HOVER:
    "var(--gradient-background-button-default-hover)",
  BUTTON_BUY_INACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_CONFIRM_BACKGROUND: "var(--color-background-button-confirmation)",
  BUTTON_CONFIRM_BACKGROUND_HOVER:
    "var(--gradient-background-button-confirmation-hover)",
  BUTTON_CONFIRM_TEXT: "var(--color-text-general-primary-text)",
  BUTTON_PRIMARY: "var(--color-background-button-continue)",
  BUTTON_REVIEW_BACKGROUND: "var(--color-background-button-review-and-send)",
  BUTTON_REVIEW_BACKGROUND_HOVER:
    "var(--gradient-background-button-review-and-send-hover)",
  BUTTON_SECONDARY: "var(--color-background-button-confirmation)",
  BUTTON_SELL_ACTIVE_BACKGROUND: "var(--color-background-button-sell)",
  BUTTON_SELL_ACTIVE_BACKGROUND_HOVER: "var(--color-background-button-sell)",
  BUTTON_SELL_ACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_SELL_INACTIVE_BACKGROUND: "var(--color-background-button-default)",
  BUTTON_SELL_INACTIVE_BACKGROUND_HOVER:
    "var(--gradient-background-button-default-hover)",
  BUTTON_SELL_INACTIVE_TEXT: "var(--color-text-button-primary)",
  BUTTON_SUBMIT: "var(--color-background-button-confirmation)",
  CAM_BACKGROUND: "var(--color-background-general-primary-surface",
  CAM_CARD_BORDER: "none",
  CHART_NEGATIVE: "var(--color-chart-candle-negative)",
  CHART_POSITIVE: "var(--color-chart-candle-positive)",
  COST_CREDIT: "var(--color-text-general-positive-tick)",
  COST_DEBIT: "var(--color-text-general-negative-tick)",
  CRYPTOCURRENCY_HISTORY_PENDING_TEXT: "var(--color-text-alerts-pending)",
  CRYPTOCURRENCY_HISTORY_REJECTED_TEXT:
    "var(--color-text-general-inactive-text)",
  CRYPTOCURRENCY_HISTORY_STATUS_OTHER_TEXT:
    "var(--color-text-general-default-text)",
  DROPDOWN_BACKGROUND: "var(--color-background-input-field-dropdown-primary)",
  DROPDOWN_EVEN_BACKGROUND:
    "var(--color-background-input-field-dropdown-primary)",
  DROPDOWN_ODD_BACKGROUND:
    "var(--color-background-input-field-dropdown-secondary)",
  EXPAND_ACTIVE_COLOR: "var(--color-icon-expand-arrow-active)",
  HYPERLINK: "var(--color-text-general-hyperlink)",
  INPUTS_BACKGROUND: "var(--color-background-input-field-mutator-controls)",
  MODAL_BACKGROUND: "var(--color-background-general-secondary-surface)",
  MODAL_BACKGROUND_HEADER: "var(--color-background-general-secondary-surface)",
  MODAL_BORDER: "1px solid var(--color-border-general-default)",
  NAVIGATION_ACTIVE_TAB:
    "2px solid var(--color-border-header-tab-underline-active)",
  REMOVE_WALLET_BUTTON_BACKGROUND: "var(--color-background-button-cancel)",
  TABLE_EXPAND_BUTTON_TEXT: "var(--color-text-general-hyperlink)",
  TABLE_HEADER_BACKGROUND: "var(--color-background-general-secondary-surface)",
  TRADE_BACKGROUND: "var(--color-background-general-primary-surface)",
  TRADE_BORDER: "none",
  TRADE_HYPERLINK: "inherit",
})

export function getColorScheme(isCrypto): ColorScheme {
  return isCrypto ? CRYPTO_COLOR_SCHEME : DEFAULT_COLOR_SCHEME
}
