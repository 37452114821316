import {
  EQUIFAX_RESULT_DESER,
  EquifaxResult,
  KBA_INITIALIZATION_DESER,
  KBA_SUBMISSION_DESER,
  KbaInitiation,
  KbaSubmission,
  VALIDATION_SERVICE_TYPE
} from '../../tastyworks/equifax'
import type HttpClient from '../../tastyworks/http'
import {
  BaseResponse,
  parseErrorContainer,
  SingleResponse,
  updateSingleResponse
} from '../../tastyworks/response'
import { JsonBuilder } from '../../tastyworks/util/json'

export class EquifaxService {
  private readonly kycClient: HttpClient
  private readonly kbaClient: HttpClient

  constructor(customerMeClient: HttpClient) {
    this.kycClient = customerMeClient.nested('kyc')
    this.kbaClient = this.kycClient.nested('kba')
  }

  async getLastResult(): Promise<SingleResponse<EquifaxResult>> {
    return this.kycClient.show(new EquifaxResult(), EQUIFAX_RESULT_DESER.update)
  }

  async submitComparison(): Promise<BaseResponse> {
    const interactionType = {
      'vendor-names': [VALIDATION_SERVICE_TYPE.COMPARE]
    }

    const jsonHelper = await this.kycClient.postJson('', interactionType)
    const response = new BaseResponse()
    parseErrorContainer(jsonHelper, response)

    return response
  }

  /**
   * initiate knowledge based authentication.
   * This will return a set of questionnaire designed to verify the customer's identity
   */
  async submitVerification(): Promise<SingleResponse<KbaInitiation>> {
    const result = await this.kbaClient.postJson('initiate', {})

    const equifaxResponse = updateSingleResponse(
      result,
      new KbaInitiation(),
      KBA_INITIALIZATION_DESER.update
    )

    return equifaxResponse
  }

  async submitQuiz(payload: KbaSubmission): Promise<BaseResponse> {
    const jsonBuilder = new JsonBuilder()

    KBA_SUBMISSION_DESER.serialize(payload, jsonBuilder)

    const jsonHelper = await this.kbaClient.postJson('submit', jsonBuilder.json)

    const response = new BaseResponse()
    parseErrorContainer(jsonHelper, response)

    return response
  }
}
