import { BaseResponse, parseErrorContainer } from '../../tastyworks'
import type HttpClient from '../../tastyworks/http'

export class InvitationService {
  constructor(private readonly httpClient: HttpClient) {}

  async acceptWithToken(
    userTeamId: string,
    invitationToken: string,
    password: string,
    passwordConfirmation: string
  ): Promise<BaseResponse> {
    const endpoint = `/user-teams/${userTeamId}/invitations/${invitationToken}`
    const params = {
      password,
      'password-confirmation': passwordConfirmation
    }

    const helper = await this.httpClient.patch(endpoint, params)

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
