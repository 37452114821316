import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export class W8Ben extends RestResource {
  recertificationRequired = false
}

export const W_8BEN_DESER: SchemaDeSer<W8Ben> = new SchemaDeSerBuilder(W8Ben)
  .ofBoolean('recertificationRequired')
  .toDeSer()
