<script context="module">
  import goog from "/@lib/boulangerie"
  const UnderlyingQuote_Property = goog.module.get(
    "com.dough.trade.model.UnderlyingQuote.Property"
  )

  function switchQuote(quote, security) {
    if (quote) {
      quoteService.unsubscribeQuote(quote)
    }

    if (security) {
      return quoteService.getQuote(security)
    }

    return null
  }
</script>

<script>
  import { _ } from "svelte-i18n"
  import { quoteService } from "/@lib/boulangerie"
  import { nullableBouleObservableStore } from "/@/boule"
  import { tippy } from "svelte-tippy"
  import { onDestroy } from "svelte"

  export let security = undefined
  let inputQuote = undefined
  export { inputQuote as quote }
  export let inset = "0 auto auto 0"

  $: securityDerivedQuote = switchQuote(securityDerivedQuote, security)
  $: quote = inputQuote ?? securityDerivedQuote
  $: isHalted = nullableBouleObservableStore(
    quote,
    UnderlyingQuote_Property.tradingHalted,
    quote?.tradingHalted ?? false
  )

  onDestroy(() => {
    switchQuote(securityDerivedQuote, null)
  })

  let cssClass = ""
  export { cssClass as class }
</script>

{#if $isHalted}
  <div
    class="
      halted-badge
      absolute
      z-10
      h-2
      rounded-small
      bg-indicator-trading-halted
      px-triple-extra-small
      leading-[1]
      text-general-inverted-text
      font-tiny-700-caps
      {cssClass}"
    style:inset
    use:tippy={{
      animation: "fade",
      content: quote?.tradingHaltedReason,
      duration: 250,
      theme: "tasty",
      trigger: "focus mouseenter",
    }}
  >
    {$_("content.trading.underlyingQuote.tradingHalted")}
  </div>
{/if}
