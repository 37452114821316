<script>import Icons from "@tastyworks/icons";
import { getUiclContext } from "../../../internal/helpers";
import { cn } from "../../../utils";
import { useActions } from "radix-svelte/internal/helpers";
import { inputFieldVariants } from ".";
let className = void 0;
export let value = void 0;
export { className as class };
export let locked = false;
export let disabled = false;
export let error = false;
export let iconClass = void 0;
export let ref = void 0;
const v2 = !!getUiclContext()?.v2;
</script>

<div class="relative">
  <input
    class={cn(inputFieldVariants({ error, v2 }), className)}
    use:useActions={$$restProps.use}
    bind:value
    bind:this={ref}
    on:blur
    on:change
    on:cancel
    on:click
    on:focus
    on:focusin
    on:focusout
    on:keydown
    on:keypress
    on:keyup
    on:mouseover
    on:mouseenter
    on:mouseleave
    on:mousedown
    on:mouseup
    on:paste
    on:input
    disabled={locked || disabled}
    aria-invalid={error}
    data-value={value ?? ""}
    {...$$restProps}
  />
  <div
    class="pointer-events-none absolute inset-0.5 left-auto mr-3.5 flex items-center"
  >
    <slot />
    {#if locked}
      <Icons.lock
        class={cn(
          "text-general-secondary-label icon-double-extra-large",
          iconClass
        )}
      />
    {/if}
  </div>
</div>

<style>
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      var(
        --autofill-bg-color,
        var(--color-background-input-field-dropdown-primary)
      )
      inset !important;
    -webkit-text-fill-color: var(
      --autofill-text-color,
      var(--color-text-general-primary-text)
    ) !important;
    -webkit-background-clip: text !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:autofill {
    -webkit-box-shadow: 0 0 0 30px
      var(
        --autofill-bg-color,
        var(--color-background-input-field-dropdown-primary)
      )
      inset !important;
    -webkit-text-fill-color: var(
      --autofill-text-color,
      var(--color-text-general-primary-text)
    ) !important;
    -webkit-background-clip: text !important;
  }

  /* hide password reveal in favor of our own */
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
