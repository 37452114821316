<script context="module">import { collectionContext, uniqueContext } from "../../../internal/helpers";
const { setContext, getContext } = uniqueContext();
export const getRadioGroupRootContext = getContext;
const itemCollection = collectionContext();
export const getRadioGroupItemCollection = itemCollection.getContext;
</script>

<script>import { cn } from "../../../utils";
import { RadioGroup as RadioGroupPrimitive } from "radix-svelte";
import { writable } from "svelte/store";
let className = void 0;
export { className as class };
export let value = void 0;
const ctx = setContext(writable());
itemCollection.setContext();
$: $ctx = { selected: value };
</script>

<RadioGroupPrimitive.Root
  bind:value
  class={cn("grid gap-2", className)}
  tabindex={-1}
  {...$$restProps}
>
  <slot />
</RadioGroupPrimitive.Root>
