import _ from 'lodash'
import type { RestResource } from './common'
import type { DeSer, Parser, Serializer, Updater } from './deser'
import type Logger from './logger'
import type { FileParams, Params } from './request'
import { toFormData, UrlPathBuilder } from './request'
import type { ItemsResponse, SingleResponse } from './response'
import {
  BaseResponse,
  parseErrorContainer,
  parseItemsResponse,
  updateItemsResponse,
  updateSingleResponse
} from './response'
import type TwSession from './session'
import type { SessionErrorHandler } from './session-error-handler'
import { TwoFactorType } from './user'
import type { ArrayMap } from './util/collection'
import type { JsonKeyExtractor, JsonMap } from './util/json'
import { JsonBuilder, JsonHelper } from './util/json'

export const JSON_MIME_TYPE = 'application/json'
export const MULTIPART_FORM_MIME_TYPE = 'multipart/form-data'
export const CONTENT_TYPE = 'Content-Type'
export const AUTHORIZATION = 'Authorization'
export const OTP_HEADER = 'x-tastyworks-otp'
const HTTP_STATUS_NO_CONTENT = 204
const HTTP_INVALID_SESSION = 401
const HTTP_INTERNAL_SERVER_ERROR = 500

export type HttpMethod = 'DELETE' | 'GET' | 'PATCH' | 'POST' | 'PUT'

export const INVALID_SESSION_MESSAGE = 'Invalid Session'
export const INTERNAL_SERVER_ERROR_MESSAGE = 'Internal Server Error'
export interface FetchOption {
  body?: FormData | string
}

export class InvalidSessionError extends Error {
  constructor(
    readonly method: HttpMethod,
    readonly url: string,
    readonly response: Response
  ) {
    super(INVALID_SESSION_MESSAGE)

    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvalidSessionError.prototype)
  }
}

export class InternalServerError extends Error {
  constructor(
    readonly method: HttpMethod,
    readonly url: string,
    readonly response: Response
  ) {
    super(INTERNAL_SERVER_ERROR_MESSAGE)

    // NOTE [DK]:
    // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InternalServerError.prototype)
  }
}

export function isOTPResponse(response: Response): boolean {
  const header = response.headers.get(OTP_HEADER)
  return !_.isNil(header) && _.startsWith(header, 'required')
}

export function isOTPError(error: Error): boolean {
  return error instanceof InvalidSessionError && isOTPResponse(error.response)
}

export function twoFactorType(response: Response): TwoFactorType | null {
  const otpHeader = response.headers.get(OTP_HEADER)
  if (_.isNil(otpHeader)) {
    return null
  }

  const twoFactorMethod = _.last(otpHeader.split(';'))
  if (_.isNil(twoFactorMethod)) {
    return null
  }

  switch (twoFactorMethod.trim()) {
    case TwoFactorType.AUTHENTICATOR:
      return TwoFactorType.AUTHENTICATOR
    case TwoFactorType.SMS:
      return TwoFactorType.SMS
    default:
      return null
  }
}

export async function toJsonHelper(response: Response) {
  if (response.status === HTTP_STATUS_NO_CONTENT) {
    // 204 responses don't have a JSON body so passing through an empty object
    return new JsonHelper({})
  }

  const contentType = response.headers.get(CONTENT_TYPE)
  if (contentType === null || !contentType.startsWith(JSON_MIME_TYPE)) {
    return new JsonHelper({})
  }

  const json = (await response.json()) as JsonMap
  return new JsonHelper(json)
}

type HttpAction = (
  path: string,
  body: any,
  params?: Params
) => Promise<JsonHelper>

const actionOnSingleResource = async <T extends RestResource>(
  httpAction: HttpAction,
  target: T,
  deSer: DeSer<T>
): Promise<SingleResponse<T>> => {
  const builder = new JsonBuilder()

  deSer.serialize(target, builder)

  const resourceParams = {
    id: target.id
  }

  const updater = (_target: T, helper: JsonHelper) => {
    deSer.update(_target, helper)
  }

  return httpAction(':id', builder.json, resourceParams).then(helper =>
    updateSingleResponse(helper, target, updater)
  )
}

export default class HttpClient {
  public readonly baseUrl: UrlPathBuilder
  public readonly session: TwSession
  public readonly defaultParams: object

  public constructor(
    private readonly logger: Logger,
    readonly fetcher: typeof fetch,
    $baseUrl: string,
    $twSession: TwSession,
    readonly sessionErrorHandler: SessionErrorHandler,
    $urlPathBuilder?: UrlPathBuilder,
    defaultParams = {}
  ) {
    this.defaultParams = defaultParams
    this.baseUrl = $urlPathBuilder
      ? $urlPathBuilder.nested($baseUrl, this.defaultParams)
      : new UrlPathBuilder($baseUrl, '', this.defaultParams)
    this.session = $twSession
  }

  nested = (path: string, defaultParams = {}): HttpClient =>
    new HttpClient(
      this.logger,
      this.fetcher,
      path,
      this.session,
      this.sessionErrorHandler,
      this.baseUrl,
      _.merge(defaultParams, this.defaultParams)
    )

  private readonly fetchWithErrorHandler = async (
    url: string,
    request: RequestInit
  ) => {
    try {
      /* cors is the default mode for browsers,
      but since svelte-kit may run in a node environment,
      we need to set it explicitly in order to support that framework */
      request.mode = 'cors'
      return await this.fetcher(url, request)
    } catch (error) {
      this.sessionErrorHandler.onHttpNetworkError(url, request, error)
      throw error
    }
  }

  fetch = async (
    url: string,
    method: HttpMethod,
    body?: FormData | string,
    headers: HeadersInit = { Accept: JSON_MIME_TYPE }
  ): Promise<JsonHelper> => {
    let requestHeaders: HeadersInit = {}
    const { sessionToken } = this.session
    if (!_.isEmpty(sessionToken)) {
      requestHeaders[AUTHORIZATION] = sessionToken
    }
    const castleHeaders = await this.session.getCastleHeaders()
    if (!_.isEmpty(castleHeaders)) {
      requestHeaders = { ...requestHeaders, ...castleHeaders }
    }
    if (this.session.oneTimePassword.length > 0) {
      requestHeaders[OTP_HEADER] = this.session.oneTimePassword
    }

    if (
      typeof body !== 'string' &&
      !_.isNil(body) &&
      // @ts-ignore
      [...body.values()].some(v => v instanceof File)
    ) {
      // Contains at least one file; ensure content-type header is
      // unset so user-agent sets it with boundary.
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete requestHeaders[CONTENT_TYPE]
    } else {
      // Defaults to application/json without files. Can be overriden by `headers` passed in
      requestHeaders[CONTENT_TYPE] = JSON_MIME_TYPE
    }

    Object.assign(requestHeaders, headers)

    const request: RequestInit = {
      body,
      // eslint-disable-next-line prefer-object-spread
      headers: requestHeaders,
      method
    }

    // this.logger.info('Starting request for ', method, url)
    const response = await this.fetchWithErrorHandler(url, request)

    //this.logger.info('Completed ', response.statusText, ' request for ', method, url)

    if (response.status === HTTP_INVALID_SESSION) {
      if (isOTPResponse(response)) {
        this.sessionErrorHandler.onOTPRequired(response)
      } else {
        this.sessionErrorHandler.onInvalidSession()
      }
      throw new InvalidSessionError(method, url, response)
    } else if (response.status >= HTTP_INTERNAL_SERVER_ERROR) {
      throw new InternalServerError(method, url, response)
    }

    return toJsonHelper(response)
  }

  get = async (
    path = '',
    params?: Params,
    headers?: HeadersInit
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, params)
    return this.fetch(url, 'GET', undefined, headers)
  }

  patch = async (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    path = '',
    body: any,
    params?: Params,
    headers?: HeadersInit
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, params)
    return this.fetch(url, 'PATCH', JSON.stringify(body), headers)
  }

  // eslint-disable-next-line @typescript-eslint/default-param-last
  put = async (path = '', body: any, params?: Params): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, params)
    return this.fetch(url, 'PUT', JSON.stringify(body))
  }

  postJson = async (
    // eslint-disable-next-line @typescript-eslint/default-param-last
    path = '',
    body: any,
    pathParams?: Params,
    headers?: HeadersInit
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, pathParams)
    return this.fetch(url, 'POST', JSON.stringify(body), headers)
  }

  postForm = async (
    path: string,
    formParams: Params,
    pathParam?: Params
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, pathParam)
    return this.fetch(url, 'POST', toFormData(formParams), {
      [CONTENT_TYPE]: MULTIPART_FORM_MIME_TYPE
    })
  }

  postFiles = async (
    path: string,
    formParams: Params,
    fileParams: FileParams,
    pathParam?: Params
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, pathParam)
    const formData = toFormData(formParams)
    for (const [key, value] of Object.entries(fileParams)) {
      formData.append(key, value, value.name)
    }
    return this.fetch(url, 'POST', formData, {})
  }

  doDelete = async (
    path = '',
    pathParam?: Params,
    headers?: HeadersInit,
    body?: any
  ): Promise<JsonHelper> => {
    const url = this.baseUrl.toUrl(path, pathParam)
    return this.fetch(
      url,
      'DELETE',
      body == null ? undefined : JSON.stringify(body),
      headers
    )
  }

  index = async <K, V>(
    target: ArrayMap<K, V>,
    updater: Updater<V>,
    jsonKeyExtractor: JsonKeyExtractor<K>,
    path?: string,
    params?: Params
  ): Promise<ItemsResponse<V>> =>
    this.get(path, params).then(helper =>
      updateItemsResponse(helper, target, updater, jsonKeyExtractor)
    )

  indexSimple = async <T>(
    parser: Parser<T>,
    path?: string,
    params?: Params,
    headers?: HeadersInit
  ): Promise<ItemsResponse<T>> =>
    this.get(path, params, headers).then(helper =>
      parseItemsResponse(helper, parser)
    )

  show = async <T>(
    target: T,
    updater: Updater<T>,
    path?: string,
    params?: Params,
    headers?: HeadersInit
  ): Promise<SingleResponse<T>> =>
    this.get(path, params, headers).then(helper =>
      updateSingleResponse(helper, target, updater)
    )

  create = async <T>(
    target: T,
    serializer: Serializer<T>,
    updater: Updater<T>,
    path?: string,
    pathParams?: Params,
    headers?: HeadersInit
  ): Promise<SingleResponse<T>> => {
    const builder = new JsonBuilder()

    serializer(target, builder)

    return this.postJson(path, builder.json, pathParams, headers).then(helper =>
      updateSingleResponse(helper, target, updater)
    )
  }

  update = async <T>(
    target: T,
    serializer: Serializer<T>,
    updater: Updater<T>,
    path?: string,
    pathParams?: Params
  ): Promise<SingleResponse<T>> => {
    const builder = new JsonBuilder()

    serializer(target, builder)

    return this.patch(path, builder.json, pathParams).then(helper =>
      updateSingleResponse(helper, target, updater)
    )
  }

  updateResource = async <T extends RestResource>(
    target: T,
    deSer: DeSer<T>
  ): Promise<SingleResponse<T>> =>
    actionOnSingleResource(this.patch, target, deSer)

  save = async <T>(
    target: T,
    serializer: Serializer<T>,
    updater: Updater<T>,
    path?: string
  ): Promise<SingleResponse<T>> => {
    const builder = new JsonBuilder()

    serializer(target, builder)

    return this.put(path, builder.json).then(helper =>
      updateSingleResponse(helper, target, updater)
    )
  }

  saveResource = async <T extends RestResource>(
    target: T,
    deSer: DeSer<T>
  ): Promise<SingleResponse<T>> =>
    actionOnSingleResource(this.put, target, deSer)

  destroy = async (
    path?: string,
    pathParams?: Params,
    headers?: HeadersInit
  ): Promise<BaseResponse> => {
    const helper = await this.doDelete(path, pathParams, headers)

    const response = new BaseResponse()
    parseErrorContainer(helper, response)

    return response
  }
}
