<script context="module">import {
  Activations,
  Directions,
  Orientations
} from "../../../internal/constants";
import { reactiveContext, useActions } from "../../../internal/helpers";
const defaults = {
  value: void 0,
  activateOn: Activations.FOCUS,
  orientation: Orientations.HORIZONTAL,
  dir: Directions.LTR
};
const { getContext, setContext } = reactiveContext(defaults);
export const getTabsRootContext = getContext;
</script>

<script>export let value = defaults.dir;
export let dir = defaults.dir;
export let orientation = defaults.orientation;
export let activateOn = defaults.activateOn;
const ctx = setContext({ value: (v) => value = v });
$: ctx.update((old) => ({ ...old, value, activateOn, orientation, dir }));
</script>

<div
  {dir}
  data-orientation={orientation}
  {...$$restProps}
  use:useActions={$$restProps.use}
>
  <slot />
</div>
