<svelte:options immutable />

<script>
  import { getContext } from "svelte"
  import { VISIBLE_COLUMN_METAS } from "./BouleTable.svelte"
  import BouleTableHeaderCell from "./BouleTableHeaderCell.svelte"
  import { BouleSmallCollectionView } from "/@/boule/control"
  import { px } from "/@/util/style-dimensions.js"

  export let rowHeight
  export let cssClass = ""
  export { cssClass as class }

  let cssCellClass = ""
  export { cssCellClass as cellClass }

  const visibleColumnMetas = getContext(VISIBLE_COLUMN_METAS)
</script>

<div
  class="boule-table-row boule-table-header {cssClass} relative overflow-x-hidden bg-table-column-header"
  style={`height: ${px(rowHeight)}`}
>
  <slot name="action-button" />
  <slot>
    <BouleSmallCollectionView
      bouleArray={visibleColumnMetas}
      let:item={columnMeta}
    >
      <slot name="table-header-cell" {columnMeta}>
        <BouleTableHeaderCell {columnMeta} {rowHeight} class={cssCellClass} />
      </slot>
    </BouleSmallCollectionView>
  </slot>
</div>
