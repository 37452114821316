import { SchemaDeSerBuilder, type SchemaDeSer } from './deser'

export class OauthApplication {
  active?: boolean
  createdAt?: Date
  name = ''
  customerTwoFactorRequired = false
}

export class OauthGrant {
  authCode = ''
  accountNumbers?: string[] = []
  allowAllAccounts?: boolean = true
  active?: boolean = true
  createdAt?: Date
  externalId?: string = ''
  oauthApplication?: OauthApplication = new OauthApplication()
}

export const OAUTH_APPLICATION_DESER: SchemaDeSer<OauthApplication> =
  new SchemaDeSerBuilder<OauthApplication>(OauthApplication.bind(null))
    .ofBoolean('active')
    .ofDateTime('createdAt')
    .ofString('name')
    .ofBoolean('customerTwoFactorRequired')
    .toDeSer()

export const OAUTH_GRANT_DESER: SchemaDeSer<OauthGrant> =
  new SchemaDeSerBuilder<OauthGrant>(OauthGrant.bind(null))
    .ofString('authCode', 'auth-code')
    .ofPrimitiveArray('accountNumbers')
    .ofBoolean('allowAllAccounts')
    .ofBoolean('active')
    .ofDateTime('createdAt')
    .ofString('externalId')
    .ofNested('oauthApplication', OAUTH_APPLICATION_DESER, OauthApplication)
    .toDeSer()
