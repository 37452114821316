import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'

export const EQUIFAX_IDENTITY_PROPERTIES = [
  'firstName',
  'middleName',
  'lastName',
  'taxNumber',
  'birthDate'
]

export enum VALIDATION_SERVICE_TYPE {
  COMPARE = 'Equifax/Dit'
}

export enum EquifaxDecision {
  APPROVE = 'Approve',
  DENY = 'Deny',
  REVIEW = 'Review',
  EMPTY = ''
}

export class EquifaxResult {
  static onInitialize = (_obj: EquifaxResult) => {
    /* no-op */
  }

  constructor() {
    EquifaxResult.onInitialize(this)
  }

  decision: EquifaxDecision = EquifaxDecision.EMPTY
  idValidationServiceType: string = ''

  get isRejected() {
    return !this.isAccepted
  }

  get isAccepted() {
    return this.decision === EquifaxDecision.APPROVE
  }
}

/**
 * Knowledge Based Authentication Initiation model
 */
export class KbaInitiation {
  correlationId = ''
  kba: Kba | null = null
}

export class Kba {
  authenticationId = ''
  questionnaire: KbaQuestionnaire = new KbaQuestionnaire()
}

export class KbaQuestionnaire {
  questions: KbaQuestion[] = []
}

export class KbaQuestion {
  choices: KbaChoice[] = []
  id = 0
  text = ''
}

export class KbaChoice {
  id = 0
  text = ''
}

export class KbaSubmission {
  answers: KbaAnswer[] = []
  authenticationId = ''
  correlationId = ''

  addAnswer(questionId: number, choiceId: number) {
    const answer = new KbaAnswer()
    answer.questionId = questionId
    answer.choiceId = choiceId
    this.answers.push(answer)
  }

  static fromInitialization(init: KbaInitiation) {
    const submission = new KbaSubmission()
    if (init.kba) {
      submission.authenticationId = init.kba.authenticationId
      submission.correlationId = init.correlationId
    }
    return submission
  }
}

export class KbaAnswer {
  questionId = 0
  choiceId = 0
}

export const KBA_CHOICE_DESER: SchemaDeSer<KbaChoice> = new SchemaDeSerBuilder(
  KbaChoice
)
  .ofInt('id', { jsonField: 'choice-id' })
  .ofString('text', 'choice-text')
  .toDeSer()

export const KBA_QUESTION_DESER: SchemaDeSer<KbaQuestion> =
  new SchemaDeSerBuilder(KbaQuestion)
    .ofArray('choices', KBA_CHOICE_DESER, KbaChoice)
    .ofInt('id', { jsonField: 'question-id' })
    .ofString('text', 'question-text')
    .toDeSer()

export const KBA_QUESTIONNAIRE_DESER: SchemaDeSer<KbaQuestionnaire> =
  new SchemaDeSerBuilder(KbaQuestionnaire)
    .ofArray('questions', KBA_QUESTION_DESER, KbaQuestion)
    .toDeSer()

export const KBA_DESER: SchemaDeSer<Kba> = new SchemaDeSerBuilder(Kba)
  .ofString('authenticationId')
  .ofNested('questionnaire', KBA_QUESTIONNAIRE_DESER, KbaQuestionnaire)
  .toDeSer()

export const KBA_INITIALIZATION_DESER: SchemaDeSer<KbaInitiation> =
  new SchemaDeSerBuilder(KbaInitiation)
    .ofString('correlationId')
    .ofNested('kba', KBA_DESER, Kba)
    .toDeSer()

export const KBA_ANSWER_DESER: SchemaDeSer<KbaAnswer> = new SchemaDeSerBuilder(
  KbaAnswer
)
  .ofInt('questionId', { jsonField: 'question-id' })
  .ofInt('choiceId', { jsonField: 'answer' })
  .toDeSer()

export const KBA_SUBMISSION_DESER: SchemaDeSer<KbaSubmission> =
  new SchemaDeSerBuilder(KbaSubmission)
    .ofArray('answers', KBA_ANSWER_DESER, KbaAnswer, 'question-answers')
    .ofString('authenticationId')
    .ofString('correlationId')
    .toDeSer()

export const EQUIFAX_RESULT_DESER: SchemaDeSer<EquifaxResult> =
  new SchemaDeSerBuilder(EquifaxResult)
    .ofString('decision')
    .ofString('idValidationServiceType')
    .toDeSer()
