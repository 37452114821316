<script lang="ts">
  import { _ } from "svelte-i18n"
  import { Alert, Button } from "@tastyworks/ui-library"
</script>

<Alert.Root variant="reminder">
  <Alert.Title>Account Status</Alert.Title>
  <Alert.Description>
    <p class="mb-4">
      {$_("content.apiAccess.oauth.customerGrant.customerProfileRequired.body")}
    </p>
    <Button href={import.meta.env.VITE_MANAGE_URL}>
      {$_(
        "content.apiAccess.oauth.customerGrant.customerProfileRequired.button"
      )}
    </Button>
  </Alert.Description>
</Alert.Root>
