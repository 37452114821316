<script context="module">import {
  generateId,
  getUiclContext,
  useActions
} from "../../../internal/helpers";
import { onMount, tick } from "svelte";
import { getSelectRootContext } from "./SelectRoot.svelte";
</script>

<script>import { cn } from "../../../utils";
import Content from "./SelectContent.svelte";
const rootCtx = getSelectRootContext();
export let label = "";
export let disabled = false;
export let value;
let className = "";
export { className as class };
let id = generateId(`${$rootCtx.idPrefix}-option`, "");
let optionRef;
let innerText;
let option = { label, value, id };
$: option.label = innerText ?? label;
$: index = $rootCtx.options.findIndex((o) => o.id === id);
$: highlighted = index === $rootCtx.highlightedIndex;
$: if (highlighted) {
  ;
  (async function() {
    await tick();
    optionRef?.scrollIntoView({ block: "nearest" });
  })();
}
const useV2Styling = !!getUiclContext()?.v2;
onMount(() => {
  $rootCtx.registerOption(option);
  return () => $rootCtx.unRegisterOption(option);
});
</script>

<li
  bind:this={optionRef}
  on:click={() => {
    $rootCtx.select(index)
    $rootCtx.closeSelectPanel()
  }}
  on:mouseenter={() => $rootCtx.highlight(index)}
  role="option"
  aria-selected={$rootCtx.value === value}
  class:pointer-events-none={disabled}
  data-highlighted={highlighted}
  data-value={value}
  {...$$restProps}
  {id}
  class={cn(
    `cursor-pointer
    p-2
    pl-3
    text-general-primary-text 
    font-small-500`,
    useV2Styling
      ? `bg-interaction-dropdown-menu
        data-[highlighted='true']:bg-interaction-item-hovered`
      : `odd:bg-input-field-dropdown-secondary
        even:bg-input-field-dropdown-primary
        data-[highlighted='true']:bg-blue-500
        data-[highlighted='true']:text-general-static-white-text`,
    className
  )}
  use:useActions={$$restProps.use}
>
  <slot name="content">
    <Content bind:innerText class="whitespace-normal">
      {#if label}
        {label}
      {:else}
        <slot />
      {/if}
    </Content>
  </slot>
  <div class="w-6">
    {#if $rootCtx.value === value}
      <slot name="indicator" />
    {/if}
  </div>
</li>
