<script>import { cn } from "../../../utils";
let className = void 0;
export let tag = "h3";
export { className as class };
</script>

<svelte:element
  this={tag}
  class={cn(
    "font-semibold leading-none tracking-1 text-general-primary-text",
    className
  )}
  {...$$restProps}
>
  <slot />
</svelte:element>
