<script lang="ts">
  let cssClass = ""
  export { cssClass as class }
  export let showLabel = true
</script>

<div
  class="
      input-field-label
      flex
      w-full
      flex-col
      items-stretch
      {cssClass}"
>
  <!-- NOTE: [KT] input contained within slot https://github.com/sveltejs/svelte/pull/5323 -->
  <!-- svelte-ignore a11y-label-has-associated-control -->
  <label
    class="
        flex
        w-full
        flex-col
        flex-wrap
        justify-between
        gap-x-small
        text-general-primary-label
        small:flex-row
        small:items-center"
  >
    {#if showLabel}
      <slot name="label" />
    {/if}
    <slot name="input" />
  </label>

  <div class="text-left">
    <slot name="error" />
  </div>
</div>
