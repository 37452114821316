import { getContext, setContext } from "svelte";
export function uniqueContext(key) {
    if (key === undefined) {
        key = Symbol();
    }
    return {
        getContext: () => getContext(key),
        setContext: (value) => setContext(key, value),
    };
}
