<script context="module" lang="ts">
  import { CustomerAuthority } from "@tastyworks/tastyworks-api"
  import { boolean, object } from "yup"

  export const AGREED_FIELD_NAME = "hasAgreed"

  const AGREED_FIELD_SCHEMA = boolean()
    .required()
    .oneOf([true], "yup.common.mixed.required")

  function showSingleAcknowledgement(authorities: CustomerAuthority[] | null) {
    return (authorities ?? []).length <= 1
  }

  export function fieldNameForAuthority(authority: CustomerAuthority) {
    // XXX: yup appears to dislike numeric-only keys. Prepend the single field-
    //      name. Also means these fields can go in the top-level rather than
    //      nesting -- ie. hasAgreed: object(fields).
    return `${AGREED_FIELD_NAME}${authority.id}`
  }

  export function ownerAccountAgreementSchema(
    authorities: CustomerAuthority[] | null
  ) {
    if (showSingleAcknowledgement(authorities)) {
      return object({ [AGREED_FIELD_NAME]: AGREED_FIELD_SCHEMA })
    }

    const fields = getAccountOwners(authorities).reduce((fields, authority) => {
      fields[fieldNameForAuthority(authority)] = AGREED_FIELD_SCHEMA
      return fields
    }, {})

    return object(fields)
  }

  function getAccountOwners(authorities: CustomerAuthority[]) {
    return authorities.filter((authority) => authority.isOwner)
  }
</script>

<script lang="ts">
  import { _ } from "svelte-i18n"
  import { EnableAdvancedTradingSubmitButton } from "/@/account-management/form/"
  import Checkbox from "/@/account-management/form/Checkbox.svelte"

  // When authorities is null, the acknolwedgement form will always show a single agreement.
  // If joint agreements are required, you will need to fetch the customer authorities and pass them in to this component
  export let authorities: CustomerAuthority[] | null = null
</script>

<div class="acknowledgement-form-group grid grid-cols-3">
  <div class="col-span-full mb-large grid gap-medium">
    <slot name="additional-acknowledgements" />
    <slot name="customer-acknowledgement">
      {#if showSingleAcknowledgement(authorities)}
        <Checkbox
          name={AGREED_FIELD_NAME}
          fieldTranslationKey="content.acknowledgementFormGroup.hasAgreed"
        />
      {:else}
        {#each getAccountOwners(authorities) as authority}
          <!-- XXX: Must provide unused fieldTranslationKey with label. -->
          <Checkbox
            name={fieldNameForAuthority(authority)}
            fieldTranslationKey=""
            label={$_("content.acknowledgementFormGroup.hasAgreedJoint", {
              values: {
                firstName: authority.customer.firstName,
                lastName: authority.customer.lastName,
              },
            })}
          />
        {/each}
      {/if}
    </slot>
  </div>

  <slot name="actions">
    <div class="col-span-3 small:col-span-1 small:col-start-3">
      <EnableAdvancedTradingSubmitButton>
        <slot name="submit-label" slot="submit-label"
          >Submit <span class="icon tw-icon-Export" /></slot
        >
      </EnableAdvancedTradingSubmitButton>
    </div>
  </slot>
</div>
