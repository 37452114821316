<svelte:options immutable />

<script context="module">
  import goog, { uiBouleRendererFactory } from "/@lib/boulangerie"
  const UiViewNumericDirectionAdapter = goog.module.get(
    "tasty.ui.boule.UiViewNumericDirectionAdapter"
  )
  export const NumericDirection = goog.module.get(
    "com.dough.trade.model.NumericDirection"
  )

  class SvelteAdapter extends UiViewNumericDirectionAdapter {
    constructor(setText, clearNumericDirection, setNumericDirection) {
      super()
      this._setText = setText
      this._clearNumericDirection = clearNumericDirection
      this._setNumericDirection = setNumericDirection
    }

    setText(text) {
      this._setText(text ?? "")
    }

    clearNumericDirection() {
      this._clearNumericDirection()
    }

    setNumericDirection(numericDirection) {
      this._setNumericDirection(numericDirection)
    }
  }
</script>

<script>
  import { onDestroy } from "svelte"
  import { cssIf } from "/@/util/style"
  import userAnalytics from "/@/util/user-analytics"

  export let source
  export let field
  export let userAnalyticsTag = undefined
  let cssClass = ""
  export { cssClass as class }
  let cssStyle = ""
  export { cssStyle as style }

  let text
  export let numericDirection = null
  export let neutralCssClass = ""

  // all bouleTableCells receive columnType
  // svelte-ignore unused-export-let
  export let columnType = undefined

  $: isNeutral = !numericDirection || NumericDirection.SAME === numericDirection

  const adapter = new SvelteAdapter(
    (nextText) => (text = nextText),
    () => (numericDirection = null),
    (nextNumericDirection) => (numericDirection = nextNumericDirection)
  )

  const renderer = uiBouleRendererFactory.createNumericDirection(adapter)

  $: renderer.source = source
  $: renderer.field = field

  onDestroy(() => renderer.tearDown())
</script>

<span
  class="
    boule-numeric-direction-text
    numeric-direction-text
    {cssClass}
    {cssIf(isNeutral, neutralCssClass)}"
  class:numeric-direction-text-up={NumericDirection.UP === numericDirection}
  class:numeric-direction-text-down={NumericDirection.DOWN === numericDirection}
  class:empty={!text}
  style={cssStyle}
  title={text}
  use:userAnalytics={userAnalyticsTag}
>
  <slot {text} {numericDirection}>
    {text}
  </slot>
</span>
