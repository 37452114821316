<script context="module">
  import goog from "/@lib/boulangerie"

  const ActiveSymbolDetailsColumnType = goog.module.get(
    "tasty.ui.boule.table.ActiveSymbolDetailsColumnType"
  )
</script>

<script>
  import { lazyContext } from "/@lib/boulangerie"
  import ColumnPreference from "/@/boule/control/ColumnPreference.svelte"

  const { symbolDetailsColumnsManager } = lazyContext
</script>

<ColumnPreference
  class="symbol-details-header"
  columnManager={symbolDetailsColumnsManager}
  columnTypes={ActiveSymbolDetailsColumnType.values()}
/>
