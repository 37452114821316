<script context="module">
  import goog from "/@lib/boulangerie"

  const TwZeroHashUtil = goog.module.get("com.dough.tw.util.TwZeroHashUtil")
</script>

<script>
  import { roundArrow } from "tippy.js"
  import { tippy } from "svelte-tippy"
  import { getColorScheme } from "/@/util/color-scheme"

  let cssClass = ""
  export { cssClass as class }

  const colorScheme = getColorScheme(true) // Crypto-only widget.

  const tippyOptions = {
    animation: "fade",
    arrow: roundArrow,
    content: TwZeroHashUtil.ZH_TOOLTIP_INFO,
    duration: 250,
  }
</script>

<div
  class="
    zero-hash-badge
    flex
    items-center
    justify-center
    rounded-small
    font-tiny-700-caps
    {cssClass}"
  style:background={colorScheme.BADGE_BACKGROUND}
  style:color={colorScheme.BADGE_COLOR}
  style:height="8px"
  style:width="20px"
  use:tippy={tippyOptions}
>
  ZH
</div>
