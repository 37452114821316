<script>import { cn } from "../../../utils";
let className = void 0;
export { className as class };
</script>

<div
  class={cn(
    "rounded-md border border-general-main-panel bg-general-primary-surface shadow",
    className
  )}
  {...$$restProps}
  on:click
>
  <slot />
</div>
