<script context="module">import { reactiveContext } from "../../../internal/helpers";
const { getContext, setContext } = reactiveContext({
  variant: null
});
export const getAlertRootContext = getContext;
</script>

<script>import Icons from "@tastyworks/icons";
import { cn } from "../../../utils";
import { createEventDispatcher } from "svelte";
import { Alert, alertVariants } from ".";
const dispatch = createEventDispatcher();
let className = void 0;
export { className as class };
export let dismissible = false;
export let variant;
export let size = "full";
let show = true;
const rootCtx = setContext({
  variant: (v) => variant = v
});
$: rootCtx.set({ variant });
function handleDismiss() {
  show = false;
  dispatch("dismiss");
}
</script>

<div
  class={cn(alertVariants({ variant, size, className }))}
  {...$$restProps}
  class:hidden={!show}
  role="alert"
>
  {#if dismissible}
    <Icons.exit
      class="mr-large mt-medium relative float-right ml-4 cursor-pointer text-general-secondary-label font-x-large-600"
      on:click={handleDismiss}
    />
  {/if}

  <div class="flex gap-3">
    <slot name="icon">
      <Alert.Icon />
    </slot>

    <div class="flex flex-col gap-0.5">
      <slot />
    </div>
  </div>
</div>
