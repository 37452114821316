<script context="module">
  import goog from "/@lib/boulangerie"
  const UiAccount = goog.module.get("tasty.ui.boule.UiAccount")
  const UiAccountDirection = goog.module.get(
    "tasty.ui.boule.UiAccountDirection"
  )
  const UiAccountPrivacyAware = goog.module.get(
    "tasty.ui.boule.UiAccountPrivacyAware"
  )
</script>

<script>
  import { slide } from "svelte/transition"
  import { BouleNumericDirectionText, BoulePrivacyAwareText } from "/@/boule"
  import PrivacyTextWrapper from "/@/trading/control/PrivacyTextWrapper.svelte"
  import { getCssPropertyValueNumeric } from "/@/util"

  export let account
  export let hidden = false

  const delay = getCssPropertyValueNumeric("--transition-left-sidebar-time")
  const duration = getCssPropertyValueNumeric(
    "--transition-trading-account-selector-item-body"
  )
</script>

<div
  class="trading-account-selector-item-body flex flex-col p-double-extra-small"
  class:hidden
  in:slide|global={{ duration, delay }}
>
  <div class="flex justify-between">
    <span
      class="net-liq-label single-line label text-general-secondary-text font-x-small-400"
    >
      Net Liq
    </span>
    <BoulePrivacyAwareText
      class="net-liq text-general-default-text font-x-small-600"
      source={account}
      field={UiAccountPrivacyAware.NET_LIQ}
    />
  </div>

  <div class="flex justify-between">
    <span
      class="cash-label single-line label text-general-secondary-text font-x-small-400"
    >
      Cash
    </span>
    <!-- Does not need to be hidden on other platforms, so not changing underlying boule privacy conditions -->
    <PrivacyTextWrapper class="cash-value-wrapper">
      <BoulePrivacyAwareText
        class="cash-value text-general-default-text font-x-small-600"
        source={account}
        field={UiAccount.CASH_VALUE}
      />
    </PrivacyTextWrapper>
  </div>

  <div class="flex justify-between">
    <span
      class="day-gain-label single-line label text-general-secondary-text font-x-small-400"
    >
      P/L Day
    </span>
    <PrivacyTextWrapper class="day-gain-wrapper">
      <BouleNumericDirectionText
        class="day-gain font-x-small-600"
        source={account}
        field={UiAccountDirection.DAY_GAIN}
      />
    </PrivacyTextWrapper>
  </div>

  <div class="flex justify-between">
    <span
      class="year-gain-label single-line label text-general-secondary-text font-x-small-400"
    >
      P/L YTD
    </span>
    <PrivacyTextWrapper class="year-gain-wrapper">
      <BouleNumericDirectionText
        class="year-gain font-x-small-600"
        source={account}
        field={UiAccountDirection.YEAR_GAIN}
      />
    </PrivacyTextWrapper>
  </div>

  <div class="flex justify-between">
    <span
      class="option-buying-power-label single-line label text-general-secondary-text font-x-small-400"
    >
      Option BP
    </span>
    <PrivacyTextWrapper class="option-buying-power-wrapper">
      <BoulePrivacyAwareText
        class="option-buying-power text-general-default-text font-x-small-600"
        source={account}
        field={UiAccount.OPTION_BUYING_POWER}
      />
    </PrivacyTextWrapper>
  </div>

  <div class="flex justify-between">
    <span
      class="stock-buying-power-label single-line label text-general-secondary-text font-x-small-400"
    >
      Stock BP
    </span>
    <PrivacyTextWrapper class="stock-buying-power-wrapper">
      <BoulePrivacyAwareText
        class="stock-buying-power text-general-default-text font-x-small-600"
        source={account}
        field={UiAccount.STOCK_BUYING_POWER}
      />
    </PrivacyTextWrapper>
  </div>
</div>
