import { appSession } from "/@lib/boulangerie"
import goog from "/@lib/boulangerie"
import { captureException } from "@sentry/browser"

const SoundEffect = goog.module.get("com.dough.ui.model.SoundEffect")

class SoundEffectsManager {
  constructor() {
    this.soundEffectsMap = new Map()
    SoundEffect.values().forEach((soundEffect) => {
      if (!SoundEffect.isNone(soundEffect)) {
        this.soundEffectsMap.set(soundEffect, this._loadAudio(soundEffect))
      }
    })
  }

  async _loadAudio({ filename }) {
    const elem = new Audio()
    const { default: fileUrl } = await import(
      `../assets/audio/${filename}.wav?url`
    )
    elem.src = fileUrl
    return new Promise((resolve) => {
      // TODO: handle rejection
      elem.addEventListener("loadeddata", () => {
        resolve(elem)
      })
    })
  }

  _isSoundEnabled(preview) {
    return preview || appSession.soundPreferences.soundEnabled
  }

  play = async (soundEffect, preview = false) => {
    // Catch potential autoplay errors.
    // https://developer.chrome.com/blog/autoplay/
    try {
      const audioPromise = this.soundEffectsMap.get(soundEffect)
      if (audioPromise && this._isSoundEnabled(preview)) {
        const audio = await audioPromise
        await audio.play()
      }
    } catch (e) {
      console.warn(e)
      captureException(e)
    }
  }

  playMarketOpen = () => {
    this.play(appSession.soundPreferences.marketOpen)
  }

  playMarketClose = () => {
    this.play(appSession.soundPreferences.marketClose)
  }

  playOrderFill = () => {
    this.play(appSession.soundPreferences.orderFill)
  }

  playQuoteAlertTriggered = () => {
    this.play(appSession.soundPreferences.quoteAlertTriggered)
  }

  playOrderRejected = () => {
    this.play(SoundEffect.error)
  }

  playChartAlertTriggered = () => {
    this.play(appSession.soundPreferences.chartAlertTriggered)
  }
}

export default new SoundEffectsManager()
