<script>import { cn } from "../../../utils";
import { createEventDispatcher } from "svelte";
import { getSelectRootContext } from "./SelectRoot.svelte";
const dispatch = createEventDispatcher();
export let value = "";
export let ref = null;
const rootCtx = getSelectRootContext();
const { inputRef } = $rootCtx;
$: if ($inputRef) ref = $inputRef;
</script>

<!-- 
  size is an implicit attribute that causes a minimum width for the input that ignores min-width.
  We set it to 1 to avoid this behavior.
-->
<input
  bind:this={$inputRef}
  bind:value
  type="text"
  size="1"
  placeholder={$rootCtx.placeholder}
  autocomplete="off"
  on:input={(event) => dispatch("input", event)}
  on:focus={(event) => dispatch("focus", event)}
  on:blur={(event) => dispatch("blur", event)}
  id="{$rootCtx.idPrefix}-input"
  {...$$restProps}
  class={cn(
    "w-full bg-transparent outline-none placeholder:text-general-secondary-label",
    $$restProps.class
  )}
/>

<style>
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      var(
        --autofill-bg-color,
        var(--color-background-input-field-dropdown-primary)
      )
      inset !important;
    -webkit-text-fill-color: var(
      --autofill-text-color,
      var(--color-text-general-primary-text)
    ) !important;
    -webkit-background-clip: text !important;
  }
input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active,
  input:autofill {
    -webkit-box-shadow: 0 0 0 30px
      var(
        --autofill-bg-color,
        var(--color-background-input-field-dropdown-primary)
      )
      inset !important;
    -webkit-text-fill-color: var(
      --autofill-text-color,
      var(--color-text-general-primary-text)
    ) !important;
    -webkit-background-clip: text !important;
  }
</style>
