<script>import Icons from "@tastyworks/icons";
import { cn } from "../../../utils";
import { getSelectRootContext } from "./SelectRoot.svelte";
let className = "";
export { className as class };
const rootCtx = getSelectRootContext();
$: iconClassNames = $rootCtx.open ? "rotate-90 text-icon-expand-arrow-active" : "text-icon-expand-arrow-inactive";
</script>

<Icons.arrowRight
  class={cn(
    "transition-color w-6 group-data-[disabled=true]:hidden",
    iconClassNames,
    className
  )}
  id="{$rootCtx.idPrefix}-indicator"
  {...$$restProps}
/>
