import type { JsonValue, TwUser } from '../../../tastyworks'
import {
  Account,
  ACCOUNT_DESER,
  Customer,
  CUSTOMER_DESER,
  ItemsResponse,
  JsonHelper,
  parseErrorContainer,
  RED_FLAG_RECORD_PARSER,
  RedFlagRecord,
  TRADING_STATUS_DESER,
  TradingStatus
} from '../../../tastyworks'
import type { AdminCustomerAccountParams } from '../../../tastyworks/admin/admin-customer-account'
import {
  AUTHORITIES_DESER_PARSER,
  Authority,
  CustomerAccountRowResult,
  USER_DESER_PARSER
} from '../../../tastyworks/admin/admin-customer-account'
import HttpClient from '../../../tastyworks/http'

export class AdminCustomerAccountService {
  private readonly adminCustomerAccountClient: HttpClient

  private readonly customersClient: HttpClient

  constructor(httpClient: HttpClient) {
    this.adminCustomerAccountClient = httpClient.nested('customers-accounts')
    this.customersClient = httpClient.nested('customers')
  }

  readonly index = async (
    params: AdminCustomerAccountParams
  ): Promise<ItemsResponse<CustomerAccountRowResult>> =>
    this.adminCustomerAccountClient
      .get('', params)
      .then(res => this.populateAdminCustomersResult(res))

  readonly populateAdminCustomersResult = (
    helper: JsonHelper
  ): ItemsResponse<CustomerAccountRowResult> => {
    const response = new ItemsResponse<CustomerAccountRowResult>()

    parseErrorContainer(helper, response)

    if (!helper.hasField('data')) {
      return response
    }

    const data = helper.getChild('data')
    response.items = AdminCustomerAccountService.parseCustomerAccountsList(data)

    return response
  }

  readonly fetchRedFlags = async (
    customerId: string | undefined
  ): Promise<ItemsResponse<RedFlagRecord>> => {
    if (!customerId) {
      return new ItemsResponse<RedFlagRecord>()
    }

    return this.customersClient
      .nested(customerId)
      .indexSimple(RED_FLAG_RECORD_PARSER, 'red-flag-records')
  }

  static parseCustomerAccountsList = (
    data: JsonHelper
  ): CustomerAccountRowResult[] => {
    const accountNumtoAccount: Map<JsonValue, Account> = new Map()

    const authIdToAuthority: Map<JsonValue, Authority> = new Map()

    const allAuthorities = data.parseArray(
      'authorities',
      AUTHORITIES_DESER_PARSER
    )

    allAuthorities.forEach(authority => {
      authIdToAuthority.set(authority.id, authority)
    })

    data.parseArray('accounts', json => {
      accountNumtoAccount.set(
        json.getString('account-number'),
        ACCOUNT_DESER.toParser()(json)
      )
    })

    const userIdToUser: Map<string, TwUser> = new Map()
    const users = data.parseArray('users', USER_DESER_PARSER)
    const userIdtoCustomer: Map<string, Customer> = new Map()
    users.forEach(user => {
      userIdToUser.set(user.id, user)
    })

    const accountNumToTS: Map<JsonValue, TradingStatus> = new Map()
    const tradingStatuses = data.parseArray(
      'trading-statuses',
      TRADING_STATUS_DESER.toParser(TradingStatus)
    )
    tradingStatuses.forEach(tradingStatus => {
      accountNumToTS.set(tradingStatus.accountNumber, tradingStatus)
    })

    const customerAccountsList: CustomerAccountRowResult[] = []

    data.parseArray('customers', json => {
      const customer: Customer = CUSTOMER_DESER.toParser(Customer)(json)
      const authIds = json.getPrimitiveArray('account-authorities')
      const authorities: Authority[] = []
      authIds.forEach(id => {
        authorities.push(authIdToAuthority.get(id) ?? new Authority())
      })
      const userId = json.getString('user-id')
      userIdtoCustomer.set(userId, customer)

      const accountNumbers = json.getPrimitiveArray('account-numbers')

      if (accountNumbers.length === 0) {
        customerAccountsList.push({
          customer: customer,
          account: undefined,
          authorities: [],
          user: userIdToUser.get(String(userId)),
          tradingStatus: new TradingStatus()
        })
      }

      accountNumbers.forEach(accountNumber => {
        customerAccountsList.push({
          customer: customer,
          account: accountNumtoAccount.get(accountNumber),
          authorities: authorities,
          user: userIdToUser.get(String(userId)),
          tradingStatus: accountNumToTS.get(accountNumber)
        })
      })
    })

    users.forEach(user => {
      if (userIdtoCustomer.get(user.id) === undefined)
        customerAccountsList.push({
          customer: new Customer(),
          account: undefined,
          authorities: [],
          user: user,
          tradingStatus: new TradingStatus()
        })
    })

    return customerAccountsList
  }
}
