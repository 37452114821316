<script context="module">
  import goog from "/@lib/boulangerie"

  const UiAccount = goog.module.get("tasty.ui.boule.UiAccount")
  const UiAccountDirection = goog.module.get(
    "tasty.ui.boule.UiAccountDirection"
  )
  const UiAccountPrivacyAware = goog.module.get(
    "tasty.ui.boule.UiAccountPrivacyAware"
  )
</script>

<script>
  import BouleNumericDirectionText from "/@/boule/control/BouleNumericDirectionText.svelte"
  import BoulePrivacyAwareText from "/@/boule/control/BoulePrivacyAwareText.svelte"
  import { bouleArrayStore } from "/@/boule/control/boule-array-store.js"
  import boulePropertyStore from "/@/boule/control/boule-property-store.js"
  import IconCheckbox from "/@/control/IconCheckbox.svelte"
  import { AutotradeBadge, PrivacyTextWrapper } from "/@/trading/control"
  import TradingAccountIcon from "/@/trading/control/account-selector/TradingAccountIcon.svelte"
  import TradingAccountIndicator from "/@/trading/control/account-selector/TradingAccountIndicator.svelte"
  import { selectedAccountsManager, tradeSession } from "/@lib/boulangerie"

  let cssClass = ""
  export { cssClass as class }

  const current = boulePropertyStore(tradeSession.currentAccountProperty())

  // Use boule-array-stores since we need updating counts.
  const all = bouleArrayStore(tradeSession.accounts)
  const included = bouleArrayStore(selectedAccountsManager.includedAccounts)
  const textClass = "grid items-center justify-end"
</script>

<div
  class="
    included-accounts-table
    grid
    gap-double-extra-small
    font-small-600
    {cssClass}"
  style:grid-template-columns="2fr repeat(3, 1fr)"
>
  <!-- Table header. -->
  <div class="heading text-general-secondary-text font-small-600">
    Showing Accounts ({$included.length}/{$all.length})
  </div>
  {#each ["Net Liq", "P/L Day", "Option BP"] as label}
    <div
      class="
        heading
        text-right
        text-general-secondary-text
        font-small-600"
    >
      {label}
    </div>
  {/each}

  <!-- Abuse gap for spacing. -->
  <div class="empty-space col-span-4" />

  <!-- Table rows. -->
  {#each $all as account}
    <IconCheckbox
      class="account icon-extra-large"
      checked={$included.includes(account)}
      disabled={$included.includes(account) && $included.length <= 1}
      on:click={() => selectedAccountsManager.toggleIncluded(account)}
    >
      <svelte:fragment let:click>
        <button
          type="button"
          class="
            account-label
            grid
            grid-flow-col
            gap-double-extra-small
            font-small-500"
          on:click={click}
        >
          <TradingAccountIcon {account}>
            <svelte:fragment slot="badge">
              <AutotradeBadge isAutotradeEnabled={account.autoTradeEnabled} />
            </svelte:fragment>
          </TradingAccountIcon>
          <BoulePrivacyAwareText
            class="display-name"
            field={UiAccountPrivacyAware.DISPLAY_NAME_ONLY}
            source={account}
          />
          <BoulePrivacyAwareText
            class="last-digits"
            field={UiAccountPrivacyAware.LAST_DIGITS_ONLY}
            source={account}
          />
          <TradingAccountIndicator active={$current === account} />
        </button>
      </svelte:fragment>
    </IconCheckbox>

    <BoulePrivacyAwareText
      class="net-liq {textClass}"
      field={UiAccountPrivacyAware.NET_LIQ}
      source={account}
    />
    <PrivacyTextWrapper class="day-gain {textClass}">
      <BouleNumericDirectionText
        class="day-gain {textClass}"
        field={UiAccountDirection.DAY_GAIN}
        source={account}
      />
    </PrivacyTextWrapper>
    <!-- XXX: Not sure why BoulePlainText won't take grid css. -->
    <PrivacyTextWrapper class="option-buying-power {textClass}">
      <BoulePrivacyAwareText
        class="option-buying-power {textClass}"
        field={UiAccount.OPTION_BUYING_POWER}
        source={account}
      />
    </PrivacyTextWrapper>

    <hr class="col-span-4 border-divider-horizontal" />
  {/each}
</div>
