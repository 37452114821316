<svelte:options immutable />

<script>
  import { createEventDispatcher } from "svelte"
  import SymbolSearch from "/@/trading/control/symbol-search/SymbolSearch.svelte"

  export let label
  export let value
  export let disable = false

  let cssClass = ""
  export { cssClass as class }

  const dispatch = createEventDispatcher()

  function change(result) {
    dispatch("change", result)
  }
</script>

<div
  class="
    symbol-search-field
    flex
    flex-row
    items-center
    gap-extra-small
    {disable && 'disabled'}
    {cssClass}"
>
  <span class="field-label text-general-primary-label">{label}</span>
  <SymbolSearch {value} on:select={({ detail }) => change(detail)} />
  <slot />
</div>
