<script context="module">import { reactiveContext } from "../../../internal/helpers";
const { getContext, setContext } = reactiveContext({
  open: false,
  variant: null,
  triggeredId: null
});
export const getDialogRootContext = getContext;
</script>

<script>import { Dialog } from "radix-svelte";
export let open = false;
export let overlay = true;
export let variant = "modal";
const rootCtx = setContext({
  open: (v) => open = v
});
$: rootCtx.set({ variant, open });
</script>

<Dialog.Root bind:modal={overlay} bind:open {...$$restProps}>
  <slot />
</Dialog.Root>
