import { cva } from "class-variance-authority";
export { default as Input } from "./Input.svelte";
export const inputFieldVariants = cva(`
  flex
  h-10
  w-full
  rounded
  bg-input-field-dropdown-primary
  px-3
  py-2
  text-general-primary-text
  ring-inset
  font-small-500
  file:mr-2
  file:border-0
  file:border-r
  file:bg-transparent
  file:text-sm
  file:font-medium
  file:text-general-primary-text
  placeholder:text-general-secondary-label
  focus:outline-none
  focus-visible:ring-2
  disabled:cursor-not-allowed
  disabled:bg-button-inactive
  disabled:text-general-secondary-label`, {
    variants: {
        error: {
            true: "outline outline-2 outline-offset-[-2px] outline-alert-error",
        },
        v2: {
            true: "placeholder:text-input-fields-input-placeholder disabled:text-icon-general-disabled disabled:placeholder:text-icon-general-disabled",
        },
    },
});
