<script lang="ts">
  import { AlertSeverity } from "./alert-severity"
  import { ExploreToursOption, VideoTourOptions } from "./toast-alert"
  import { _ } from "svelte-i18n"
  import { createEventDispatcher } from "svelte"

  export let message: string
  export let severity: AlertSeverity
  export let buttonText: string
  export let onButtonClick: () => void
  export let onDismiss: () => void
  export let tourToastOptions: ExploreToursOption | VideoTourOptions

  const dispatch = createEventDispatcher()

  function handleDismiss() {
    if (isVideoTourOptions(tourToastOptions)) {
      window.dataLayer?.push({
        event: `shepherd_${tourToastOptions.tourType}_dismiss`,
      })
    }
    onDismiss()
  }

  function handleWatchVideo() {
    if (isVideoTourOptions(tourToastOptions)) {
      window.dataLayer?.push({
        event: `shepherd_${tourToastOptions.tourType}_video`,
      })
      dispatch("watchVideo", tourToastOptions)
      window.dispatchEvent(new CustomEvent("openVideoModal"))
      onDismiss()
    }
  }

  function isVideoTourOptions(
    options: ExploreToursOption | VideoTourOptions
  ): options is VideoTourOptions {
    return options.type === "videoTour"
  }
</script>

<div class="tour-alert-message {severity} flex flex-col items-stretch p-small">
  <div class="header mb-small flex items-center justify-between">
    <span class="title text-general-primary-label">
      {#if isVideoTourOptions(tourToastOptions)}
        {$_(`content.${tourToastOptions.tourType}TradingTour.title`)}
      {:else}
        {$_("content.tourToastAlert.title")}
      {/if}
    </span>
    <button
      class="dismiss-button tw-icon-Exit text-xl"
      on:click={handleDismiss}
      aria-label="Dismiss"
    />
  </div>
  <span class="message pb-medium text-general-primary-text">{message}</span>
  <div class="flex space-x-2">
    {#if isVideoTourOptions(tourToastOptions)}
      <button
        class="button button-default h-10 w-full rounded"
        on:click={handleWatchVideo}
        >{$_("content.tourToastAlert.demoVideo")}</button
      >
    {/if}
    <button
      class="button button-continue h-10 w-full rounded"
      on:click={onButtonClick}>{buttonText}</button
    >
  </div>
</div>

<style lang="postcss">
  :global(._toastContainer:has(.tour-alert-message)) {
    --toastContainerTop: 3rem;
  }
</style>
