<script context="module" lang="ts">
  const NO_ERROR = ""
</script>

<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { SvelteComponent, createEventDispatcher, getContext } from "svelte"
  import { _, json } from "svelte-i18n"
  import type { HTMLInputAttributes } from "svelte/elements"
  import ErrorMessage from "./ErrorMessage.svelte"
  import ReadOnly from "./ReadOnly.svelte"
  import { findFieldTranslationKey, getByPath, isFieldRequired } from "./util"
  import LabelText from "/@/account-management/control/LabelText.svelte"
  import InputFieldLabel from "/@/account-management/control/input-field-label/InputFieldLabel.svelte"
  import StackedLayout from "/@/account-management/control/input-field-label/StackedLayout.svelte"
  import { FormContext } from "/@/account-management/form"
  import {
    INPUT_FIELD_HEIGHT_CLASS,
    INPUT_FIELD_WIDTH_CLASS,
  } from "/@/account-management/form/classes"
  import InputField from "/@/control/InputField.svelte"
  import { UserAnalyticsTag } from "/@/util/user-analytics"

  const {
    errors,
    form,
    isReadOnly,
    updateValidateField,
    validateField,
    validationSchema,
    touched,
  } = getContext<FormContext>(key)
  const dispatch = createEventDispatcher()

  export let name: string
  export let type: typeof SvelteComponent<any> | string = "text"
  export let fieldTranslationKey = findFieldTranslationKey(
    validationSchema,
    name
  )
  export let inputmode: HTMLInputAttributes["inputmode"] | null = null
  export let label = $_(fieldTranslationKey)
  export let autocomplete = "off"
  export let disabled = false
  let cssClass = ""
  export { cssClass as class }
  export let readOnlyClass = ""
  export let id = null
  export let variant = StackedLayout
  export let errorUserAnalyticsTag: UserAnalyticsTag = null
  export let required = isFieldRequired(validationSchema, name)
  export let hint = null

  function findPlaceholderText(key: string) {
    return $json(`${key}.placeholder`) ? $_(`${key}.placeholder`) : ""
  }

  export let placeholder = findPlaceholderText(fieldTranslationKey)
  export let showLabel = true
  export let nameTooltip = ""

  export let hideReadOnly = false // hide ugly transition states when we know field will be hidden when not editable

  let passwordField = type === "password"
  let passwordVisibility = false

  let lastValue = null
  let isActive = false

  $: value = getByPath($form, name)

  function hasFieldError(isActive, error, isTouched) {
    if (isActive) {
      return false
    }
    if (!isTouched) {
      return false
    }

    return error !== NO_ERROR
  }

  $: hasError = hasFieldError(
    isActive,
    getByPath($errors, name),
    getByPath($touched, name)
  )

  const hidden = type === "hidden"

  async function onChange(event: any) {
    const inputValue = event.target?.value ?? event.detail?.value

    await updateValidateField(name, inputValue)

    if (lastValue !== inputValue) {
      dispatch("change", { target: event.target, value: inputValue })
      lastValue = inputValue
    }
  }

  function onFocusIn() {
    isActive = true
    dispatch("didFocusIn")
  }

  function onFocusOut() {
    validateField(name)
    isActive = false
  }
</script>

<div
  class="
    form-field
    {name}
    {cssClass}"
  class:hidden
  on:focusin={onFocusIn}
  on:focusout={onFocusOut}
>
  <InputFieldLabel {variant} {showLabel}>
    <LabelText slot="label" {label} {required} {hint} {nameTooltip} />

    <svelte:fragment slot="input">
      {#if $isReadOnly && !hideReadOnly}
        <ReadOnly class={readOnlyClass} {name} />
      {:else if typeof type === "string"}
        <InputField
          inputClass="p-2 {INPUT_FIELD_HEIGHT_CLASS}"
          {id}
          {autocomplete}
          {name}
          {inputmode}
          {value}
          {disabled}
          {hasError}
          {placeholder}
          type={passwordField && passwordVisibility ? "text" : type}
          on:input={onChange}
        >
          <slot name="prefix" slot="prefix" />

          <slot name="suffix" slot="suffix">
            <div class="input-field-suffix flex items-center">
              {#if passwordField}
                <button
                  type="button"
                  on:click|preventDefault={() =>
                    (passwordVisibility = !passwordVisibility)}
                  class="password-visibility button bg-transparent p-0 px-1.5 shadow-none hover:bg-transparent active:bg-transparent"
                >
                  <i
                    class="icon text-icon-general-active-state
          font-x-large-600"
                    class:tw-icon-Visibility-On={passwordVisibility}
                    class:tw-icon-Visibility-Off={!passwordVisibility}
                  />
                </button>
              {/if}
            </div>
          </slot>
        </InputField>
      {:else}
        <svelte:component
          this={type}
          class="
            rounded
            border-0
            bg-input-field-mutator-controls
            text-input-fields-input-active
            {INPUT_FIELD_WIDTH_CLASS}
            {INPUT_FIELD_HEIGHT_CLASS}
            p-2"
          {id}
          {name}
          {value}
          {disabled}
          {hasError}
          {placeholder}
          {...$$restProps}
          on:input={onChange}
        />
      {/if}
    </svelte:fragment>

    <svelte:fragment slot="error">
      <ErrorMessage
        hidden={!hasError}
        {name}
        userAnalyticsTag={errorUserAnalyticsTag}
      />
    </svelte:fragment>
  </InputFieldLabel>
</div>
