import { Directions, Orientations, } from "../constants";
// Ref: https://www.w3.org/TR/uievents-key/#named-key-attribute-values
export var Keys;
(function (Keys) {
    Keys["Space"] = " ";
    Keys["Enter"] = "Enter";
    Keys["Escape"] = "Escape";
    Keys["Backspace"] = "Backspace";
    Keys["Delete"] = "Delete";
    Keys["ArrowLeft"] = "ArrowLeft";
    Keys["ArrowUp"] = "ArrowUp";
    Keys["ArrowRight"] = "ArrowRight";
    Keys["ArrowDown"] = "ArrowDown";
    Keys["Home"] = "Home";
    Keys["End"] = "End";
    Keys["PageUp"] = "PageUp";
    Keys["PageDown"] = "PageDown";
    Keys["Tab"] = "Tab";
})(Keys || (Keys = {}));
const SCROLL_KEYS = [
    Keys.ArrowUp,
    Keys.ArrowDown,
    Keys.ArrowLeft,
    Keys.ArrowRight,
    Keys.Home,
    Keys.End,
    Keys.PageUp,
    Keys.PageDown,
];
export function isScrollKey(key) {
    return SCROLL_KEYS.includes(key);
}
export function nextKey(orientation = Orientations.HORIZONTAL, dir = Directions.LTR) {
    return orientation === Orientations.VERTICAL
        ? Keys.ArrowDown
        : dir === Directions.RTL
            ? Keys.ArrowLeft
            : Keys.ArrowRight;
}
export function prevKey(orientation = Orientations.HORIZONTAL, dir = Directions.LTR) {
    return orientation === Orientations.VERTICAL
        ? Keys.ArrowUp
        : dir === Directions.RTL
            ? Keys.ArrowRight
            : Keys.ArrowLeft;
}
