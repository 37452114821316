import {
  CHECK_DELIVERY_METHODS,
  IraDetail,
  TRANSFER_METHODS,
  WITHHOLDING_TYPES,
  Withdrawal,
  toStringValues,
  IRA_DISTRIBUTION_REASONS,
} from "@tastyworks/tastyworks-api"
import { WithdrawalTransaction } from "./withdrawal-transaction"

export enum CHECK_WITHDRAWAL_FREQUENCIES {
  ONE_TIME = "One Time",
}

export const CHECK_WITHDRAWAL_FREQUENCY_VALUES = toStringValues(
  CHECK_WITHDRAWAL_FREQUENCIES
)

export class CheckWithdrawal implements WithdrawalTransaction {
  amount: number = null
  deliveryMethod: CHECK_DELIVERY_METHODS = CHECK_DELIVERY_METHODS.STANDARD
  federalWithholding: number | null = null
  federalWithholdingEnabled = false
  federalWithholdingType: WITHHOLDING_TYPES | null = null
  frequency = CHECK_WITHDRAWAL_FREQUENCIES.ONE_TIME
  isAnyIra = false
  hasConfirmedReviewed = false
  hasViewedIraDistributionRequest = false
  reason: IRA_DISTRIBUTION_REASONS | null = null
  stateWithholding: number | null = null
  stateWithholdingEnabled = false
  stateWithholdingType: WITHHOLDING_TYPES | null = null

  toWithdrawal(accountNumber: string) {
    const withdrawal = new Withdrawal()
    withdrawal.accountNumber = accountNumber
    withdrawal.amount = this.amount
    withdrawal.transferMethod = TRANSFER_METHODS.CHECK
    withdrawal.deliveryMethod = this.deliveryMethod
    withdrawal.achRelationshipId = undefined

    if (this.isAnyIra) {
      const iraDetail = new IraDetail()
      iraDetail.distributionReason = this.reason

      if (this.federalWithholdingEnabled) {
        iraDetail.federalWithholding = this.federalWithholding
        iraDetail.federalWithholdingType = this.federalWithholdingType
      }

      if (this.stateWithholdingEnabled) {
        iraDetail.stateWithholding = this.stateWithholding
        iraDetail.stateWithholdingType = this.stateWithholdingType
      }

      withdrawal.iraDetail = iraDetail
    }

    return withdrawal
  }

  get isDryRunnable() {
    return this.amount !== null
  }
}
