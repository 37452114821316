import {
  Customer,
  CUSTOMER_DESER,
  ItemsResponse,
  Order,
  ORDER_DESER,
  PER_PAGE,
  SingleResponse
} from '../../tastyworks'
import {
  customerIdParam,
  REQUIRED_DOCUMENT_DESER,
  RequiredDocument,
  SUPPORTING_DOCUMENT_DESER,
  SupportingDocument
} from '../../tastyworks/customer'
import type HttpClient from '../../tastyworks/http'

export class CustomerService {
  constructor(
    private readonly baseHttpClient: HttpClient,
    private readonly customerMeClient: HttpClient
  ) {}

  readonly create = async (
    customer: Customer
  ): Promise<SingleResponse<Customer>> =>
    this.baseHttpClient.create(
      customer,
      CUSTOMER_DESER.serialize,
      CUSTOMER_DESER.update,
      'customers'
    )

  readonly show = async (
    customerId?: number
  ): Promise<SingleResponse<Customer>> =>
    this.customerMeClient.show(new Customer(), CUSTOMER_DESER.update, '', {
      customerId
    })

  readonly update = async (
    customer: Customer
  ): Promise<SingleResponse<Customer>> =>
    this.customerMeClient.update(
      customer,
      CUSTOMER_DESER.serialize,
      CUSTOMER_DESER.update
    )

  readonly getRequiredIdentityDocuments = async (): Promise<
    ItemsResponse<RequiredDocument>
  > =>
    this.customerMeClient.indexSimple(
      REQUIRED_DOCUMENT_DESER.toParser(RequiredDocument),
      '/required-identity-documents',
      customerIdParam(this.customerMeClient.session)
    )

  readonly getSupportingDocuments = async (): Promise<
    ItemsResponse<SupportingDocument>
  > =>
    this.customerMeClient.indexSimple(
      SUPPORTING_DOCUMENT_DESER.toParser(SupportingDocument),
      '/supporting-documents',
      customerIdParam(this.customerMeClient.session)
    )

  /**
   * gets orders across all accounts
   * @returns
   */
  readonly getFilledOrders = async (
    accountNumbers: string[],
    perPage = PER_PAGE
  ): Promise<ItemsResponse<Order>> => {
    const params = {
      'account-numbers': accountNumbers,
      status: 'Filled',
      'per-page': perPage
    }
    return this.customerMeClient.indexSimple(
      ORDER_DESER.toParser(Order),
      `/orders`,
      params
    )
  }
}
