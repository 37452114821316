import { DateHelper } from "@tastyworks/tastyworks-api"
import {
  TaxForm,
  TaxFormAvailability,
  TaxCalendarEvent,
} from "/@/account-management/model/tax-calendar-event"

/**
 * Current tax year currently needs to be updated manually every year
 */
export const CURRENT_TAX_YEAR = 2024

/**
 * Current calendar year currently needs to be updated manually every year
 */
export const CURRENT_CALENDAR_YEAR = 2025
/**
 * Upcoming tax events for the current tax year. This currently needs to be updated manually every tax year with the new dates.
 * NOTE: events should be added in chronological order and months are indexed starting at "0"
 */
export const taxCalendarEvents: TaxCalendarEvent[] = [
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "Any futures transactions in a non-qualified account.",
    new Date(2025, 0, 31),
    new TaxForm("1099-B Futures")
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "Distributions from qualified retirement plans",
    new Date(2025, 1, 8),
    new TaxForm("1099-R")
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "A consolidated tax document containing forms 1099-B, 1099-DIV, 1099-INT and 1099-MISC",
    new Date(2025, 1, 7),
    new TaxForm(
      "Consolidated 1099 1st Run",
      "Available online between 2/7/25 through 2/14/25.",
      "Please note: If you don't see your 1099 Consolidated Documents at this time, they will be available in the second run starting on February 24, 2025."
    )
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "Any cryptocurrency transactions in a non-qualified account for US residents.",
    new Date(2025, 1, 14),
    new TaxForm("1099-B Cryptocurrencies")
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "A consolidated tax document containing forms 1099-B, 1099-DIV, 1099-INT and 1099-MISC",
    new Date(2025, 1, 24),
    new TaxForm(
      "Consolidated 1099 2nd Run",
      "Available online between 2/24/25 through 2/28/25."
    )
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.MAILED,
    "Payments to residents of Puerto Rico—such as dividends, interest, partnership distributions, long-term gains, liquidations, and gross proceeds—that did not have Puerto Rico tax withheld. These payments can be from a Puerto Rico or non-Puerto Rico source.",
    new Date(2025, 2, 7),
    new TaxForm(
      "PR 480.6a",
      "Informative Return–Income Not Subject to Withholding (Puerto Rico residents only)"
    )
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.MAILED,
    'Puerto Rico source income (generally, dividends and interest); this is subject to Puerto Rico "at source" withholding (also known as foreign tax)',
    new Date(2025, 2, 7),
    new TaxForm(
      "PR 480.6b",
      "Informative Return–Income Subject to Withholding (Puerto Rico residents only)"
    )
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "Foreign Person’s U.S. Source Income Subject to Withholding",
    new Date(2025, 2, 21),
    new TaxForm("1042-S")
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.MAILED,
    "Undistributed capital gains from mutual funds and Real Estate Investment Trusts (REITs)",
    new Date(2025, 3, 8),
    new TaxForm(
      "2439",
      "Notice to Shareholders of Undistributed Long-Term Capital Gains"
    )
  ),
  new TaxCalendarEvent(
    TaxFormAvailability.ONLINE,
    "Contributions to qualified retirement plans",
    new Date(2025, 5, 6),
    new TaxForm("5498")
  ),
]

export const getUpcomingDatesToKnow = (events: TaxCalendarEvent[]) => {
  const currentDate = new DateHelper().clearTime().toDate()
  const nextEventIndex = events.findIndex((event) => event.date >= currentDate)

  return events.slice(nextEventIndex, nextEventIndex + 3)
}

export enum TaxSoftwareIntegrations {
  TURBO_TAX = "turboTax",
  HR_BLOCK = "hrBlock",
  TAX_ACT = "taxAct",
  TRADE_LOG = "tradeLog",
}

export enum TaxFormGuides {
  CONSOLIDATED_1099_GUIDE = "consolidated1099Guide",
  IRA_TAX_FORM_GUIDE = "iraTaxFormGuide",
  INTERNATIONAL_ACCOUNTS_TAXES_GUIDE = "internationalAccountsTaxesGuide",
}
