import _ from 'lodash'
import { RestResource } from './common'
import type { SchemaDeSer } from './deser'
import { SchemaDeSerBuilder } from './deser'
import { IRA_DETAIL_DESER, IraDetail } from './ira-detail'
import { ArrayMap } from './util/collection'
import { Cost, CostEffect } from './util/cost'
import toStringValues from './util/enum'

export enum ACH_RELATIONSHIP_STATUSES {
  PRE_SEND = 'PRE_SEND',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  CANCELED = 'CANCELED',
  IDENTITY_FAIL = 'IDENTITY_FAIL',
  PREVIOUS_ACH_RETURNED_R10 = 'PREVIOUS_ACH_RETURNED_R10',
  NEEDS_BROKER_REVIEW = 'NEEDS_BROKER_REVIEW'
}

export enum RELATIONSHIP_METHODS {
  MICRO_DEPOSIT = 'MICRO_DEPOSIT',
  PLAID = 'PLAID'
}

export enum BANK_ACCOUNT_TYPES {
  CHECKING = 'CHECKING',
  SAVINGS = 'SAVINGS'
}

export class WithdrawalLimit {
  permittedWithdrawalAmount = 0
  activeWithdrawalHoldAmount = 0
}

export const WITHDRAWAL_LIMIT_DESER: SchemaDeSer<WithdrawalLimit> =
  new SchemaDeSerBuilder(WithdrawalLimit)
    .ofFloat('permittedWithdrawalAmount')
    .ofFloat('activeWithdrawalHoldAmount')
    .toDeSer()

export enum INSTITUTION_STATUSES {
  CONNECTED = 'Connected',
  REAUTH_REQUIRED = 'Reauth Required',
  CANCELED = 'Canceled'
}

export class ExternalInstitution extends RestResource {
  static onInitialize = (_obj: ExternalInstitution) => {
    /* no-op */
  }

  constructor() {
    super()

    ExternalInstitution.onInitialize(this)
  }

  accountNumber = ''
  institutionType = ''
  achRelationshipId = 0
  status: INSTITUTION_STATUSES | null = null

  get isReauthRequired(): boolean {
    return this.status === INSTITUTION_STATUSES.REAUTH_REQUIRED
  }
}

export const EXTERNAL_INSTITUTION_DESER: SchemaDeSer<ExternalInstitution> =
  new SchemaDeSerBuilder(ExternalInstitution)
    .ofString('id')
    .ofStrings('accountNumber', 'institutionType', 'status')
    .ofInt('achRelationshipId')
    .toDeSer()

export class AchRelationship extends RestResource {
  static onInitialize = (_obj: AchRelationship) => {
    /* no-op */
  }

  constructor() {
    super()

    AchRelationship.onInitialize(this)
  }

  accountNumber = ''
  approvalMethod = ''
  achRelationshipMethod = ''
  extRelationshipId = ''
  achRelationshipStatus = ''
  cancellationComment = ''
  externalAccountId = ''
  externalAccountType = ''
  externalAccountNumber = ''
  institutionType = ''
  nickname = ''
  publicToken = ''
  bankRoutingNumber = ''
  bankAccount = ''
  bankAccountNumberMask = ''
  bankAccountOwnerName = ''
  approvalAttemptsCount = 0
  maxApprovalAttempts = false
  bankAccountType = BANK_ACCOUNT_TYPES.CHECKING
  externalInstitution: ExternalInstitution | null = null

  get isApproved() {
    return this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.APPROVED
  }

  get isPending() {
    return this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.PENDING
  }

  get isPresend() {
    return this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.PRE_SEND
  }

  get isIdentityFail() {
    return (
      this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.IDENTITY_FAIL
    )
  }

  get isRejected() {
    return this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.REJECTED
  }

  get isCanceled() {
    return this.achRelationshipStatus === ACH_RELATIONSHIP_STATUSES.CANCELED
  }

  get isPreviousAchReturned() {
    return (
      this.achRelationshipStatus ===
      ACH_RELATIONSHIP_STATUSES.PREVIOUS_ACH_RETURNED_R10
    )
  }

  get hasCompletedRelationship() {
    return this.isCreated && !this.isPresend
  }
}

export const NO_ACH_RELATIONSHIP = new AchRelationship()

export const ACH_RELATIONSHIP_DESER: SchemaDeSer<AchRelationship> =
  new SchemaDeSerBuilder(AchRelationship)
    .ofString('accountNumber')
    .ofString('achRelationshipStatus')
    .ofString('approvalMethod')
    .ofString('achRelationshipMethod')
    .ofString('cancellationComment')
    .ofString('externalAccountId')
    .ofString('externalAccountType')
    .ofString('externalAccountNumber')
    .ofString('id')
    .ofString('institutionType')
    .ofString('nickname')
    .ofString('publicToken')
    .ofString('bankRoutingNumber')
    .ofString('bankAccount')
    .ofString('bankAccountNumberMask')
    .ofString('bankAccountOwnerName')
    .ofString('bankAccountType')
    .ofString('extRelationshipId')
    .ofBoolean('maxApprovalAttempts')
    .ofInt('approvalAttemptsCount')
    .ofNested(
      'externalInstitution',
      EXTERNAL_INSTITUTION_DESER,
      ExternalInstitution
    )
    .toDeSer()

function createPreRegisteredDepositsMap(): ArrayMap<
  string,
  PreRegisteredDeposit
> {
  return ArrayMap.stringKey(PreRegisteredDeposit, 'id')
}

export enum PRE_REGISTERED_DEPOSIT_STATUSES {
  CANCELED = 'Canceled',
  AWAITING_ACH_RELATIONSHIP_CREATION = 'Awaiting ACH Relationship Creation',
  SUBMITTED_TO_CLEARING = 'Submitted to Clearing'
}

const PRE_REGISTERED_DEPOSIT_TERMINAL_STATES: readonly string[] = Object.freeze(
  [
    PRE_REGISTERED_DEPOSIT_STATUSES.CANCELED,
    PRE_REGISTERED_DEPOSIT_STATUSES.SUBMITTED_TO_CLEARING
  ]
)

export enum TRANSFER_DIRECTIONS {
  OUTGOING = 'OUTGOING',
  INCOMING = 'INCOMING'
}

export class PreRegisteredDeposit
  extends RestResource
  implements CashTransaction
{
  static onInitialize = (_obj: PreRegisteredDeposit) => {
    /* no-op */
  }

  constructor() {
    super()

    PreRegisteredDeposit.onInitialize(this)
  }

  status = ''
  amount = 0
  bankingPreRegistrationId: number | null = null
  externalTransactionId: number | null = null
  createdAt = new Date()
  updatedAt = new Date()

  get isCancelable() {
    return this.isPending
  }

  get isCanceled(): boolean {
    return this.status === PRE_REGISTERED_DEPOSIT_STATUSES.CANCELED
  }

  get isComplete(): boolean {
    return this.isSubmitted
  }

  get isPending(): boolean {
    return (
      this.status ===
      PRE_REGISTERED_DEPOSIT_STATUSES.AWAITING_ACH_RELATIONSHIP_CREATION
    )
  }

  get isPresend() {
    return this.isPending
  }

  get isSubmitted() {
    return this.status === PRE_REGISTERED_DEPOSIT_STATUSES.SUBMITTED_TO_CLEARING
  }

  get isTerminal(): boolean {
    return PRE_REGISTERED_DEPOSIT_TERMINAL_STATES.includes(this.status)
  }

  get direction(): TRANSFER_DIRECTIONS {
    return TRANSFER_DIRECTIONS.INCOMING
  }

  readonly isDeposit = true

  readonly isWithdrawal = false
}

export enum BANKING_PRE_REGISTRATION_ACCOUNT_TYPES {
  CHECKING = 'Checking',
  SAVINGS = 'Savings'
}

export enum BANKING_PRE_REGISTRATION_STATUSES {
  CANCELED = 'Canceled',
  AWAITING_ACCOUNT_CREATION = 'Awaiting Account Creation',
  SUBMITTED_TO_CLEARING = 'Submitted to Clearing',
  ERROR = 'Error'
}

export class BankingPreRegistration extends RestResource {
  static onInitialize = (_obj: BankingPreRegistration) => {
    /* no-op */
  }

  constructor() {
    super()

    BankingPreRegistration.onInitialize(this)
  }

  accountOpeningApplicationId: number | null = null
  achRelationshipMethod = ''
  bankAccount = ''
  bankRoutingNumber = ''
  bankAccountType = BANKING_PRE_REGISTRATION_ACCOUNT_TYPES.CHECKING
  externalAccountId = ''
  externalAccountNumber = ''
  institutionType = ''
  nickname = ''
  publicToken = ''
  status = ''
  achRelationship: AchRelationship | null = null
  preRegisteredDeposits = createPreRegisteredDepositsMap()
  reason = ''
  createdAt = new Date()
  updatedAt = new Date()

  get isCanceled() {
    return this.status === BANKING_PRE_REGISTRATION_STATUSES.CANCELED
  }

  get isAwaitingAccountCreation() {
    return (
      this.status ===
      BANKING_PRE_REGISTRATION_STATUSES.AWAITING_ACCOUNT_CREATION
    )
  }

  get isSubmitted() {
    return (
      this.status === BANKING_PRE_REGISTRATION_STATUSES.SUBMITTED_TO_CLEARING
    )
  }

  get isError() {
    return this.status === BANKING_PRE_REGISTRATION_STATUSES.ERROR
  }
}

export const NO_BANKING_PRE_REGISTRATION = new BankingPreRegistration()

export enum TRANSFER_STATES {
  /** has not yet been sent. FE-only */
  BLANK = '',
  PRE_SEND = 'PRE_SEND',
  SEND_FAILED = 'SEND_FAILED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  COMPLETE = 'COMPLETE',
  FUNDS_POSTED = 'FUNDS_POSTED',
  PENDING = 'PENDING',
  /** this is a chase only status that will be resolved once the user reauths with that bank */
  REAUTH_REQUIRED = 'REAUTH_REQUIRED',
  REJECTED = 'REJECTED',
  RETURNED = 'RETURNED' // ACHs only
}

export enum PLATFORM_NAMES {
  ANDROID = 'Android',
  IOS = 'iOS',
  WEB = 'Web'
}

export enum TRANSFER_METHODS {
  ACH = 'ACH',
  WIRE = 'WIRE',
  CHECK = 'CHECK'
}

export const TERMINAL_TRANSFER_STATES: readonly string[] = Object.freeze([
  TRANSFER_STATES.CANCEL_REQUESTED,
  TRANSFER_STATES.CANCELED,
  TRANSFER_STATES.COMPLETE,
  TRANSFER_STATES.REJECTED,
  TRANSFER_STATES.RETURNED,
  TRANSFER_STATES.SEND_FAILED
])

export const PENDING_TRANSFER_STATES: readonly string[] = Object.freeze([
  TRANSFER_STATES.FUNDS_POSTED,
  TRANSFER_STATES.PENDING,
  TRANSFER_STATES.PRE_SEND
])

export const TRANSACTION_REAUTH_REQUIRED_REASON =
  'Your login credentials at your bank have changed. Please re-authenticate with your new credentials.'

export enum IRA_CONTRIBUTION_REASONS {
  CONVERSION = 'CONVERSION',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYER = 'EMPLOYER',
  RECHARACTERIZATION = 'RECHARACTERIZATION',
  REGULAR = 'REGULAR',
  ROLLOVER_60_DAY = 'ROLLOVER_60_DAY',
  ROLLOVER_DIRECT = 'ROLLOVER_DIRECT',
  TRANSFER = 'TRANSFER'
}

export enum IRA_DISTRIBUTION_REASONS {
  CONVERSION = 'CONVERSION',
  DEATH = 'DEATH',
  EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE = 'EXCESS_CONTRIBUTION_REMOVAL_AFTER_TAX_DEADLINE',
  EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE = 'EXCESS_CONTRIBUTION_REMOVAL_BEFORE_TAX_DEADLINE',
  NORMAL = 'NORMAL',
  PREMATURE = 'PREMATURE',
  RECHARACTERIZATION_PRIOR_YEAR = 'RECHARACTERIZATION_PRIOR_YEAR',
  RECHARACTERIZATION_CURRENT_YEAR = 'RECHARACTERIZATION_CURRENT_YEAR',
  TRANSFER = 'TRANSFER'
}

export const ACH_DEPOSIT_MAXIMUM = 250_000

export const IRA_DISTRIBUTION_REASON_VALUES = toStringValues(
  IRA_DISTRIBUTION_REASONS
)

export interface CashTransaction {
  id: string
  direction: string
  amount: number
  createdAt: Date | null
  updatedAt: Date | null
  isCancelable: boolean
  isCanceled: boolean
  isComplete: boolean
  isPresend: boolean
  isTerminal: boolean
  isDeposit: boolean
  isWithdrawal: boolean
  status: string
}

export enum TransactionStatusBucket {
  PROCESSING = 'processing',
  REJECTED = 'rejected',
  CANCELED = 'canceled',
  COMPLETE = 'complete',
  RETURNED = 'returned'
}

function getStatusBucket(
  state: TRANSFER_STATES | string
): TransactionStatusBucket {
  switch (state) {
    case TRANSFER_STATES.REJECTED:
    case TRANSFER_STATES.SEND_FAILED:
    case TRANSFER_STATES.FAILED:
      return TransactionStatusBucket.REJECTED
    case TRANSFER_STATES.RETURNED:
      return TransactionStatusBucket.RETURNED
    case TRANSFER_STATES.CANCELED:
    case TRANSFER_STATES.CANCEL_REQUESTED:
      return TransactionStatusBucket.CANCELED
    case TRANSFER_STATES.COMPLETE:
      return TransactionStatusBucket.COMPLETE
    case TRANSFER_STATES.PRE_SEND:
    case TRANSFER_STATES.PENDING:
    case TRANSFER_STATES.FUNDS_POSTED:
    case TRANSFER_STATES.REAUTH_REQUIRED:
    case TRANSFER_STATES.BLANK:
    default:
      return TransactionStatusBucket.PROCESSING
  }
}

export class ExternalTransaction
  extends RestResource
  implements CashTransaction
{
  static onInitialize = (_obj: ExternalTransaction) => {
    /* no-op */
  }

  constructor() {
    super()

    ExternalTransaction.onInitialize(this)
  }

  achRelationship?: AchRelationship
  achRelationshipId = 0
  accountNumber = ''
  bankingDate?: Date
  direction = ''
  fundsAvailableDate?: Date
  iraDetail?: IraDetail
  amount = 0
  state: TRANSFER_STATES = TRANSFER_STATES.BLANK
  extTransferId = ''
  reason = ''
  transferMethod = ''
  disbursementType = ''
  createdAt: Date | null = null
  updatedAt: Date | null = null
  buyingPowerGrantedAt: Date | null = null
  isCancelable = false
  isClearingAccepted = false
  reversedAt: Date | null = null
  verifiedAt: Date | null = null

  get status() {
    return this.state
  }

  get isAch() {
    return this.transferMethod === TRANSFER_METHODS.ACH
  }

  get hasBuyingPowerGranted() {
    return this.buyingPowerGrantedAt !== null
  }

  get isCheck() {
    return this.transferMethod === TRANSFER_METHODS.CHECK
  }

  get isMicroDeposit() {
    return (
      this.achRelationship?.achRelationshipMethod ===
      RELATIONSHIP_METHODS.MICRO_DEPOSIT
    )
  }

  get isBuyingPowerGrantEligible() {
    if (this.hasBuyingPowerGranted) {
      return false
    }
    if (!this.isDeposit) {
      return false
    }
    if (!this.isClearingAccepted) {
      return false
    }

    return this.isPending || this.isFundsPosted
  }

  get statusBucket(): TransactionStatusBucket {
    return getStatusBucket(this.state)
  }

  get isComplete() {
    return this.state === TRANSFER_STATES.COMPLETE
  }

  get isWire() {
    return this.transferMethod === TRANSFER_METHODS.WIRE
  }

  get isWithdrawal() {
    return this.direction === TRANSFER_DIRECTIONS.OUTGOING
  }

  get isDeposit() {
    return this.direction === TRANSFER_DIRECTIONS.INCOMING
  }

  get isFullBalance() {
    return this.disbursementType === 'FULL_BALANCE'
  }

  get isPending() {
    return this.state === TRANSFER_STATES.PENDING
  }

  get isPresend() {
    return this.state === TRANSFER_STATES.PRE_SEND
  }

  get isSendFailed() {
    return this.state === TRANSFER_STATES.SEND_FAILED
  }

  get isSubmissionRejected() {
    return this.isRejected || this.isSendFailed
  }

  get isCanceled() {
    return this.state === TRANSFER_STATES.CANCELED
  }

  get isRejected() {
    return this.state === TRANSFER_STATES.REJECTED
  }

  get isFundsPosted() {
    return this.state === TRANSFER_STATES.FUNDS_POSTED
  }

  get isReturned() {
    return this.state === TRANSFER_STATES.RETURNED
  }

  get isVerified() {
    return !_.isNil(this.verifiedAt)
  }

  get isProcessed(): boolean {
    return this.isPending || this.isPresend || this.isFundsPosted
  }

  get isProcessingStatus() {
    return this.statusBucket === TransactionStatusBucket.PROCESSING
  }

  get isCompleteStatus() {
    return this.statusBucket === TransactionStatusBucket.COMPLETE
  }

  get isCanceledStatus() {
    return this.statusBucket === TransactionStatusBucket.CANCELED
  }

  get isRejectedStatus() {
    return this.statusBucket === TransactionStatusBucket.REJECTED
  }

  get isReturnedStatus() {
    return this.statusBucket === TransactionStatusBucket.RETURNED
  }

  get isTerminal(): boolean {
    return TERMINAL_TRANSFER_STATES.includes(this.state)
  }

  get isReauthRequired(): boolean {
    return this.reason === TRANSACTION_REAUTH_REQUIRED_REASON
  }
}

export const EXTERNAL_TRANSACTION_DESER: SchemaDeSer<ExternalTransaction> =
  new SchemaDeSerBuilder(ExternalTransaction)
    .ofNested('achRelationship', ACH_RELATIONSHIP_DESER, AchRelationship)
    .ofNested('iraDetail', IRA_DETAIL_DESER, IraDetail)
    .ofString('id')
    .ofInt('achRelationshipId')
    .ofString('accountNumber')
    .ofDate('bankingDate')
    .ofString('direction')
    .ofDate('fundsAvailableDate')
    .ofFloat('amount')
    .ofString('state')
    .ofString('extTransferId')
    .ofString('reason')
    .ofString('transferMethod')
    .ofString('disbursementType')
    .ofDateTime('createdAt')
    .ofDateTime('updatedAt')
    .ofDateTime('buyingPowerGrantedAt')
    .ofBoolean('isCancelable')
    .ofBoolean('isClearingAccepted')
    .ofDateTime('verifiedAt')
    .ofDateTime('reversedAt')
    .toDeSer()

export class AvailableBuyingPowerResponse extends RestResource {
  amount = 0
}

export const AVAILABLE_BUYING_POWER_DESER: SchemaDeSer<AvailableBuyingPowerResponse> =
  new SchemaDeSerBuilder(AvailableBuyingPowerResponse)
    .ofFloat('amount')
    .toDeSer()

export enum PendingCashEntryState {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  RELEASED = 'Released'
}

export class PendingCashEntry extends RestResource {
  accountNumber = ''
  apexCashActivityId = 0
  amount = 0
  effect = CostEffect.NoCost
  state: PendingCashEntryState = PendingCashEntryState.ACTIVE
  expirationDate = new Date()
  releasedAt = null
  releasedByUserId = 0
  description = ''
  externalTransactionId = 0

  get isDebit() {
    return this.effect === CostEffect.Debit
  }

  get isCredit() {
    return this.effect === CostEffect.Credit
  }

  get isActive() {
    return this.state === PendingCashEntryState.ACTIVE
  }

  get totalCost() {
    return Cost.create(this.amount, this.effect)
  }
}

export class PlaidLinkToken {
  linkToken = ''
  expiration = new Date()
  requestId = ''

  get isExpired(): boolean {
    return (
      !_.isEmpty(this.linkToken) &&
      this.expiration.getTime() < new Date().getTime()
    )
  }
}

export const PLAID_LINK_TOKEN_DESER: SchemaDeSer<PlaidLinkToken> =
  new SchemaDeSerBuilder(PlaidLinkToken)
    .ofString('linkToken')
    .ofDateTime('expiration')
    .ofString('requestId')
    .toDeSer()
