<script lang="ts">
  import { key } from "@tastyworks/svelte-forms-lib"
  import { getContext } from "svelte"
  import { _ } from "svelte-i18n"
  import { getByPath, isPlainObject } from "./util"
  import { FormContext } from "/@/account-management/form"
  import userAnalytics, { UserAnalyticsTag } from "/@/util/user-analytics"

  const { errors } = getContext<FormContext>(key)

  export let name: string
  export let userAnalyticsTag: UserAnalyticsTag = null
  export let hidden = false

  let errorKey: string
  let errorValues: Record<string, any>

  $: errorMessage = formattedErrorMessage(getByPath($errors, name))

  function formattedErrorMessage(errors: any) {
    if (errors === "") {
      return null
    }

    if (typeof errors === "string") {
      return $_(errors)
    }

    if (isPlainObject(errors)) {
      ;({ key: errorKey, ...errorValues } = errors)

      return $_(errorKey, errorValues)
    }

    return null
  }
</script>

{#if errorMessage}
  <div
    class="
      form-error-message
      mt-1
      flex
      flex-row
      items-center"
    class:invisible={hidden}
  >
    <small
      class="
        text-alerts-error
        font-x-small-500"
      use:userAnalytics={userAnalyticsTag}>{errorMessage}</small
    >
  </div>
{/if}
