export async function generateRecaptchaToken(action: string) {
  await waitForGrecaptcha()
  return window.grecaptcha.execute(window._recaptchaClientId, {
    action,
  })
}

async function waitForGrecaptcha(timeoutMs = 5000): Promise<void> {
  await new Promise((resolve, reject) => {
    const intervalMs = 100
    let elapsed = 0

    const checkGrecaptcha = () => {
      if (typeof window.grecaptcha?.execute === "function") {
        clearInterval(timer)
        resolve()
      } else {
        elapsed += intervalMs
        if (elapsed >= timeoutMs) {
          clearInterval(timer)
          reject(new Error("recaptcha did not load within the timeout period."))
        }
      }
    }

    const timer = setInterval(checkGrecaptcha, intervalMs)
  })
}
