<script lang="ts">
  import { _ } from "svelte-i18n"
  export let type = "sticker"
  export let isAutotradeEnabled = false
</script>

{#if isAutotradeEnabled}
  {#if type === "sticker"}
    <span
      class="
        autotrade-badge
        absolute
        bottom-1
        left-1
        h-1.5
        w-1.5
        rounded-full
        bg-general-primary-background
        text-center
        text-general-default-text"
      style:font-size="4px"
    >
      {$_("content.trading.autotrade_1")}
    </span>
  {:else}
    <span
      class="
        autotrade-badge
        absolute
        -rotate-90
        rounded-t-sm
        bg-general-primary-background
        text-general-default-text
        opacity-60
        font-tiny-700-caps"
      style:padding="0 10px"
      style:margin="6px 0 0 -34px"
    >
      {$_("content.trading.autotrade_2")}
    </span>
  {/if}
{/if}
