import {
  getAccountNumbers,
  getStoredSessionToken,
  getUserExternalId,
  webPlatformSourceIdentifier,
} from "/@lib/shared"
import runLoop from "/@lib/tastyworks-rest/run-loop"
import { AccountStreamer } from "@tastyworks/tastyworks-api"
import logger from "./logger"

// Fire events at snowplow.
function emitRemoteEvent(tag: string, data: object = {}) {
  window.dataLayer?.push({ event: tag, ...data })
}

export function createAccountStreamer(): AccountStreamer {
  const accountStreamer = new AccountStreamer(
    logger,
    runLoop,
    (url) => new WebSocket(url),
    import.meta.env.VITE_TW_STREAMER_WS_URL,
    {
      getSessionToken(): string {
        return getStoredSessionToken()
      },
      getSessionSource(): string {
        return webPlatformSourceIdentifier
      },
      getAccountNumbers(): string[] {
        return getAccountNumbers()
      },
      getUserExternalId(): string {
        return getUserExternalId()
      },
    },
    emitRemoteEvent
  )
  return accountStreamer
}
