import { type Updater } from '../../tastyworks/deser'
import HttpClient from '../../tastyworks/http'
import { updateSingleResponse } from '../../tastyworks/response'
import { W_8BEN_DESER, W8Ben } from '../../tastyworks/w-8ben'

export class W8BenService {
  private readonly w8BenClient: HttpClient

  public constructor(customerMeClient: HttpClient) {
    this.w8BenClient = customerMeClient.nested('w8ben-recertification')
  }

  readonly show = async () =>
    this.w8BenClient.show(new W8Ben(), W_8BEN_DESER.update)

  readonly create = async () => {
    const result = await this.w8BenClient.postJson('/recertify', {})

    return updateSingleResponse(result, {}, {} as Updater<any>)
  }
}
