<script lang="ts" context="module">
  import { uniqueContext } from "@tastyworks/ui-library"

  const { setContext, getContext } = uniqueContext<{
    redirectUri: string
    clientId: string
    clientName: string
    codeChallenge?: string
    codeChallengeMethod?: string
    responseType: string
    state?: string
    scope?: string
    customerTwoFactorRequired?: boolean
    prompt?: string
  }>()
</script>

<script lang="ts">
  import Router, { push, replace } from "svelte-spa-router"
  import wrap from "svelte-spa-router/wrap"
  import "/@/assets/styles/all.css"
  import Login from "./Login.svelte"
  import Error from "./Error.svelte"
  import Confirm from "./Confirm.svelte"
  import Mfa from "./Mfa.svelte"
  import MfaRequired from "./MfaRequired.svelte"
  import CustomerProfileRequired from "/@/tasty-oauth/CustomerProfileRequired.svelte"
  import { doZeroHashFlow, isZeroHash } from "/@/tasty-oauth/zero-hash-hack"
  import { onMount } from "svelte"
  import twApiClient from "/@/tasty-oauth/tw-api-client"

  onMount(async () => {
    if (window.location.hash != "#/") {
      await replace("/")
    }
  })

  setContext({
    clientId: "",
    clientName: "",
    codeChallenge: undefined,
    codeChallengeMethod: undefined,
    customerTwoFactorRequired: false,
    prompt: undefined,
    redirectUri: "",
    responseType: "",
    scope: undefined,
    state: undefined,
  })

  const oauthFlowStateContext = getContext()

  const requiredParamsCondition = async (detail) => {
    const REDIRECT_URI = "redirect_uri"
    const CLIENT_ID = "client_id"
    const RESPONSE_TYPE = "response_type"

    const requiredQueryParams = [REDIRECT_URI, CLIENT_ID, RESPONSE_TYPE]

    const queryParams = new URLSearchParams(window.location.search.slice(1))

    const missingQueryParams = requiredQueryParams.filter(
      (param) => !queryParams.has(param)
    )

    if (missingQueryParams.length > 0) {
      detail.code = 401
      detail.message = `Missing required query params: ${missingQueryParams.join(
        ", "
      )}`
      return false
    }

    const redirectUri = queryParams.get(REDIRECT_URI) as string
    const clientId = queryParams.get(CLIENT_ID) as string
    const responseType = queryParams.get(RESPONSE_TYPE) as string

    const clientDetails = await getClientDetails(
      redirectUri,
      clientId,
      responseType
    )

    if (!clientDetails) {
      detail.code = 404
      detail.message = "Client not found"
      return false
    }

    const { clientName, customerTwoFactorRequired } = clientDetails

    oauthFlowStateContext.clientId = clientId
    oauthFlowStateContext.clientName = clientName
    oauthFlowStateContext.codeChallenge = queryParams.get("code_challenge")
    oauthFlowStateContext.codeChallengeMethod = queryParams.get(
      "code_challenge_method"
    )
    oauthFlowStateContext.customerTwoFactorRequired = customerTwoFactorRequired
    oauthFlowStateContext.redirectUri = redirectUri
    oauthFlowStateContext.responseType = responseType
    oauthFlowStateContext.scope = queryParams.get("scope") ?? ""
    oauthFlowStateContext.state = queryParams.get("state") ?? ""
    oauthFlowStateContext.prompt = queryParams.get("prompt") ?? ""

    return true
  }

  const getClientDetails = async (
    redirectUri: string,
    clientId: string,
    responseType: string
  ): Promise<
    | {
        clientName: string
        customerTwoFactorRequired: boolean
      }
    | undefined
  > => {
    if (isZeroHash(clientId, responseType)) {
      return doZeroHashFlow(redirectUri)
    }
    const oauthResponse = await twApiClient.oauthService.show(
      clientId,
      redirectUri
    )

    if (!oauthResponse.hasData()) {
      return
    }

    const { name, customerTwoFactorRequired } = oauthResponse.data!

    return {
      clientName: name,
      customerTwoFactorRequired,
    }
  }
</script>

<body
  class="flex h-full flex-col items-center justify-around overflow-x-clip overflow-y-scroll p-10"
>
  <div class="bg-general-primary-surface">
    <Router
      routes={{
        "/": wrap({
          component: Login,
          props: {
            oauthFlowStateContext,
          },
          conditions: [requiredParamsCondition],
        }),
        "/confirm": wrap({
          component: Confirm,
          props: {
            oauthFlowStateContext,
          },
          conditions: [requiredParamsCondition],
        }),
        "/customer-profile-required": wrap({
          component: CustomerProfileRequired,
          conditions: [requiredParamsCondition],
        }),
        "/mfa": wrap({
          component: Mfa,
          conditions: [requiredParamsCondition],
        }),
        "/mfa-required": wrap({
          component: MfaRequired,
          props: {
            oauthFlowStateContext,
          },
          conditions: [requiredParamsCondition],
        }),
        // eslint-disable-next-line sort-keys
        "/error": Error,
      }}
      on:conditionsFailed={({ detail }) => {
        // @ts-expect-error adding custom code and message fields to detail
        push(`/error?code=${detail.code}&message=${detail.message}`)
      }}
    />
  </div>
</body>
