<script context="module">import { uniqueContext } from "../../../internal/helpers";
const { getContext, setContext } = uniqueContext();
export const getSelectRootContext = getContext;
</script>

<script>import { Popper } from "../../../internal/components";
import {
  generateId,
  isScrollKey,
  Keys,
  useActions
} from "../../../internal/helpers";
import { cn } from "../../../utils";
import { createEventDispatcher, onMount, tick } from "svelte";
import { writable } from "svelte/store";
import { selectFieldVariants } from ".";
import Panel from "./SelectPanel.svelte";
import SelectPortal from "./SelectPortal.svelte";
import Trigger from "./SelectTrigger.svelte";
const dispatch = createEventDispatcher();
export let value = null;
export let name = "";
export let id = generateId();
export let open = false;
export let disabled = false;
export let required = false;
export let placeholder = "";
export let highlightedIndex = null;
export let selectedIndex = null;
export let error = false;
let rootRef = writable();
let triggerRef = writable();
let panelRef = writable();
let inputRef = writable();
let options = [];
let selected = null;
const ctx = setContext(
  writable({
    rootRef,
    triggerRef,
    panelRef,
    inputRef,
    value,
    selectedIndex,
    highlightedIndex,
    open,
    placeholder,
    options,
    idPrefix: id,
    selected,
    disabled,
    openSelectPanel() {
      if (disabled || open || options.length === 0) return;
      open = true;
      if (selectedIndex !== null) {
        highlightedIndex = selectedIndex;
        this.highlight(highlightedIndex);
      }
    },
    closeSelectPanel() {
      if (disabled || !open) return;
      open = false;
      highlightedIndex = null;
    },
    registerOption(option) {
      if (options.length === 0) {
        options.push(option);
        options = options;
        return;
      }
      let highlightedOption = highlightedIndex !== null ? options[highlightedIndex] : null;
      let orderMap = Array.from(
        $panelRef?.querySelectorAll(`[id^=${id}-option]`) ?? []
      ).reduce(
        (lookup, element, index) => Object.assign(lookup, { [element.id]: index }),
        {}
      );
      options.push(option);
      options.sort((a, z) => orderMap[a.id] - orderMap[z.id]);
      options = options;
      highlightedIndex = (() => {
        if (highlightedOption === null) return null;
        return options.indexOf(highlightedOption);
      })();
    },
    async unRegisterOption(option) {
      let highlightedOption = highlightedIndex !== null ? options[highlightedIndex] : null;
      let toRemove = options.findIndex((opt) => opt.value === option.value);
      if (toRemove !== -1) options.splice(toRemove, 1);
      options = options;
      highlightedIndex = (() => {
        if (toRemove === highlightedIndex) return null;
        if (highlightedOption === null) return null;
        return options.indexOf(highlightedOption);
      })();
    },
    select(index) {
      if (disabled) return;
      if (index !== null && index >= 0 && index < options.length) {
        selectedIndex = index;
        selected = options[index] ?? null;
        value = selected?.value ?? null;
        if ($inputRef) {
          $inputRef.value = selected?.label ?? "";
        }
      } else {
        value = $inputRef?.value ?? null;
      }
    },
    highlight(index) {
      if (disabled) return;
      highlightedIndex = index;
    },
    toggleDialog() {
      if (open) {
        $ctx.closeSelectPanel();
      } else if (options.length > 0) {
        $ctx.openSelectPanel();
      }
    },
    handleKeyDown(event) {
      const shouldContinue = dispatch("keydown", event, { cancelable: true });
      if (!shouldContinue || !event.key) return;
      if (searchDebounce) {
        clearTimeout(searchDebounce);
        searchDebounce = void 0;
      }
      if (event.key !== Keys.ArrowRight && event.key !== Keys.ArrowLeft && isScrollKey(event.key)) {
        event.preventDefault();
        event.stopPropagation();
      }
      switch (event.key) {
        case Keys.Enter:
          if (!open) {
            $ctx.openSelectPanel();
            break;
          }
          if (open && highlightedIndex !== null) {
            event.preventDefault();
            $ctx.select(highlightedIndex);
          }
          $ctx.closeSelectPanel();
          break;
        case Keys.Escape:
        case Keys.Tab:
          $ctx.closeSelectPanel();
          break;
        case Keys.Space:
          if (!open) {
            $ctx.openSelectPanel();
            highlightedIndex && $ctx.highlight(highlightedIndex);
          } else {
            $ctx.closeSelectPanel();
          }
          break;
        case Keys.ArrowDown:
          $ctx.openSelectPanel();
          $ctx.highlight(nextIndex(options, highlightedIndex));
          break;
        case Keys.ArrowUp:
          $ctx.openSelectPanel();
          $ctx.highlight(prevIndex(options, highlightedIndex));
          break;
        case Keys.Home:
          $ctx.highlight(0);
          break;
        case Keys.End:
          $ctx.highlight(options.length - 1);
          break;
        case Keys.PageUp:
          $ctx.highlight(prevPage(highlightedIndex));
          break;
        case Keys.PageDown:
          $ctx.highlight(nextPage(options, highlightedIndex));
          break;
        default:
          if (event.key.length === 1) {
            handleAlphaNumeric(event);
          }
          break;
      }
    }
  })
);
export const ctrl = ctx;
function nextIndex(options2, index) {
  if (index === null) {
    return 0;
  }
  return (index + 1) % options2.length;
}
function prevIndex(options2, index) {
  if (index === null) {
    return options2.length - 1;
  }
  return index === 0 ? options2.length - 1 : index - 1;
}
function nextPage(options2, index, pageSize = 10) {
  if (index === null) {
    return 0;
  }
  if (index + pageSize >= options2.length) {
    return options2.length - 1;
  }
  return index + pageSize;
}
function prevPage(index, pageSize = 10) {
  if (index === null || index - pageSize <= 0) {
    return 0;
  }
  return index - pageSize;
}
let searchStr = "";
let searchDebounce;
async function handleAlphaNumeric(event) {
  if ($inputRef) return;
  searchDebounce = setTimeout(() => {
    searchStr = "";
  }, 500);
  searchStr += event.key.toLowerCase();
  await tick();
  const index = options.findIndex(
    (o) => o.label?.toLowerCase().startsWith(searchStr)
  );
  if (index >= 0) {
    $ctx.highlight(index);
  }
}
onMount(() => {
  if (value) {
    const index = options.findIndex((o) => o.value === value);
    if (index >= 0) {
      selectedIndex = index;
      selected = options[index] ?? null;
    }
  } else if (selectedIndex !== null) {
    selected = options[selectedIndex] ?? null;
    value = selected?.value ?? null;
  }
});
$: if (value !== selected?.value) {
  const optionIndex = options.findIndex((o) => o.value === value);
  if (optionIndex >= 0) {
    selectedIndex = optionIndex;
    selected = options[selectedIndex];
  } else {
    selectedIndex = null;
    selected = null;
  }
}
$: ctx.update((obj) => ({
  ...obj,
  open,
  selectedIndex,
  selected,
  placeholder,
  value,
  highlightedIndex,
  options,
  idPrefix: id,
  disabled
}));
$: dispatch("change", { value });
function handleDocumentClick(event) {
  const target = event.target;
  if (!$rootRef.contains(target) && !$panelRef?.contains(target)) {
    $ctx.closeSelectPanel();
  }
}
</script>

<svelte:document on:pointerdown={handleDocumentClick} />

<Popper.Root>
  <div
    {...$$restProps}
    class={cn(selectFieldVariants({ error }), $$restProps.class)}
    {id}
    bind:this={$rootRef}
    data-open={open}
    data-disabled={disabled}
    aria-required={required}
    use:useActions={$$restProps.use}
    role="combobox"
    aria-expanded={open}
    aria-controls="{id}-panel"
    aria-activedescendant={selected ? selected.id : null}
    tabindex="-1"
    aria-invalid={error}
    on:focusin
    on:focusout
  >
    <slot name="trigger">
      <Trigger />
    </slot>

    <slot name="panel">
      <SelectPortal>
        <Panel>
          <!-- TODO: integrate options prop -->
          <slot />
        </Panel>
      </SelectPortal>
    </slot>
    {#if name}
      <input type="hidden" {name} {value} {required} />
    {/if}
  </div>
</Popper.Root>
